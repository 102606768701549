import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'


function MetamaskInstall({ header, li1, li2, img, walletList, li3, addUser, installMetaMask, userAdding,li3_1 }) {
  const userAdded = useSelector((state) => state.requireAvalibilityReducer)
  const [timer, setTimer] = useState(30)
  const navigate = useNavigate()


  const timerFn = () => {
    const timerID = setInterval(() => {
      setTimer(timer - 1)
    }, 1000);

    // navigate logout if tiemr hits 0
    if (timer === 0) {
      navigate('/')
    }
    return () => clearInterval(timerID)
  }
  useEffect(() => {
    if (userAdded.allreadyRegisteredWallet) {
      timerFn();
    }
  }, [timer])

  const refreshMetaMask = () => {
    window.location.reload()
  }

  // set polygon mainnet into meta mask account
  async function addPolygonNetwork() {
    if (window.ethereum) {
      try {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [{
            chainId: '0x89',
            chainName: 'Polygon Mainnet',
            rpcUrls: ['https://polygon.drpc.org'],
            nativeCurrency: {
              name: 'MATIC',
              symbol: 'MATIC',
              decimals: 18
            },
            blockExplorerUrls: ['https://polygon.llamarpc.com'],
          }]
        });

        await addTokenToMetaMask()

        setTimeout(() => {
          window.location.reload()
        }, 2000);

      } catch (error) {
        console.error('Failed to add Polygon Mainnet:', error);
      }
    } else {
      console.error('MetaMask is not installed.');
    }

    
  }

  const navigatePlan = () => {
    navigate("/dashboard/billing")
  }

  // add becx token 
  const addTokenToMetaMask = async () => {
    const tokenAddress = process.env.REACT_APP_TOKEN_ADDRESS_FOR_TRANSFER; // The custom token contract address
    const tokenSymbol = 'BECX'; 
    const tokenDecimals = 18; // The number of
    const tokenImage = ''; // A string URL of the token logo image

    try {
      // Check if MetaMask is available
      if (window.ethereum) {
        // Request MetaMask to add a custom token
        const wasAdded = await window.ethereum.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20',
            options: {
              address: tokenAddress,
              symbol: tokenSymbol,
              decimals: tokenDecimals,
              image: tokenImage,
            },
          },
        });

      } else {
        console.error('MetaMask is not installed.');
      }
    } catch (error) {
      console.error('Error adding token:', error);
    }
  }
  return (
    <div className="fixed z-[99999] right-2 top-[120px] w-[550px] inline-block text-sm text-white border border-gray-200 rounded-lg shadow-sm bg-red-400 animate__animated animate__fadeInRight animate__fast">
      {/* Header with MetaMask Logo */}
      <div className="px-3 py-2 bg-red-500 border-b border-gray-200 rounded-t-lg flex gap-2 items-center">
        <div className="p-2 rounded-full bg-white">
          <img src={img} className="w-[30px]" alt="" />
        </div>
        <h3 className="font-semibold text-gray-900 dark:text-white">{header}</h3>
      </div>

      {/* Content */}
      <div className="px-3 py-2 flex flex-col gap-3">
        <ul className="list-disc pl-5">
          <li>{li1}</li>
          <li>{li2}</li>
          <li>{li3_1}</li>
          {li3 && <li className='font-bold'>{li3}</li>}
        </ul>

        <ol className='px-8 text-bethel-newgreen font-bold'>
          {walletList && walletList.map((address, index) => {
            return <li>{index + 1}. {address}</li>
          })}
        </ol>
      </div>

      {/* if user is added or not  */}
      {userAdded && userAdded.userAdded && <div className='flex absolute px-3 hover:scale-105 py-2 hover:bg-bethel-green w-[140px] justify-center rounded-lg top-3 right-2 border-[1px] border-white'>
        <button onClick={addUser}>Add New User</button>
      </div>}

      {/* if wallet addrss fail  */}
      {userAdded && userAdded.allreadyRegisteredWallet && <div className='flex absolute hover:scale-105  hover:bg-bethel-green justify-center top-2 right-2 border-[1px] p-2 rounded-full w-[150px] gap-2  items-center'>
        <h3 className='text-[12px]'>Log out in :</h3>
        <button className='w-[30px] h-[30px] rounded-full bg-white text-red-500 font-bold'>{timer}</button>
      </div>}

      {/* if user is added or not  */}
      {installMetaMask === "Installed" &&
        <div className='flex absolute px-3 hover:scale-105 py-2 hover:bg-bethel-green w-[140px] justify-center rounded-lg top-3 right-2 border-[1px] border-white'>
          <button onClick={refreshMetaMask} className='text-[12px]'>Already Installed</button>
        </div>}

      {/* add polygon mainnet into meta mask */}
      {false === "AddMainnet" &&
        <div className='z-[9999] flex absolute bg-red-500 px-3 hover:scale-105 py-2 hover:bg-bethel-green w-[140px] justify-center rounded-lg top-3 right-2 border-[1px] border-white'>
          <button onClick={addPolygonNetwork} className='text-[12px] font-bold'>ADD MAINNNET</button>
        </div>}

        {/* add polygon mainnet into meta mask */}
      {true &&
        <div className='z-[9999] flex absolute bg-red-500 px-3 hover:scale-105 py-2 hover:bg-bethel-green w-[140px] justify-center rounded-lg top-3 right-2 border-[1px] border-white'>
          <button onClick={navigatePlan} className='text-[12px] font-bold'>Download</button>
        </div>}



      {/* Triangle Pointer */}
      <div className="absolute bottom-0 right-20 transform translate-y-full w-6 h-6 border-t-[30px] border-t-red-400 border-l-[30px] border-l-transparent"></div>
    </div>
  )
}

export default MetamaskInstall