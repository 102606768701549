import React, { useEffect, useRef, useState } from 'react'
import iconWifi from "../Images/icons/icons8-wifi-100.png"
import iconStorage from "../Images/icons/icon-storage.png"
import iconFolder from "../Images/icons/icons-folder.png"
import { useDispatch, useSelector } from 'react-redux'
import SmallCard from './Cards/SamllCard'
import NetworkCard from './Cards/NetworkCard'
import PieChartCard from './Cards/PieChartCard'
import iconStatus from "../Images/icons/icon-status.png"
import requireAvalibilitySlice from '../reducers/RequireAvalibilySlice'
import iconMedal1 from "../Images/icons/icon-medal1.png"
import iconMedal2 from "../Images/icons/icon-medal2.png"
import iconMedal3 from "../Images/icons/icon-medal3.png"
import iconMedal from "../Images/icons/icon-trophie.png"
import iconMedalNone from "../Images/icons/icon-medalnone.png"
import bethelLogo from "../Images/icons/bethel-logo.png"
import { useQuery } from '@apollo/client'
import img_plane from "../Images/BG-Images/paper-rocket.png"
import img_plane2 from "../Images/BG-Images/paper-plane.png"
import img_plane3 from "../Images/BG-Images/rokcet.png"
import paperplanImageLite from "../Images/BG-Images/paper-rocket-lite.png"
import planImageLite from "../Images/BG-Images/paper-plane-lite.png"
import rocketImageLite from "../Images/BG-Images/rokcet1-lite.png"
import DashboardPlanCard from './Cards/DashboardPlanCard'
import DashboardActivitiesComponent from './Cards/DashboardActivitesComponent'
import { check_active_plan, get_all_file_count } from '../queris'
import icon_size from "../Images/icons/icon-size.png"
import warning_img_dark from "../Images/BG-Images/warning_img_darkmode.png"
import warning_img_light from "../Images/BG-Images/warning_img_lightmode.png"


function DashboardHome() {

  const [dashboard_data, setDashboard_data] = useState({ count: 0, size: 0, plan_status: null })
  const dispatch = useDispatch()


  // store data
  const avalibilityData = useSelector((state) => state.requireAvalibilityReducer)
  const web_theme = useSelector((state) => state.webThemeReducer.web_theme)
  const userDid = useSelector((state) => state.DidReducer)

  useEffect(() => {
    dispatch(requireAvalibilitySlice.actions.saveAvailibility({ metamask: false, useruserAdded: false, noUser: false, allreadyRegisteredWallet: false }))
    get_leaderboard_details() // get leaderboard details
    console.log("this is dashbaord data : ",dashboard_data)
  }, [])

  //get leader boards details
  const [leaderboarder_details, setLeaderboard_details] = useState(null)
  const [my_rank, setMy_rank] = useState(null)
  const rankRef = useRef()

  const [toggle_leader_board, setToggle_leader_board] = useState(false)

  const get_leaderboard_details = async () => {
    try {
      const res = await fetch(process.env.REACT_APP_BACKEND_URL + "get-top-10-ref")
      if (res.ok) {
        const data = await res.json()
        setLeaderboard_details(data.TopReferrals)
      }
    } catch (error) {
      console.log("error in leaderboard details :", error)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (rankRef.current && !rankRef.current.contains(event.target)) {
      setToggle_leader_board(false);
    }
  };

  //  --------------------------------------------------------------- graph ql fns --------------------------------------------------------------

  const { data: all_file_count_data, loading: all_file_count_loading, error: all_file_count_error } = useQuery(get_all_file_count, {
    variables: { input: { did: userDid } }
  })

  const { data: active_plan_data, loading: active_plan_loading, error: active_plan_error } = useQuery(check_active_plan, {
    variables: { input: { did: userDid } }
  })

  useEffect(() => {
    if (all_file_count_data) {
      setDashboard_data((prev) => ({ ...prev, count: all_file_count_data.getTotalFilesCountSize.ok.ok.count, size: all_file_count_data.getTotalFilesCountSize.ok.ok.size }))
    }

    if (all_file_count_loading) {
      console.log("get all file count data is loading :")
    }

    if (all_file_count_error) {
      console.log("get all file count data error -- >  :", all_file_count_error.message)
    }
  }, [all_file_count_data, all_file_count_loading, all_file_count_error])


  useEffect(() => {
    if (active_plan_data) {
      setDashboard_data((prev) => ({ ...prev, plan_status: active_plan_data.getCurrentPackage.packageName }))
      console.log("hello there :",active_plan_data.getCurrentPackage)
    }

    if (active_plan_loading) {
      console.log("get all file count data is loading :")
    }

    if (active_plan_error) {
      setDashboard_data((prev) => ({ ...prev, plan_status: "no plan" }))

    }
  }, [active_plan_data, active_plan_loading, active_plan_error])



  return (
    <div className='w-full pr-4'>
      <div className={` ${web_theme ? "bg-dashboard-home-bg1 text-white" : "bg-sidebar-bg-lite text-sidebar-text-lite2"} rounded-[20px] p-1
         w-full animate__animated animate__fadeIn ${!avalibilityData.allreadyRegisteredWallet && !avalibilityData.userAdded && !avalibilityData.noUser ? "" : "pointer-events-none"}`}>

        {/* start of leaderboard div */}
        {toggle_leader_board &&
          <div ref={rankRef} className="flex flex-col gap-2 fixed bottom-0 w-[500px] bg-black rounded-t-lg border border-bethel-green/20 right-2 p-3 z-[200] animate__animated animate__fadeInUp animate__faster">

            {/* leaderboard */}
            <div className='w-full p-1 flex flex-col gap-2 z-[200] relative'>
              {/* top section */}
              <div className="flex gap-2 items-center justify-between tracking-wide p-2">
                <div className="flex items-center gap-2 ">
                  <div>
                    <img src={bethelLogo} className="w-[20px]" alt="" />
                  </div>
                  <div className="font-bold text-bethel-green text-[1rem]">
                    <h3>BETHEL LEADERBOARD</h3>
                  </div>
                </div>


                <div className="text-white font-bold text-[10px] flex gap-1 items-center">
                  <h3>Your Rank :</h3>
                  <h3 className="relative text-bethel-green  z-10 font-bold rounded-full w-[25px] h-[25px] justify-center items-center flex text-[10px] border border-bethel-green">{my_rank && my_rank}</h3>

                </div>
              </div>
              {/* end of top section */}

              <hr className="h-[1px] opacity-20 w-full" />

              <div className="flex w-full flex-col gap-2">
                {leaderboarder_details && leaderboarder_details.slice(0, 3).map((ref, index) => {
                  return <div className="flex flex-col">
                    <div className="flex items-center px-2 py-1 gap-2">

                      <div className="flex items-center w-full gap-2 ">
                        {/* tropie image */}
                        <div className={`flex`}>
                          {index === 0 && <img src={iconMedal1} className="w-[35px] animate-pulse" alt="" />}
                          {index === 1 && <img src={iconMedal2} className="w-[35px] opacity-80" alt="" />}
                          {index === 2 && <img src={iconMedal3} className="w-[35px] opacity-60" alt="" />}
                        </div>

                        {/* refs */}
                        {index === 0 && (
                          <div className="relative w-full p-1 overflow-hidden rounded-l-lg flex justify-between animate-pulse">
                            <div className="bg-gradient-to-r from-green-600/40 via-green-400/40 to-transparent absolute inset-0 animate-fade-green"></div>
                            <h3 className="relative text-white/70 text-[14px] z-10">{ref.ReferralID}</h3>
                            <h3 className="relative text-white/70  z-10 font-bold rounded-full w-[25px] h-[25px] justify-center items-center flex text-[10px] border border-white/50">{ref.TotalPoint}</h3>
                          </div>
                        )}

                        {index === 1 && (
                          <div className="relative w-full p-1 overflow-hidden rounded-l-lg flex justify-between">
                            <div className="bg-gradient-to-r from-yellow-600/40 via-yellow-400/40 to-transparent absolute inset-0 animate-fade-green"></div>
                            <h3 className="relative text-white/70 text-[14px] z-10">{ref.ReferralID}</h3>
                            <h3 className="relative text-white/70  z-10 font-bold rounded-full w-[25px] h-[25px] justify-center items-center flex text-[10px] border border-white/50">{ref.TotalPoint}</h3>

                          </div>
                        )}

                        {index === 2 && (
                          <div className="relative w-full p-1 overflow-hidden rounded-l-lg flex justify-between">
                            <div className="bg-gradient-to-r from-orange-600/40 via-orange-400/40 to-transparent absolute inset-0 animate-fade-green"></div>
                            <h3 className="relative text-white/70 text-[14px] z-10">{ref.ReferralID}</h3>
                            <h3 className="relative text-white/70  z-10 font-bold rounded-full w-[25px] h-[25px] justify-center items-center flex text-[10px] border border-white/50">{ref.TotalPoint}</h3>

                          </div>
                        )}
                      </div>

                    </div>

                  </div>
                })}

                {leaderboarder_details && leaderboarder_details.slice(3, 10).map((ref, index) => {
                  return <div className="flex flex-col">
                    <div className="flex items-center px-2 py-1 gap-2">

                      <div className="flex items-center w-full gap-2 ">
                        {/* tropie image */}
                        <div className={`flex`}>
                          <img src={iconMedalNone} className="w-[35px]" alt="" />
                        </div>

                        <div className="relative w-full p-1 overflow-hidden rounded-l-lg flex justify-between">
                          <div className="bg-gradient-to-r from-white/10 via-white/10 to-transparent absolute inset-0 animate-fade-green"></div>
                          <h3 className="relative text-white/70 text-[14px] z-10">{ref.ReferralID}</h3>
                          <h3 className="relative text-white/70  z-10 font-bold rounded-full w-[25px] h-[25px] justify-center items-center flex text-[10px] border border-white/50">{ref.TotalPoint}</h3>
                        </div>
                      </div>

                    </div>

                  </div>
                })}
              </div>
            </div>

            {/* toggle div */}
            <button onClick={() => setToggle_leader_board(!toggle_leader_board)} className='flex gap-1 p-2 absolute top-[-45px] w-fit items-center justify-center bg-black rounded-t-lg border-t border-l border-r border-bethel-green/20 right-[-1px] -z-10 px-4'>
              <div className='flex'>
                <img src={iconMedal} className='w-[30px] animate__animated animate__tada animate__infinite animate__slower' alt="" />
              </div>
              <div className='text-bethel-green/80 text-[12px] font-bold animate__animated animate__tada animate__infinite animate__slower'>
                <h3>Leaderboard</h3>
              </div>
            </button>

          </div>
        }
        {/* end of leader board div */}

        {!toggle_leader_board &&
          <button onClick={() => setToggle_leader_board(true)} className='flex gap-1 p-2 fixed bottom-0 w-fit items-center justify-center bg-black rounded-t-lg border border-bethel-green/20 right-4 z-[200] px-4'>
            <div className='flex'>
              <img src={iconMedal} className='w-[30px] animate__animated animate__tada animate__infinite animate__slower' alt="" />
            </div>
            <div className='text-bethel-green/80 text-[12px] font-bold animate__animated animate__tada animate__infinite animate__slower'>
              <h3>Leaderbord</h3>
            </div>
          </button>
        }

        {/* main container div */}
        <div className='flex w-full h-full border-white pl-2 pr-3 lg:flex-nowrap md:flex-wrap sm:flex-wrap min-[320px]:flex-wrap'>

          {/* three card component and network stats and table div*/}
          <div className={`flex flex-col p-2 gap-5 lg:w-2/3 md:w-full sm:w-full min-[320px]:w-full ${!avalibilityData.userAdded && !avalibilityData.noUser && !avalibilityData.allreadyRegisteredWallet ? "" : "grayscale blur-sm"}`}>
            {/* topic container */}
            <div className='flex flex-col mt-2 mb-1'>
              <div className='ml-2 opacity-50 text-[.9rem] flex flex-col gap-2'>
                <h3>Quick Access</h3>
                <hr className={`${web_theme ? "text-white bg-white opacity-30 h-[1px]" : " text-sidebar-text-lite2 opacity-100 h-[2px]"} w-[99%] `} />
              </div>
            </div>

            {/* three cards div */}
            <div className='flex w-full gap-5 lg:flex-nowrap md:flex-wrap sm:flex-wrap min-[320px]:flex-wrap'>
              <SmallCard card_icon={iconFolder} heading={'Files'} subheading={'total files'} card_data={dashboard_data.count} card_color={web_theme ? "bg-bethel-green/100" : "bg-sidebar-text-lite2 text-white"} stats={true} />
              <SmallCard card_icon={icon_size} heading={'Storage Size'} subheading={'total size'} card_data={(dashboard_data.size / 1048576 / 1000).toFixed(1)} card_color={web_theme ? "bg-dashboard-small-card" : "bg-sidebar-bg-lite"} />
              <SmallCard card_icon={iconStorage} heading={'Storage Plan'} subheading={'Status'}
                card_data={`${dashboard_data.plan_status === "Starter" ? "200 GiB" : ""}
                  ${dashboard_data.plan_status === "AdvancePro" ? "12 TiB" : ""}
                  ${dashboard_data.plan_status === "StarterPro" ? "2 TiB" : ""} 
                  ${dashboard_data.plan_status === "Advance" ? "6 TiB" : ""}
                 ${dashboard_data.plan_status === ("no plan") ? "0" : ""} 
                 ${dashboard_data.plan_status === "Primary" ? "50 GiB" : ""} `} 
                 card_color={web_theme ? "bg-dashboard-small-card" : "bg-sidebar-bg-lite"} />
            </div>

            {/* topic container */}
            <div className='flex flex-col mt-2 mb-1'>
              <div className='ml-2 opacity-50 text-[.9rem] flex flex-col gap-2'>
                <h3>Current Status</h3>
                <hr className={`${web_theme ? "text-white bg-white opacity-30 h-[1px]" : " text-sidebar-text-lite2 opacity-100 h-[2px]"} w-[99%] `} />
              </div>
            </div>

            {/* chart section */}
            <div className='flex w-full gap-5 lg:flex-nowrap md:flex-wrap sm:flex-wrap min-[320px]:flex-wrap'>
              {/* <NetworkCard card_heading={"Network Usage"} card_image={iconWifi} Card_subheading={"Total Network Usage :"} /> */}
              {dashboard_data.plan_status === "Primary" && <DashboardPlanCard plan={"Primary Plan"} plan_details={"Unlimited Uploads Upto 1 GiB storage space Hack-Proof ZKP Protected Blockchain-Based Security Decentralized Data Protection"} plan_img={web_theme ? img_plane : paperplanImageLite} />}
              {dashboard_data.plan_status === ("no plan") && <DashboardPlanCard plan={"No Plan "} plan_details={"Please add a plan for activate uploads and downloads for your storage"} plan_img={web_theme ? warning_img_dark : warning_img_light} />}
              {dashboard_data.plan_status === "Advance" && <DashboardPlanCard plan={"Advance Plan"} plan_details={"Unlimited Uploads Upto 5 TiB storage space Hack-Proof ZKP Protected Blockchain-Based Security Decentralized Data Protection"} plan_img={web_theme ? img_plane3 : rocketImageLite} />}
              {dashboard_data.plan_status === "AdvancePro" && <DashboardPlanCard plan={"Advance Plan"} plan_details={"Unlimited Uploads Upto 5 TiB storage space Hack-Proof ZKP Protected Blockchain-Based Security Decentralized Data Protection"} plan_img={web_theme ? img_plane3 : rocketImageLite} />}
              {dashboard_data.plan_status === "Starter" && <DashboardPlanCard plan={"Starter Plan"} plan_details={"Unlimited Uploads Upto 1 TiB storage space Hack-Proof ZKP Protected Blockchain-Based Security Decentralized Data Protection"} plan_img={web_theme ? img_plane2 : planImageLite} />}
              {dashboard_data.plan_status === "StarterPro" && <DashboardPlanCard plan={"Starter Plan"} plan_details={"Unlimited Uploads Upto 1 TiB storage space Hack-Proof ZKP Protected Blockchain-Based Security Decentralized Data Protection"} plan_img={web_theme ? img_plane2 : planImageLite} />}
              <PieChartCard card_icon={iconStatus} card_heading={'Status'} card_subHeading={"Chart Details :"} UsedValue={dashboard_data.size} FilesCount={dashboard_data.count}
                currentPackage={dashboard_data.plan_status} />
            </div>

            {/* topic container */}
            <div className='flex flex-col mb-1'>
              <div className='ml-2 opacity-50 text-[.9rem] flex flex-col gap-2'>
                <h3>Data Usage</h3>
                <hr className='h-[1px] w-[99%] opacity-30' />
              </div>
            </div>

            <div className='h-auto w-full'>
              {/* <CardTable NameArr={fileNames} iconCard={tableUpload} iconFile={iconFile} heading={"Recent Uploads"} /> */}
              <NetworkCard card_heading={"Network Usage"} card_image={iconWifi} Card_subheading={"Total Network Usage :"} />

            </div>
          </div>

          {/* activities section */}
          <div className={`flex mt-5  p-1 h-auto lg:w-1/3 md:w-full sm:w-full min-[320px]:w-full ${!avalibilityData.allreadyRegisteredWallet && !avalibilityData.userAdded && !avalibilityData.noUser ? "" : "grayscale blur-sm"}`}>
            <DashboardActivitiesComponent />
          </div>

        </div>
      </div>
    </div>

  )
}

export default DashboardHome
