import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    toggleSidebar : false
}

const toggleSidebarSlice = createSlice({
    name : "requireAvalibilitySlice",
    initialState,
    reducers : {
        toggleSidebar : (state,action) => action.payload
    }
})

export default toggleSidebarSlice