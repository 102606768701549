import React, { useEffect, useRef, useState } from "react";
import FileSharedComp from "../components/FIles-ShareFiles";
import TableWithMoreButton from "../components/Test";
import "../loadingCss/InfiniteLoader.css";
import iconWrong from "../Images/icons/icons-close.png";
import { useDispatch, useSelector } from "react-redux";
import robot from "../Images/BG-Images/robot.png"
import iconExpired from "../Images/icons/icon-expired.png"
import { NavLink } from "react-router-dom";
import img_cloud from "../Images/BG-Images/upload-image.png"
import { uploading_files_slice } from "../reducers/UploadingFileSlice";
import { toggleTriggerFn } from "../reducers/uploadButtonClick";
import current_file_count from "../reducers/currentAccountReducer";
import { check_active_plan, CHECK_PACAKGE_ACTIVATION, check_plan_expire, is_user_added } from "../queris";
import { useQuery } from "@apollo/client";
import planStatusSlice from "../reducers/PlanStatusSlice";


export default function DashboardStorageFolder2() {
  const childRef = useRef();
  const dispatch = useDispatch();

  // dark or lite theme
  const web_theme = useSelector((state) => state.webThemeReducer.web_theme)

  // on chain data
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadFail, setUploadFail] = useState(false);
  const [changeUploadMethod, setChangeUploadMethod] = useState(true);
  const [singleOrBatch, setSingleOrBatch] = useState(false);

  // for the bulk upload
  const [bulkUploads, setBulkUploads] = useState(null);
  const [bulkUploadsLoop, setBulkUploadsLoop] = useState(null);
  const [uploadBtnShow, setUploadBtnShow] = useState(false);

  // store variables
  const blur = useSelector((state) => state.fileBlurReducer);
  const did = useSelector((state) => state.DidReducer)
  const avalibilityData = useSelector((state) => state.requireAvalibilityReducer)


  // limit upload 
  const [maxUploadLimit, setMaxUploadLimit] = useState(false)

  // if user not install meta mask navigate to dashboard 
  useEffect(() => {
    dispatch(uploading_files_slice.actions.save_uploading_files({ uploading_files: null })) // clear uploading data when refresh
    check_plan_expire_gql() //check if the plan activated or not 
  }, [])


  // drag and drop file handler
  const [isDragging, setIsDragging] = useState(false);

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);

    // Get the dropped files
    const files = event.dataTransfer.files;

    // dispatch file count for add points 
    dispatch(current_file_count.actions.save_current_file_count({ current_file_count: files.length }))

    if (files.length > 0) {
      handleBulkUpload({ target: { files } }); // Simulating file input event
    }
  };

  // handle bulk upload fn
  const handleBulkUpload = (event) => {
    setUploadSuccess(false);
    const uploadss = event.target.files;

    // dispatch file count for add points 
    dispatch(current_file_count.actions.save_current_file_count({ current_file_count: uploadss.length }))

    // check if file upload is single or batch
    if (uploadss.length === 1) {
      setSingleOrBatch(true);
    } else {
      setSingleOrBatch(false);
    }

    // set uploading limit 
    if (uploadss.length === 41) {
      setMaxUploadLimit(true)

      setTimeout(() => {
        setMaxUploadLimit(false)
      }, 3000);

      setBulkUploads(null);
      return;

    }
    setBulkUploadsLoop(Array.from(uploadss));
    dispatch(uploading_files_slice.actions.save_uploading_files({ uploading_files: Array.from(uploadss) }))
    setBulkUploads(uploadss);
    setUploadBtnShow(true);
  };

  // delete all bulk upload
  const deleteBulkuploads = () => {
    setBulkUploadsLoop(null);
    setBulkUploads(null);
    setUploadBtnShow(false);
  };

  const planStats = useSelector((state) => state.planStatusReducer)
  const planExpired = useSelector((state) => state.planStatusReducer)

  // toggle details upload and download and share files 
  const [toggleDetails, setToggleDetails] = useState(false)

  const file_upload_v2 = () => {
    dispatch(toggleTriggerFn()); // Dispatch action to toggle triggerFn
    setBulkUploadsLoop(null)
  };

  // graph ql functions --- - - - -  - - - - - - 

  const { data: is_active_plan, loading: is_activate_plan_loading, error: is_active_plan_error, refetch: refetch_active_plan } = useQuery(is_user_added, { variables: { did: did }, fetchPolicy : 'no-cache' })

  const { data: expire_data, loading: expire_loading, error: expire_error } = useQuery(check_plan_expire, { variables: { "input": { "did": did } }, fetchPolicy : 'no-cache' });

  const { data: is_active_package, loading : is_loading_active_plan, error : active_plan_error} = useQuery(check_active_plan, {variables : { "input": { "did": did } }})

  useEffect(() => {

    if (is_active_plan) {
      dispatch(planStatusSlice.actions.saveStatus({
        planActive: is_active_plan.isUserExistsOnChain.isRegistered,
        planExpire: planExpired.planExpire,
        planSpace: null
      }))
    }
    if (is_active_plan_error) {
      console.log("error in fetch plan status", is_active_plan_error.message)
    }
  }, [is_active_plan, is_activate_plan_loading, is_active_plan_error, dispatch, planExpired.planExpire, refetch_active_plan])

  useEffect(() => {
    if(is_active_package){
      console.log("this is active plan status : ", is_active_package)
    }
    if(active_plan_error){
      console.log("active package error :", active_plan_error.message)
      dispatch(planStatusSlice.actions.saveStatus({
        planActive: false,
        planExpire: planExpired.planExpire,
        planSpace: null
      }))
    }
  },[is_active_package, is_loading_active_plan, active_plan_error, planExpired.planExpire, dispatch])

  // check if plan expire or not
  const check_plan_expire_gql = () => {
    if (expire_data) {
      dispatch(planStatusSlice.actions.saveStatus({
        planActive: planExpired.planActive,
        planExpire: expire_data.checkPackageExpire.Ok ? expire_data.checkPackageExpire.Ok : false,
        planSpace: null
      }))
    }
    if (expire_loading) {
      console.log("loading plan expire loading  :")
    }
    if (expire_error) {
      console.log("error is plan expire :", expire_error.message)
      dispatch(planStatusSlice.actions.saveStatus({
        planActive: planExpired.planActive,
        planExpire: planExpired.planExpire,
        planSpace: null
      }))
    }
  }


  return (
    <section className={`mr-4 ${web_theme ? "bg-dashboard-home-bg1" : 'bg-sidebar-bg-lite text-sidebar-text-lite2'} rounded-[25px] p-5 h-screen flex ${!avalibilityData.allreadyRegisteredWallet && !avalibilityData.userAdded && !avalibilityData.noUser ? "" : "pointer-events-none grayscale blur-sm"}`}>

      {/* user adding function robot status */}
      {!planExpired.planActive && <NavLink to="/dashboard/billing">
        <div className="fixed z-[99999] right-8 bottom-[210px] w-[400px] inline-block text-sm text-white border border-gray-200 rounded-lg shadow-sm bg-red-400 animate__animated animate__fadeInRight animate__fast">
          {/* Header with MetaMask Logo */}
          <div className="px-3 py-2 bg-red-600 border-b border-gray-200 rounded-t-lg flex gap-2 items-center">
            <div className="p-2 rounded-full bg-white">
              <img src={iconExpired} className="w-[30px]" alt="" />
            </div>
            <h3 className="font-semibold text-white text-[16px]">Oops! plan was not detected !</h3>
          </div>

          {/* Content */}
          <div className="px-3 py-4 flex flex-col">
            <h4 className='font-bold text-black mb-3'>Please activate a plan to continue uploading ! </h4>
          </div>

          {/* Triangle Pointer */}
          <div className="absolute bottom-0 right-20 transform translate-y-full w-6 h-6 border-t-[30px] border-t-red-400 border-l-[30px] border-l-transparent"></div>
        </div>

        {/* div ai robot div */}
        <div className='z-[1000] fixed -bottom-10 -right-12 -rotate-6 rounded-full p-5 animate__animated animate__fadeInRight animate__fast'>
          <img src={robot} className='w-[150px] opacity-100 ' alt="" />
        </div>
      </NavLink>}

      {/* plan activation adding funcntion robot status */}
      {planExpired.planActive && planExpired.planExpire && <NavLink to="/dashboard/billing">
        <div className="fixed z-[99999] right-8 bottom-[210px] w-[400px] inline-block text-sm text-white border border-gray-200 rounded-lg shadow-sm bg-red-400 animate__animated animate__fadeInRight animate__fast">
          {/* Header with MetaMask Logo */}
          <div className="px-3 py-2 bg-red-600 border-b border-gray-200 rounded-t-lg flex gap-2 items-center">
            <div className="p-2 rounded-full bg-white">
              <img src={iconExpired} className="w-[30px]" alt="" />
            </div>
            <h3 className="font-semibold text-white text-[16px]">Oops! plan was expired !</h3>
          </div>

          {/* Content */}
          <div className="px-3 py-4 flex flex-col">
            <h4 className='font-bold text-black mb-3'>your current plan was expired please re-new your plan to continue ! </h4>
          </div>

          {/* Triangle Pointer */}
          <div className="absolute bottom-0 right-20 transform translate-y-full w-6 h-6 border-t-[30px] border-t-red-400 border-l-[30px] border-l-transparent"></div>
        </div>

        {/* div ai robot div */}
        <div className='z-[1000] fixed -bottom-10 -right-12 -rotate-6 rounded-full p-5 animate__animated animate__fadeInRight animate__fast'>
          <img src={robot} className='w-[150px] opacity-100 ' alt="" />
        </div>
      </NavLink>}

      <div className={`w-full`}>
        <div className={`flex ${blur}`}>
          {/* topic container */}
          <div className={`flex flex-col mt-2 mb-1 w-full ${web_theme ? 'text-white/70' : 'text-sidebar-text-lite2'}`}>
            <div className='ml-2 opacity-50 text-[.9rem] flex flex-col gap-2'>
              <h3>File Upload</h3>
              <hr className={`${web_theme ? "text-white bg-white opacity-30 h-[1px]" : " bg-sidebar-text-lite2 opacity-20 h-[2px]"} w-[99%] `} />
            </div>
          </div>
        </div>


        {/* drag and drop file upload section */}
        <div
          className={` ${web_theme ? 'bg-dashboard-home-bg1' : 'bg-sidebar-text-lite2/10 shadow-md'} ${blur} ${!planExpired.planActive || planExpired.planExpire ? 'cursor-crosshair pointer-events-none' : 'cursor-pointer'} rounded-[25px] h-auto my-2 flex justify-center items-center`}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          {/* Hidden File Input */}
          <input
            disabled={planExpired.planExpire || !planExpired.planActive}
            onChange={handleBulkUpload}
            id="file-types"
            type="file"
            multiple
            hidden
          />

          {/* Upload Section */}
          <label
            htmlFor="file-types"
            className={`${!planExpired.planActive ? 'cursor-crosshair pointer-events-none' : 'cursor-pointer'}
              rounded-[25px] h-full w-[99%] py-2 my-2 border border-dashed flex flex-col justify-center items-center ${web_theme ? 'bg-dashboard-home-bg1 border-white/20' : 'border-sidebar-text-lite2/10'
              } ${isDragging ? 'border-blue-500 bg-blue-100' : ''}`} // Highlight on drag
          >
            {/* Image */}
            <div className="w-full flex justify-center">
              <img src={img_cloud} className="w-[120px] grayscale opacity-30" alt="Upload" />
            </div>

            {/* Upload Text */}
            <div className="flex w-full justify-center items-center flex-col my-2">
              <h2 className={`${web_theme ? 'text-white' : ''} text-[0.9rem]`}>
                {isDragging ? 'Drop files here' : 'Click to upload or '}
                <span className="opacity-50">Drag and Drop</span>
              </h2>
              <h2 className={`${web_theme ? 'text-white' : ''} text-[0.9rem]`}>
                <span className="opacity-50">(Any file format is supported)</span>
              </h2>
            </div>
          </label>
        </div>

        {/* Show Import Items */}
        {bulkUploadsLoop && <div class={`${blur} rounded-[25px] h-fit ${web_theme ? 'shadow-sm shadow-white/10' : 'shadow-sm shadow-black/10'} relative after:overflow-x-auto mt-10 p-4 ${planStats.planExpire ? "grayscale pointer-events-none" : ""}`}>
          <table class="table-auto border-collapse w-full">
            {/* topic container */}
            <div className={`flex flex-col mt-2 mb-1 w-full ${web_theme ? 'text-white/70' : 'text-sidebar-text-lite2'}`}>
              <div className='ml-2 opacity-50 text-[.9rem] flex flex-col gap-2'>
                <h3>Selected Items</h3>
                <hr className='h-[1px] w-[99%] opacity-30' />
              </div>
            </div>

            <tbody class="text-sm font-normal text-gray-400">
              {bulkUploadsLoop ?
                bulkUploadsLoop.map((uploadItem) => {
                  return (
                    <tr class=" py-1">
                      <div>
                        <td class="px-4 py-4 flex justify-between">
                          <div><h2>{uploadItem.name.length > 60 ? uploadItem.name.slice(0, 60) + ' ...' : uploadItem.name}</h2></div>

                          <div className="flex gap-4">
                            <div>
                              <h1>{((uploadItem.size).toString().length > 5) ? (uploadItem.size / 1000000).toFixed(2) + " MiB" : (uploadItem.size / 1000).toFixed(2) + " KiB"}</h1>
                            </div>

                            <div className="gap-2 flex items-center"></div>
                          </div>
                        </td>
                      </div>
                    </tr>
                  );
                }) :
                <div className={`w-full flex justify-center items-center ${web_theme ? "text-white/50" : ''} my-2 `}>
                  <h3 className="">No file selected</h3>
                </div>
              }

              {uploadBtnShow && (
                <div>
                  {!singleOrBatch ? (
                    <button
                      onClick={file_upload_v2}
                      className="cursor-pointer flex absolute top-[-10px] right-3 w-[80px]  bg-gradient-to-r from-bethel-orange to- to-bethel-newgreen xxxs:w-full hover:text-white px-3 py-1 hover:bg-black rounded-md"
                    >
                      <div className="  w-full">
                        <span className=" flex items-center justify-center font-bold text-black hover:text-white font-raleway">
                          Upload
                        </span>
                      </div>
                    </button>
                  ) : (
                    <button
                      onClick={file_upload_v2}
                      className="cursor-pointer flex absolute top-[-10px] right-3 w-[80px]  bg-gradient-to-r from-bethel-orange to- to-bethel-newgreen xxxs:w-full hover:text-white px-3 py-1 hover:bg-black rounded-md"
                    >
                      <div className="w-full">
                        <span className=" flex items-center justify-center font-bold text-black hover:text-white font-raleway">
                          Upload
                        </span>
                      </div>
                    </button>
                  )}
                </div>
              )}

              {uploadBtnShow && (
                <button
                  onClick={deleteBulkuploads}
                  className="rounded-md cursor-pointer flex absolute top-[-10px] w-[80px] right-24  bg-red-600 xxxs:w-full hover:text-white px-3 py-1"
                >
                  <div className="  w-full ">
                    <span className=" flex items-center justify-center font-bold text-black hover:text-white font-raleway">
                      Clear
                    </span>
                  </div>
                </button>
              )}
            </tbody>
          </table>

          {/* upload success and fail  */}
          {uploadSuccess && (
            <div
              className=" w-[200px] rounded-sm absolute 
              top-[10px] right-2 py-2 flex-col flex popup items-center justify-center text-white"
              id="popup"
            >
              <div className="flex flex-row items-center justify-center gap-x-2">
                <button className="rounded-full shadow-md">
                  <svg
                    viewBox="0 0 24 24"
                    fill="none"
                    className="w-5 "
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      strokeLinejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                        stroke="#aaff00"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        strokeLinejoin="round"
                      ></path>{" "}
                      <path
                        d="M7.75 12L10.58 14.83L16.25 9.17004"
                        stroke="#aaff00"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        strokeLinejoin="round"
                      ></path>{" "}
                    </g>
                  </svg>
                </button>
                <h1 className="text-sm font-sm text-bethel-green font-bold">
                  Upload Complete
                </h1>
              </div>
            </div>
          )}

          {uploadFail && (
            <div
              className=" w-[200px] bg-black/20 border-red-500 border-[1px]  h-[40px] rounded-sm absolute
              top-[-50px] right-2 py-2 flex-col flex popup items-center justify-center text-red-400"
              id="popup"
            >
              <div className="flex flex-row items-center justify-center gap-x-2">
                <button className="rounded-full shadow-md">
                  <img src={iconWrong} alt="" className="w-[20px]" />
                </button>
                <h1 className="text-sm font-sm text-red-500">Upload Fail</h1>
              </div>
            </div>
          )}

          {/* max upload limit exceed */}
          {maxUploadLimit && (
            <div
              className=" w-[250px] bg-black/20 border-red-500 border-[1px]  h-[40px] rounded-sm absolute
              top-[-50px] right-2 py-2 flex-col flex popup items-center justify-center text-red-400"
              id="popup"
            >
              <div className="flex flex-row items-center justify-center gap-x-2">
                <button className="rounded-full shadow-md">
                  <img src={iconWrong} alt="" className="w-[20px]" />
                </button>
                <h1 className="text-[12px] font-xs text-red-500 ">Upload Limit exceeded! <br /> ( max is 40 files )</h1>
              </div>
            </div>
          )}
          {/* end of upload success msg */}
        </div>}
        {/* end of the item for the single file uploads ------------------------------- */}

        {/* topic container */}
        <div className={`flex flex-col mt-8 w-full ${web_theme ? 'text-white/70' : 'text-sidebar-text-lite2'} my-5 ${blur}`}>
          <div className='ml-2 opacity-50 text-[.9rem] flex flex-col gap-2'>
            <h3>uploads and share files</h3>
            <hr className={`${web_theme ? "text-white bg-white opacity-30 h-[1px]" : " bg-sidebar-text-lite2 opacity-20 h-[2px]"} w-[99%] `} />
          </div>
        </div>

        {/* download and share files toggle */}
        <div className={`${blur} flex rounded-[25px] ${web_theme ? 'bg-dashboard-home-bg1' : 'shadow-sm border border-y-sidebar-text-lite2/20'} w-fit p-1 text-[.8rem]`}>
          {/* download data toggle */}
          <button onClick={() => setToggleDetails(false)} className={`flex rounded-[25px] w-[100px] ${toggleDetails ? 'text-sidebar-text-lite2' : 'bg-bethel-green font-bold'} p-1 justify-center items-center `}>
            Downloads
          </button>

          {/* share data toggle */}
          <button onClick={() => setToggleDetails(true)} className={`flex rounded-[25px] w-[100px] ${toggleDetails ? 'bg-bethel-green font-bold' : 'text-sidebar-text-lite2'} p-1 justify-center items-center
            `}>
            Shares
          </button>
        </div>

        <div className="flex w-full lg:flex-row md:flex-col sm:flex-col min-[320px]:flex-col ">
          {!toggleDetails ? <div className="flex w-full">
            <TableWithMoreButton
              changeUploadMethod={changeUploadMethod}
              ref={childRef}
            />
          </div>
            :
            <div className={`flex w-full ${planStats.planExpire ? "grayscale pointer-events-none" : ""}`}>
              <FileSharedComp />
            </div>}
        </div>
      </div>
    </section>
  );
}
