import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    web_theme : true
}

 const web_theme_slice = createSlice({
    name : "Verifier_did",
    initialState,
    reducers: {
    change_theme: (state, action) => action.payload,
  }
})

export default web_theme_slice;