/* eslint-disable no-use-before-define */
import React, {
  useEffect,
  useState,
  forwardRef,
  useRef,
} from "react";

import QRCode from "react-qr-code";
import "animate.css";
import loadingGif from "../Images/Animation-gifs/Rolling-1s-200px(1).gif";
import { useDispatch, useSelector } from "react-redux";
import threeDots from "../Images/icons/threeDots.png";
import { ethers } from "ethers";
import FileCompBlurSlice from "../reducers/filesCompBlurReducer";
import downloading from "../Images/icons/icons8-downloading.png";
import batchIcon from "../Images/icons/icon-batch.png";
import iconClose from "../Images/icons/icons-close.png"
import iconDone from "../Images/icons/icon-newdone.png"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { GET_ALL_FILES, GET_CIDS, GET_DOWNLOAD_QR, GET_DOWNLOAD_STATUS, GET_FILE_INDEX, GET_INDIVIDUAL_FILE_NAMES } from "../queris";
import icon_share from "../Images/icons/icon-share.png"
import icon_delete from "../Images/icons/icon-delete.png"
import icon_files_lite from "../Images/icons/icon-files-lite.png"
import icon_threedots_lite from "../Images/icons/icon-threedots-lite.png"
import { DELETE_FILE, DOWNLOAD_HALL_BATCH, DOWNLOAD_SINGLE_FILE, SHARE_CLAIM, SHARE_FILE, VERIFY_FILE } from "../mutations";

const TableWithMoreButton = forwardRef((props, ref) => {
  React.useImperativeHandle(ref, () => ({
    getAllFile,
  }));

  const dispatch = useDispatch();

  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowSingle, setSelectedRowSingle] = useState(null);
  const [selectQR, setSelectQR] = useState(null);
  const [dowloadQr, setDownloadQr] = useState(null);
  const [selectedDownload, setSelectedDownload] = useState(null);
  const [qrClaim, setQrClaim] = useState(null);
  const [downloadLink, setDownloadLink] = useState("");
  const [clickedDownloadIndex, setClickedDownloadIndex] = useState(null); //to catch which button was clicked
  const [clickedDownloadIndexForHallBatch, setClickedDownloadIndexForHallBatch] = useState(null)

  // all the bulk uplaod file data
  const [bulkDonwloadDetailsData, setBulkDownloadDetailsData] = useState([]);
  const [singleFileDetails, setSingleFileDetails] = useState([]);

  const [isShareSub, setIsShareSub] = useState(null);
  const [isShareSubBatch, setIsShareSubBatch] = useState(null);
  const [shareDidValue, setShareDidValue] = useState("");
  const [btnGreen, setBtnGreen] = useState(false);
  const [btnGreenSingle, setBtnGreenSingle] = useState(false);
  const [authenticationWait, setAuthenticationWait] = useState(false);
  const [isSharingClaim, setIsSharingCliam] = useState(false);

  //set batch file indezx
  const [batchFileIndex, setBatchFileIndex] = useState(null);
  const [shareFileIndex, setShareFileIndex] = useState(null);
  const [hallBatch, setHallBatch] = useState(null);

  // sharing file process
  const [shareingFail, setSharinFail] = useState(false)

  // single file name arr
  const [fileNames, setFileNames] = useState({});

  // store variables 
  const web_theme = useSelector((state) => state.webThemeReducer.web_theme) // change theme variable 
  const upload_status = useSelector((state) => state.uploadStatusReducer)



  // data for the MIDDLE QR
  const [selectIndex, setSelectIndex] = useState("24");
  const [selectedItem, setSelectedItem] = useState(null);
  const [shareDidIndex, setShareDidIndex] = useState(null);
  const [selectedRowBulk, setSelectedRowBulk] = useState(null);
  const [isFileDownloading, setIsFileDownloading] = useState(false);

  //vefifiation msg
  const [shareVerify, setShareVerify] = useState(false);
  const [downloadVerify, setDownloadVerify] = useState(false);
  const [downloadFail, setDownloadFail] = useState(false);
  const [toggleBatchDownloadButton, setToggleBatchDownloadButton] = useState(false)

  // get share did
  const [shareDIDs, setShareDIDs] = useState(null);

  //get batch single files
  const [batchFiles, setBatchFiles] = useState(null);

  const did = useSelector((state) => state.DidReducer);
  const blur = useSelector((state) => state.fileBlurReducer);

  // donwloadble file nae 
  const [fileName, setFileName] = useState(null)

  // for Claim QR loading screen 
  const [QRStatus, setQRStatus] = useState({ is_loading: false, complete: false, msg: '' })

  // deleting status 
  const [delete_status, setDelete_status] = useState({ is_deleting: false, is_fisnished: false, errMsg: null })

  // downloading status 
  const [download_status, setDownload_status] = useState({ is_loading: false, is_complete: false, is_loading_single: false })

  const handleMoreButtonClick = (index) => {
    setSelectedRow(index === selectedRow ? null : index);
    setIsShareSub("Disapear");
    setToggleShare(false)
  };

  const divRef = useRef()

  const handleMoreButtonClickforSingle = (index) => {
    setSelectedRowSingle(index === selectedRowSingle ? null : index);
    setIsShareSub("Disapear");
    setToggleShare(false)
  };

  // close toggle handle when click else where
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        handleMoreButtonClickforSingle(null);  // Toggle to false if clicked outside the div
      }
    };

    // Add event listener when component is mounted
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup the event listener when the component is unmounted
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [])

  const handleMoreButtonBulkFiles = (index) => {
    setShareFileIndex(index);
    setSelectedRowBulk(index === selectedRowBulk ? null : index);
    setToggleShare(false)
  };

  //issue claim for bulk file fn
  const IssueClaimBulk = async (index) => {
    setQRStatus((prev) => ({ ...prev, is_loading: true, msg: "QR is loading..." }))

    setBtnGreen(false);
    setQrClaim(null);
    dispatch(FileCompBlurSlice.actions.changeBlur("blur-md"));
    setSelectIndex(index);
    const selectedItem = bulkDonwloadDetailsData[index];

    setSelectQR(index);


    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const address = signer.getAddress()
    const Acc = await address

    const hd5HashRes = await fetch(process.env.REACT_APP_BACKEND_URL + "get-claim?Did=" + did + "&BatchHash=" + selectedItem.BatchHash + "&ownerAddress=" + Acc)

    if (hd5HashRes.status === 200) {
      const claim = await hd5HashRes.json()

      setQrClaim(JSON.parse(claim.claim));

      setQRStatus((prev) => ({ ...prev, msg: "Complete Please Scan Now!" }))

      setTimeout(() => {
        setQRStatus((prev) => ({ ...prev, is_loading: false, msg: "" }))
      }, 2000);


      provider
        .waitForTransaction(claim.txHash)
        .then((_receipt) => {
          if (_receipt.status === 0) {
            console.log("Transaction failed.");
          } else {
            console.log("Transaction successful.");

            getAllFile();

          }
        })
        .catch((error) => {
          console.log("Error:", error);
          setIsSharingCliam(false);
          setQRStatus((prev) => ({ ...prev, is_loading: false, msg: "" }))
        });
    }
    else {
      try {
        console.log("1")
        const res = await fetch(process.env.REACT_APP_BACKEND_URL + "update-file-claim", {
          method: "POST",
          body: JSON.stringify({
            DID: did,
            BatchHash: selectedItem.BatchHash,
            Owner: Acc
          })
        })

        if (res.status === 201) {
          console.log("2")
          const claim = await res.json()
          console.log(claim)
          setQrClaim(JSON.parse(claim.ClaimQr));
        }
      } catch (e) {
        console.log(e)
      }
      setQRStatus((prev) => ({ ...prev, msg: "Failed !" }))

      setTimeout(() => {
        setQRStatus((prev) => ({ ...prev, is_loading: false, msg: "" }))
        closeQR()
      }, 2000);
    }
  }


  const set_file_name = (fileName) => {
    setFileName(fileName)
  }

  // download the file fn
  const getFile = async (index, download_link, file_name) => {
    const response = await fetch(download_link);
    const blob = await response.blob();
    const blobUrl = window.URL.createObjectURL(blob);
    // set custom file name 


    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = file_name

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(blobUrl);
    // setDownloadLink(CID);
  };

  const stopIntervalRef = useRef(false)

  // close QRs fn
  const closeQR = () => {
    setSelectedDownload("24")
    setSelectQR("24")

    stopIntervalRef.current = true;
    setDownload_status((prev) => ({ ...prev, is_loading : false, is_loading_single : false}))
    dispatch(FileCompBlurSlice.actions.changeBlur(null));
  };

  // hide and show share sec
  const [toggleShare, setToggleShare] = useState(false)

  // open and close DID input Filds fn
  const shareDid = (index) => {
    setIsShareSub(index === isShareSub ? null : index);
    setSelectedRowBulk(null);
    setBatchFileIndex(index);
    setToggleShare(true)
  };

  const shareDidBatchFn = (index) => {
    setIsShareSubBatch(index === isShareSubBatch ? null : index);
    setSelectedRowBulk(null);
    setBatchFileIndex(index);
    setToggleShare(true)
  };

  // close hide and show
  const closeShareDid = (index) => {
    setToggleShare(true)
    setSelectedRowSingle(index === selectedRowSingle ? null : index);
    setIsShareSub("Disapear");
    setIsShareSubBatch("Disapear");
  }

  const planStats = useSelector((state) => state.planStatusReducer)


  // ----------------------------------------------------------------------------- graph QL fn for Queries  -----------------------------------------------------------------------------

  const { data: files_data, loading: files_loading, error: files_error, refetch } = useQuery(GET_ALL_FILES, { variables: { "input": { "owner_did": did } }, fetchPolicy: 'no-cache' }) // get all files 

  const [fetch_file_names, { data: files_name_data, loading: files_name_loading, error: files_name_error }] = useLazyQuery(GET_INDIVIDUAL_FILE_NAMES) // get all file names 

  const [get_download_claim] = useLazyQuery(GET_DOWNLOAD_QR) // 

  const [get_download_status] = useLazyQuery(GET_DOWNLOAD_STATUS) // get donwload file status 

  const [get_file_index] = useLazyQuery(GET_FILE_INDEX)

  const [get_file_cids] = useLazyQuery(GET_CIDS)

  const [verify_file] = useMutation(VERIFY_FILE)

  const [donwload_single_file] = useMutation(DOWNLOAD_SINGLE_FILE)

  const [share_file_gql] = useMutation(SHARE_FILE)

  const [share_claim_gql] = useMutation(SHARE_CLAIM)

  const [delete_file] = useMutation(DELETE_FILE)

  const [download_hall_batch] = useMutation(DOWNLOAD_HALL_BATCH)



  // fetch all files in GraphQL
  useEffect(() => {
    if (files_data) {
      // get all files 
      getAllFile(files_data.getAllBatches.batches)
    }
    if (files_loading) {
      console.log("this is files loading")
    }
    if (files_error) {
      console.log("this is files error", files_error.message)
    }
  }, [files_data, files_loading, files_error, refetch, delete_status.is_fisnished])

  // refetch when update the uploads
  useEffect(() => {
    refetch()
  }, [upload_status.is_complete, dispatch, delete_status.is_fisnished, refetch])

  // get all batch files from graphQL fn
  const getAllFile = async (files_data_array) => {

    try {
      let SingleFileArr = [];
      let BatchFileArr = [];

      // section uploads into single or batch fn
      files_data_array.map((item, index) => {
        if (item.filesCount === 1) {
          SingleFileArr.push(item);
        } else {
          BatchFileArr.push(item);
        }
      });

      setBulkDownloadDetailsData(BatchFileArr);
      setSingleFileDetails(SingleFileArr);
    } catch (error) {
      console.log(error)
    }
  };


  // get individual file names fn
  const getIndividualFileName = async (index) => {
    const batchHash = singleFileDetails[index].batchhash;

    const data = await fetch_file_names({
      variables: {
        "did": did,
        "batchHash": batchHash
      },
      fetchPolicy: 'no-cache'
    })

    if (data.data) {
      setFileNames((prev) => ({ ...prev, [index]: data.data.getFileNames.fileNameData[0] && data.data.getFileNames.fileNameData[0].fileName }))
    }

  };


  // set individual file names 
  useEffect(() => {
    singleFileDetails.forEach((_, index) => {
      getIndividualFileName(index);
    });
  }, [singleFileDetails]);


  // show batch fiels
  const showBatchFiles = async (index) => {
    setBatchFiles(null);
    setToggleBatchDownloadButton(!toggleBatchDownloadButton)
    setBtnGreen(false);
    setShareDIDs(null);
    setBatchFileIndex(index);

    const batchItems = bulkDonwloadDetailsData[index];
    setHallBatch(batchItems);

    // get files details
    const data = await fetch_file_names({
      variables: {
        "did": did,
        "batchHash": batchItems.batchhash
      },
      fetchPolicy: 'no-cache'
    })

    if (data) {
      setBatchFiles(data.data.getFileNames.fileNameData)
    }
    setShareDidIndex(index === shareDidIndex ? null : index);
  };

  const IssueClaimBulkSingle = () => {

  }

  // make file verify in SC
  const file_verify_fn = async (batch_hash) => {
    try {
      const file_verify = await verify_file({
        variables: {
          "ownerDid": did,
          "batchHash": batch_hash
        }
      })

      if (file_verify.data) {
        console.log(file_verify.data)
        return true
      }
    } catch (error) {
      console.log("file verification error; ", error)
    }
  }

  // get file index fn
  const get_file_index_fn = async (batch_hash, file_hash) => {
    try {
      const index = await get_file_index({
        variables: {
          "input": {
            "batchhash": batch_hash,
            "filehash": file_hash,
            "owner_did": did
          }
        }
      })

      if (index.data) {
        console.log(index.data.getBatchFile.index)
        return index.data.getBatchFile.index
      }
    } catch (error) {
      console.log("get file index error", error)
    }
  }

  // get cids 
  const get_cids_fn = async (file_index) => {
    try {
      const res = await get_file_cids({ variables: { "index": file_index, "did": did } })

      if (res.data) {
        return res.data.getCIDS.cidList
      }
    } catch (error) {
      console.log("cid get error : ", error)
    }
  }

  // download single file 
  const download_single_file_fn = async (batchHash, fileHash, fileName, cids) => {
    try {
      const donwload_file = await donwload_single_file({
        variables: {
          "batchHash": batchHash,
          "fileHash": fileHash,
          "fileName": fileName,
          "did": did,
          "cids": cids
        }
      })

      if (donwload_file.data) {
        return donwload_file.data.downloadFile.URL
      }
    } catch (error) {
      console.log("error in download file : ", error)
    }
  }

  //download files for batch bulk fn
  const downloadFileBulkSingle = async (index) => {
    stopIntervalRef.current = false // set  state for get status 
    setBtnGreenSingle(false);
    setClickedDownloadIndexForHallBatch(null);
    dispatch(FileCompBlurSlice.actions.changeBlur("blur-md"));

    // for download status 
    setSelectIndex(index);
    setDownload_status((prev) => ({...prev, is_loading_single : true}))

    const selectedItemxx = singleFileDetails[index];
    setSelectedItem(selectedItemxx);

    const BatchHash = selectedItemxx.batchhash
    const file_hash = selectedItemxx.fileHashes[0]

    try {
      // file verification 
      await file_verify_fn(BatchHash)

      // get download file claim 
      const download_cliam = await get_download_claim({ variables: { "did": did, "batchHash": BatchHash }, fetchPolicy: 'no-cache' })

      let session_id = null

      if (download_cliam.data) {

        // set download QR data
        setDownloadQr(JSON.stringify(download_cliam.data.getDownloadQR))

        // get session id
        session_id = download_cliam.data.getDownloadQR.SessionID

        setClickedDownloadIndex(index);
        setSelectedDownload(index === selectedRow ? null : index);

        // recursive fn for zkp verification
        const get_download_status_fn = async () => {
          console.log(session_id)

          if (stopIntervalRef.current){
            setDownload_status((prev) => ({ ...prev, is_loading_single : false }))
            return
          };

          try {
            const data = await get_download_status({ variables: { "sessionId": session_id } })

            if (data.data) {

              const file_index = await get_file_index_fn(BatchHash, file_hash) // to get index 

              const file_cids = await get_cids_fn(file_index)

              const file_name = fileNames[index]

              const download_link = await download_single_file_fn(BatchHash, file_hash, file_name, file_cids)

              // setCID(download_link);
              setSelectedDownload("24");
              closeQR();
              setAuthenticationWait(false);
              setDownloadLink(download_link);


              setTimeout(() => {
                setDownloadLink(download_link);
                setFileName(file_name)
                setBtnGreenSingle(true);
              }, 2000);

              setDownloadVerify(true);
              setIsFileDownloading(false);

              setTimeout(() => {
                setDownloadVerify(false);
              }, 2000);

              // get file downloaded
              await getFile(index, download_link, file_name)

              setDownload_status((prev) => ({ ...prev, is_loading_single: false }))

              return true
            }
            else {
              setTimeout(() => {
                if (stopIntervalRef.current) return;
                get_download_status_fn(session_id)
              }, 3000);
            }
          } catch (error) {
            console.log(error)
            setDownload_status((prev) => ({ ...prev, is_loading_single: false }))
            return false
          }
        }

        get_download_status_fn()
      }


    } catch (error) {
      console.log("donwload error", error)
      setDownload_status((prev) => ({ ...prev, is_loading_single: false }))
      setDownloadFail(true)
      setSelectedDownload("24");
      setIsFileDownloading(false)
      closeQR();
      setAuthenticationWait(false);

      setTimeout(() => {
        setDownloadFail(false)

      }, 3000);
    }
  };

  //download fields for batch bulk fn
  const downloadFileBulk = async (index) => {
    stopIntervalRef.current = false // set  state for get status 
    setBtnGreen(false);
    dispatch(FileCompBlurSlice.actions.changeBlur("blur-md"));

    // for download status 
    setSelectIndex(index);
    setDownload_status((prev) => ({ ...prev, is_loading: true }))

    setClickedDownloadIndexForHallBatch(index);
    stopIntervalRef.current = false

    const batchHash = bulkDonwloadDetailsData[batchFileIndex].batchhash;
    const file_hash_v2 = bulkDonwloadDetailsData[batchFileIndex].fileHashes[index];

    try {
      // file verification 
      await file_verify_fn(batchHash)

      // get download file claim 
      const download_claim = await get_download_claim({ variables: { "did": did, "batchHash": batchHash }, fetchPolicy: 'no-cache' })

      let session_id = null

      if (download_claim.data) {

        // set download QR data
        setDownloadQr(JSON.stringify(download_claim.data.getDownloadQR))

        // get session id
        session_id = download_claim.data.getDownloadQR.SessionID

        setClickedDownloadIndex(index);
        setSelectedDownload(index === selectedRow ? null : index);


        // recursive fn for zkp verification
        const get_download_status_fn = async () => {

          if (stopIntervalRef.current){
            setDownload_status((prev) => ({ ...prev, is_loading: false }))
            return 
          };

          try {
            const data = await get_download_status({ variables: { "sessionId": session_id } })
            if (data.data) {
              session_id = null

              const file_index = await get_file_index_fn(batchHash, file_hash_v2) // to get index 

              const file_cids = await get_cids_fn(file_index)

              const download_link = await download_single_file_fn(batchHash, file_hash_v2, batchFiles[index].fileName, file_cids)

              // setCID(download_link);
              setSelectedDownload("24");
              closeQR();
              setAuthenticationWait(false);
              setDownloadLink(download_link);


              setTimeout(() => {
                setDownloadLink(download_link);
                setFileName(batchFiles[index].fileName)
                setBtnGreen(true);
              }, 2000);

              setDownloadVerify(true);
              setIsFileDownloading(false);

              setTimeout(() => {
                setDownloadVerify(false);
              }, 2000);

              // get file downloaded
              await getFile(index, download_link, batchFiles[index].fileName)

              setDownload_status((prev) => ({ ...prev, is_loading: false }))
            }
            else {
              setTimeout(() => {
                if (stopIntervalRef.current) return;
                get_download_status_fn()
              }, 3000);
            }
          } catch (error) {
            console.log(error)
            return false
          }
        }

        get_download_status_fn()
      }


    } catch (error) {
      console.log("download error", error)
      setDownload_status((prev) => ({ ...prev, is_loading: false }))
      //  if fails 
      setDownloadFail(true)
      setIsFileDownloading(false)

      setSelectedDownload("24");
      closeQR();
      setAuthenticationWait(false);

      setTimeout(() => {
        setDownloadFail(false)

      }, 3000);
    }
  };

  // download hall batch as one 
  const downloadFilesZip = async (index) => {
    setBtnGreen(false);
    stopIntervalRef.current = false // set  state for get status 
    dispatch(FileCompBlurSlice.actions.changeBlur("blur-md"));

    setSelectIndex(index);
    setDownload_status((prev) => ({ ...prev, is_loading: true }))

    setClickedDownloadIndexForHallBatch(index === clickedDownloadIndexForHallBatch ? null : index)

    const batchHash = bulkDonwloadDetailsData[index].batchhash;
    const file_hashes_arr = bulkDonwloadDetailsData[index].fileHashes
    let file_names_arr = []
    let file_indexs_arr = []
    let file_cids = []
    let file_download_struct = []

    // verify batch hash 
    await file_verify_fn(batchHash)

    try {
      // get download file claim 
      const download_cliam = await get_download_claim({ variables: { "did": did, "batchHash": batchHash }, fetchPolicy: 'no-cache' })

      let session_id = null

      if (download_cliam.data) {

        // set download QR data
        setDownloadQr(JSON.stringify(download_cliam.data.getDownloadQR))

        // get session id
        session_id = download_cliam.data.getDownloadQR.SessionID

        setClickedDownloadIndex(index);
        setSelectedDownload(index === selectedRow ? null : index);

        // recursive fn for zkp verification
        const get_download_status_fn = async () => {
          console.log(session_id)

          if (stopIntervalRef.current){
            setDownload_status((prev) => ({ ...prev, is_loading: false }))
            return
          };
          try {
            const data = await get_download_status({ variables: { "sessionId": session_id } })
            console.log("this is data from hall batch download ", data)
            if (data.data) {
              // get files details
              const data = await fetch_file_names({
                variables: {
                  "did": did,
                  "batchHash": batchHash
                }
              })

              if (data.data) {
                // create file names array
                data.data.getFileNames.fileNameData.map((file) => {
                  file_names_arr.push(file.fileName)
                })

                // get file cid and index
                file_cids = await Promise.all(
                  file_names_arr.map(async (item, index) => {
                    const file_index = await get_file_index_fn(batchHash, file_hashes_arr[index]);
                    file_indexs_arr.push(file_index);

                    return await get_cids_fn(file_index);  // ✅ Returns the CID instead of an object
                  })
                );

                file_names_arr.map((items, i) => {
                  file_download_struct.push({ cids: file_cids[i], fileHash: file_hashes_arr[i], fileName: file_names_arr[i] })
                })

                try {
                  const data_url = await download_hall_batch({
                    variables: {
                      "input": {
                        "batchData": file_download_struct,
                        "batchHash": batchHash,
                        "odid": did
                      }
                    }
                  })

                  if (data_url.data) {
                    console.log("this is real data : ", data_url.data.downloadBatch.URL)

                    if (data_url.data.downloadBatch.DID === did) {
                      set_file_name("batch-" + [index + 1])
                      setSelectedDownload("24");
                      closeQR();
                      setAuthenticationWait(false);

                      setDownloadLink(data_url.data.downloadBatch.URL);

                      setTimeout(() => {
                        setDownloadLink(data_url.data.downloadBatch.URL);

                        setBtnGreen(true);
                      }, 2000);

                      setDownloadVerify(true);
                      setIsFileDownloading(false);
                      setTimeout(() => {
                        setDownloadVerify(false);
                      }, 2000);

                      // get file downloaded
                      await getFile(index, data_url.data.downloadBatch.URL, "batch-" + [index + 1])

                      setDownload_status((prev) => ({ ...prev, is_loading: false }))
                    }
                  }
                } catch (error) {
                  console.log("download error :", error)
                  setDownload_status((prev) => ({ ...prev, is_loading: false }))
                }
              }

            }
            else {
              setTimeout(() => {
                if (stopIntervalRef.current) return;
                get_download_status_fn()
              }, 3000);
            }
          } catch (error) {
            console.log(error)
            setDownload_status((prev) => ({ ...prev, is_loading: false }))
            return false
          }
        }

        get_download_status_fn()
      }


    } catch (error) {
      console.log("download error", error)
      //  if fails 
      setDownloadFail(true)
      setIsFileDownloading(false)

      setSelectedDownload("24");
      closeQR();
      setAuthenticationWait(false);

      setTimeout(() => {
        setDownloadFail(false)

      }, 3000);
    }
  };

  // share single file ( graph QL )
  const submitShareDidSingle = async (index) => {
    setIsSharingCliam(true);
    // setSelectedRowSingle(null);

    const batchHash = singleFileDetails[index].batchhash;
    const fileHash = singleFileDetails[index].fileHashes[0];
    const fileName = fileNames[index]

    console.log("this is file_name", shareDidValue)

    try {
      // deploy share fn 
      const res_share_file = await share_claim_gql({
        variables: {
          "ownerDid": did,
          "sharedDid": shareDidValue,
          "batchhash": batchHash,
          "filehash": fileHash,
          "filename": fileName
        }
      })

      if (res_share_file.data) {
        console.log("trasaction succesfull : : :", res_share_file.data)
        setShareVerify(true);
        setIsSharingCliam(false)

        setTimeout(() => {
          setShareVerify(false);
        }, 3000);

        setTimeout(() => {
          setSelectedRowSingle(null);
          setSelectedRow(null);
          setIsShareSub(null);
        }, 1000);
      }
    } catch (error) {

      console.log("share file is fail", error);
      setSelectedRowSingle(null);
      setSelectedRow("Disapear");
      setIsShareSub("Disapear");
      setIsSharingCliam(false);

      setSharinFail(true) //share claim fail msg
      setTimeout(() => {
        setSharinFail(false) //share claim fail msg
      }, 2000);
    }
  };

  // share batch file ( grapgh QL )
  const submitShareDidBulk = async (index) => {
    setIsSharingCliam(true);

    const SelectItemx = batchFiles[shareFileIndex];

    const batchHash = hallBatch.batchhash
    const file_name = SelectItemx.fileName
    const file_hash = SelectItemx.fileHash

    try {
      // deploy share fn 
      const res_share_file = await share_claim_gql({
        variables: {
          "ownerDid": did,
          "sharedDid": shareDidValue,
          "batchhash": batchHash,
          "filehash": file_hash,
          "filename": file_name
        }
      })

      if (res_share_file.data) {
        console.log("trasaction succesfull : : :", res_share_file.data)
        setShareVerify(true);
        setIsSharingCliam(false)

        setTimeout(() => {
          setShareVerify(false);
        }, 3000);

        setTimeout(() => {
          setSelectedRowSingle(null);
          setSelectedRow(null);
          setIsShareSub(null);
        }, 1000);
      }
    } catch (error) {

      console.log("share file is fail", error);
      setSelectedRowSingle(null);
      setSelectedRow("Disapear");
      setIsShareSub("Disapear");
      setIsSharingCliam(false);

      setSharinFail(true) //share claim fail msg
      setTimeout(() => {
        setSharinFail(false) //share claim fail msg
      }, 2000);
    }
    return

    setSelectedRow("Disapear");
    setIsShareSub("Disapear");

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const address = signer.getAddress()

    const acc = await address

    const shareDidResponse = await fetch(
      process.env.REACT_APP_BACKEND_URL + "shareclaim",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "OwnerDid": did,
          "ShareDid": shareDidValue,
          "BatchHash": batchHash,
          "FileHash": file_hash,
          "Owner": acc,
          "FileName": file_name
        }),
      }
    );


    try {
      if (shareDidResponse.status === 201) {
        const claim = await shareDidResponse.json();

        provider
          .waitForTransaction(claim.TXHash)
          .then((_receipt) => {
            if (_receipt.status === 0) {
              console.log("Transaction failed.");
              setIsSharingCliam(false);

              setSharinFail(true) //share claim fail msg
              setTimeout(() => {
                setSharinFail(false) //share claim fail msg
              }, 3000);
            } else {
              console.log("Transaction successful.");
              setShareVerify(true);
              setIsSharingCliam(false);
              setTimeout(() => {
                setShareVerify(false);
              }, 2000);
            }
          })
          .catch((error) => {
            console.log("Error:", error);
            setIsSharingCliam(false);

            setSharinFail(true) //share claim fail msg

            setTimeout(() => {
              setSharinFail(false) //share claim fail msg
            }, 20000);
          });

        // setTimeout(() => {
        //   setShareVerify(false);
        // }, 2000);
      }
      else {
        setIsSharingCliam(false);

        setSharinFail(true) //share claim fail msg
        setTimeout(() => {
          setSharinFail(false) //share claim fail msg
        }, 3000);
      }
    } catch (error) {
      setIsSharingCliam(false);

      setSharinFail(true) //share claim fail msg
      setTimeout(() => {
        setSharinFail(false) //share claim fail msg
      }, 3000);
    }
  };

  // delete file'
  const delete_file_gql = async (batch_hash, file_hash) => {
    try {
      const res = await delete_file({
        variables: {
          "input": {
            "batchhash": batch_hash,
            "filehash": file_hash,
            "owner_did": did
          }
        }
      })

      if (res.data) {

        setTimeout(() => {
          setDelete_status((prev) => ({ ...prev, is_deleting: false, is_fisnished: true }))
          setShareDidIndex(null);

          refetch()
        }, 3000);

        setTimeout(() => {
          setDelete_status((prev) => ({ ...prev, is_deleting: false, is_fisnished: false }))
          setBatchFiles(null)
        }, 5000);
      }
    } catch (error) {
      console.log("delete file error :", error)
      setDelete_status((prev) => ({ ...prev, errMsg: "deleting fail", }))

      setTimeout(() => {
        setDelete_status((prev) => ({ ...prev, errMsg: null, is_deleting: false }))
      }, 3000);

    }
  }

  // delete batch's single file
  const delete_batch_single_file = async (index) => {
    setDelete_status((prev) => ({ ...prev, is_deleting: true }))
    setSelectedRowBulk(null)

    const batch_hash = bulkDonwloadDetailsData[batchFileIndex].batchhash;
    const file_hash = batchFiles[index].fileHash

    await delete_file_gql(batch_hash, file_hash) // call delete graph QL fn 
  }

  // delete single file
  const Delete_single_file = async (index) => {
    setDelete_status((prev) => ({ ...prev, is_deleting: true }))
    setSelectedRowSingle(null)

    const batch_hash = singleFileDetails[index].batchhash;
    const file_hash = singleFileDetails[index].fileHashes[0]

    await delete_file_gql(batch_hash, file_hash) // call grapgh ql f

    await refetch()
  }

  return (
    <div className={`flex flex-col w-full px-2 ${planStats.planExpire ? "grayscale pointer-events-none" : ""}`}>

      {/* share Sucess msg */}
      {shareVerify && (
        <div
          className="fixed right-2 bottom-0 w-[250px] bg-black z-[1000] border-green-500 border-t-[1px] tracking-wider font-bold rounded-t-[25px] h-[40px]
             py-2 flex-col flex popup items-center justify-center text-green-500 animate__animated animate__fadeInUp animate__faster"
          id="popup"
        >
          <div className="flex flex-row items-center justify-center gap-x-2">
            <button className="rounded-full shadow-md">
              <svg
                viewBox="0 0 24 24"
                fill="none"
                className="w-5 "
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                    stroke="#aaff00"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>{" "}
                  <path
                    d="M7.75 12L10.58 14.83L16.25 9.17004"
                    stroke="#aaff00"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>{" "}
                </g>
              </svg>
            </button>
            <h1 className="text-sm font-sm text-bethel-green ">
              Share Claim Success
            </h1>
          </div>
        </div>
      )}

      {/* share Sucess msg */}
      {shareingFail && (
        <div
          className="fixed right-2 bottom-0 w-[250px] bg-black z-[1000] border-red-500 border-t-[1px] tracking-wider font-bold rounded-t-[25px] h-[40px]
             py-2 flex-col flex popup items-center justify-center text-red-500 animate__animated animate__fadeInUp animate__faster"
          id="popup"
        >
          <div className="flex flex-row items-center justify-center gap-x-2">
            <button className="rounded-full shadow-md">
              <img src={iconClose} className="w-[25px]" alt="" />
            </button>
            <h1 className="text-sm font-sm  ">
              Share Claim Fail
            </h1>
          </div>
        </div>
      )}

      {/* download verfiy msg */}
      {downloadVerify && (
        <div
          className="fixed right-2 bottom-0 w-[250px] bg-black z-[1000] border-bethel-green border-t-[1px] font-bold rounded-t-[25px] h-[40px]
             py-2 flex-col flex popup items-center justify-center text-white"
          id="popup"
        >
          <div className="flex flex-row items-center justify-center gap-x-2">
            <button className="rounded-full shadow-md">
              <svg
                viewBox="0 0 24 24"
                fill="none"
                className="w-5 "
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                    stroke="#aaff00"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>{" "}
                  <path
                    d="M7.75 12L10.58 14.83L16.25 9.17004"
                    stroke="#aaff00"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>{" "}
                </g>
              </svg>
            </button>
            <h1 className="text-sm font-sm text-bethel-green ">
              Download Verified
            </h1>
          </div>
        </div>
      )}

      {downloadFail && (
        <div
          className="fixed right-2 bottom-0 w-[250px] bg-black z-[1000] border-red-500 border-t-[1px] tracking-wider font-bold rounded-t-[25px] h-[40px]
             py-2 flex-col flex popup items-center justify-center text-white"
          id="popup"
        >
          <div className="flex flex-row items-center justify-center gap-x-2">
            <button className="rounded-full shadow-md">
            </button>
            <h1 className="text-sm font-sm text-red-500 ">
              Download Fail
            </h1>
          </div>
        </div>
      )}

      {/* delete msg */}
      {/* download verfiy msg */}
      {delete_status.is_fisnished && (
        <div
          className="fixed right-2 bottom-0 w-[250px] bg-black z-[1000] border-bethel-green border-t-[1px] rounded-t-[25px] h-[40px]
             py-2 flex-col flex popup items-center justify-center text-white"
          id="popup"
        >
          <div className="flex flex-row items-center justify-center gap-x-2">
            <button className="rounded-full shadow-md">
              <svg
                viewBox="0 0 24 24"
                fill="none"
                className="w-5 "
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                    stroke="#aaff00"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>{" "}
                  <path
                    d="M7.75 12L10.58 14.83L16.25 9.17004"
                    stroke="#aaff00"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>{" "}
                </g>
              </svg>
            </button>
            <h1 className="text-sm font-sm text-bethel-green ">
              file deleted
            </h1>
          </div>
        </div>
      )}

      {/* download verfiy msg */}
      {delete_status.errMsg && (
        <div
          className="fixed right-2 bottom-0 w-[250px] bg-black z-[1000] border-red-500 border-t-[1px] rounded-t-[25px] h-[40px]
             py-2 flex-col flex popup items-center justify-center text-white"
          id="popup"
        >
          <div className="flex flex-row items-center justify-center gap-x-2">
            <button className="rounded-full shadow-md">
              <img src={iconClose} className="w-[25px]" alt="" />
            </button>
            <h1 className="text-sm font-sm text-red-500 ">
              delete fail
            </h1>
          </div>
        </div>
      )}

      {delete_status.is_deleting && (
        <div
          className="fixed right-2 bottom-0 w-[250px] bg-black z-[1000] border-bethel-green border-t-[1px] rounded-t-[25px] h-[40px]
             py-2 flex-col flex popup items-center justify-center text-white"
          id="popup"
        >
          <div className="flex flex-row items-center justify-center gap-x-2 relative">
            <div>
              <div className="loader_upload absolute -right-9 -top-1">
              </div>
              <h1 className="text-sm font-sm text-bethel-green ">
                Deleting file{" "}
              </h1>
            </div>
          </div>
        </div>
      )}

      {isFileDownloading && (
        <div
          className="fixed right-2 bottom-0 w-[250px] bg-black z-[1000] border-bethel-green border-t-[1px] rounded-t-[25px] h-[40px]
             py-2 flex-col flex popup items-center justify-center text-white"
          id="popup"
        >
          <div className="flex flex-row items-center justify-center gap-x-2 relative">
            <div>
              <div className="loader_upload absolute -right-9 -top-1">
              </div>
              <h1 className="text-sm font-sm text-bethel-green ">
                Verifying file{" "}
              </h1>
            </div>
          </div>
        </div>
      )}

      {isSharingClaim &&
        <div className='fixed right-2 bottom-3 w-[200px] bg-black/20 border-bethel-green border-[1px]  h-[40px] rounded-sm hidden
             py-2 flex-col popup items-center justify-center text-white' id="popup">

          <div className='flex flex-row items-center justify-center gap-x-2'>
            <div className="rounded-full shadow-md">
              <img src={loadingGif} className="w-[20px]" alt="" />
            </div>
            <h1 className='text-sm font-sm text-bethel-green '>Sharing ...</h1>
          </div>
        </div>
      }

      <div className={`w-full ${blur}`}>
        <div className="w-full">
          <table className="table w-full text-sm text-gray-400 border-separate border-transparent rounded-md border-spacing-2 mt-4">
            <div className={`flex lg:flex-row md:flex-col w-full h-full gap-4 ${web_theme ? 'bg-dashboard-home-bg1' : 'bg-sidebar-bg-lite shadow-md border border-sidebar-text-lite2/10'} p-3 rounded-[25px] h-auto`}>
              {/* batch file list div */}
              <div className="w-full flex flex-col">
                {/* topic section */}
                <div className={`${web_theme ? "text-white/30" : 'text-sidebar-text-lite2/40'} px-3 py-2`}>
                  batch files
                </div>

                {bulkDonwloadDetailsData &&
                  bulkDonwloadDetailsData.map((item, index) => (
                    <div key={index} className="w-full">
                      <div className={` animate__animated animate__fadeInDown animate__faster relative w-full z-[99999] flex items-center justify-between ${web_theme ? 'bg-dashboard-small-card text-white' : 'bg-sidebar-bg-lite border border-sidebar-text-lite2/30 text-sidebar-text-lite2 '} animate__animated animate__fadeIn  mb-2 rounded-[25px] px-4 py-1 z-[100] `}>
                        <div className="flex items-center justify-center w-full">
                          {/* bathc icon image div */}
                          <div className="flex items-center justify-center object-cover w-12 h-12 rounded-full">
                            <img src={web_theme ? batchIcon : icon_files_lite} alt="" className="w-[20px]" />
                          </div>

                          {/* show batch files div */}
                          <button onClick={() => showBatchFiles(index)} className="w-full" >
                            <div className="ml-3 w-[200px] flex flex-col items-start justify-start">
                              <div>{`Batch-${index + 1}`}</div>
                              <div className=" text-[10px] flex gap-1">
                                <h3>
                                  <h3 className="opacity-70">{item.filesCount - item.deletedFilesCount} files</h3>
                                </h3>
                              </div>
                            </div>
                          </button>

                        </div>

                        {/* verify and donwload buttons  */}
                        {!toggleBatchDownloadButton && <div className="flex flex-row p-3 gap-2 ">
                          {/*end file download button */}
                          <div className="w-[50px] flex items-end justify-end mr-2 gap-1">
                          </div>
                          <div className="relative">
                            <div>
                              {selectIndex === index && download_status.is_loading ?
                                <div className="loader_upload"></div>
                                :
                                <button onClick={() => downloadFilesZip(index)} className="cursor-pointer flex relative w-full rounded-[25px] bg-red-500 xs:w-full hover:text-white px-3 py-1" >
                                  <div className=" button-container w-full">
                                    <span className=" flex items-center justify-center font-bold text-black hover:text-white font-raleway">
                                      Download
                                    </span>
                                  </div>
                                </button>
                              }
                            </div>
                          </div>
                        </div>
                        }
                      </div>

                      {/* show the bulk files list*/}
                      {shareDidIndex === index && (
                        <div className={`${web_theme ? 'bg-dashboard-home-bg1' : 'bg-sidebar-bg-lite'} relative rounded-[25px] animate__animated animate__fadeInDown animate__faster w-full px-10 py-4 flex flex-col justify-between mb-20`}>
                          <div className="w-full flex flex-col justify-between items-start">
                            {/* share claim show here */}
                            {batchFiles &&
                              batchFiles.map((batch, indexs) => {
                                return (
                                  <div className="w-full flex justify-between items-center mt-2 overflow-scroll">
                                    <div className="flex w-full justify-between">
                                      <h3>{batch.fileName.length > 30 ? batch.fileName.slice(0, 30) + ' ...' : batch.fileName}</h3>
                                      {/* file download button */}
                                      <div className="relative">
                                        {selectIndex === indexs && download_status.is_loading ?
                                          <div className="loader_upload"></div>
                                          :
                                          <button onClick={() => downloadFileBulk(indexs)} className="cursor-pointer flex relative w-full rounded-[25px] bg-red-500 xs:w-full hover:text-white px-3 py-1 hover:scale-105" >
                                            <div className=" button-container w-full">
                                              <span className=" flex items-center justify-center font-bold text-black hover:text-white font-raleway">
                                                Download
                                              </span>
                                            </div>
                                          </button>
                                        }
                                        {/*end of check the download link is here or not */}
                                      </div>
                                    </div>

                                    {/* more three dots */}
                                    <div className="w-[30px] absolute right-4">
                                      <div className="relative z-[500]">
                                        {/* selected row bulk */}
                                        {selectedRowBulk === indexs && (
                                          <tr className="absolute left-[-490px] top-1 z-[1000]">
                                            <td>
                                              <div className="absolute top-0">
                                                {/* Add your unique menu content here */}
                                                <div className="absolute right-0">
                                                  <div className="absolute left-[440px] rounded-[15px] top-[25px] bg-gray-600 flex flex-col p-1 bg-card-sub-text w-[100px] items-start justify-center h-auto">
                                                    <div className="flex flex-col w-full">
                                                      {/* share button div */}
                                                      <div className="relative p-1 z-[2000] w-full justify-center hover:bg-gray-700 hover:scale-105 rounded-[15px] px-3 py-2 ">
                                                        <button className="text-center w-full flex items-center gap-1 animate__animated animate__fadeInDown animate__faster" onClick={() => shareDidBatchFn(index)} >
                                                          <div>
                                                            <img src={icon_share} className="w-[20px]" alt="" />
                                                          </div>

                                                          <div>
                                                            <h3 className="text-white "> Share </h3>
                                                          </div>

                                                        </button>
                                                      </div>

                                                      {/* share button div */}
                                                      <div className="relative z-[2000] p-1 w-full justify-center hover:bg-gray-700 hover:scale-105 rounded-[15px] px-3 py-2">
                                                        <button className="text-center w-full flex items-center gap-1  animate__animated animate__fadeInDown animate__fast" onClick={() => delete_batch_single_file(indexs)} >
                                                          <div>
                                                            <img src={icon_delete} className="w-[20px]" alt="" />
                                                          </div>
                                                          <div>
                                                            <h3 className="text-white ">delete</h3>
                                                          </div>
                                                        </button>
                                                      </div>

                                                    </div>
                                                  </div>
                                                  <div className="w-0 h-0 absolute left-[500px] top-[15px] rotate-[180deg] border-l-[10px] border-l-transparent border-t-[15px] border-t-gray-600 border-r-[10px] border-r-transparent"></div>
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        )}
                                        {/* end of the selected row bulk */}
                                      </div>

                                      {/* three dot div  */}
                                      <button onClick={() => handleMoreButtonBulkFiles(indexs)} className="w-full" >
                                        <img src={web_theme ? threeDots : icon_threedots_lite} alt="" className="w-[20px] ml-4" />
                                      </button>
                                    </div>
                                    {/* end of more three button */}
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      )}
                      {/* end the bulk files list */}

                      {selectedRow === index && (
                        <tr className="menu-row relative z-[2000]">
                          <td colSpan="3">
                            <div className={`menu`}>
                              {/* file content */}
                              <div className="relative">
                                <div className="absolute right-2 top-[-30px] bg-gray-500 flex flex-col py-1 bg-card-sub-text gap-y-1 w-[80px] items-start pl-2 rounded-md justify-center">
                                  {/* reissue button funcs */}
                                  {item.Verified ? (
                                    <div>
                                      <button
                                        onClick={() => IssueClaimBulk(index)}
                                        className="flex flex-row gap-x-4 hover:bg-bethel-green/50 w-full p-1"
                                      >
                                        {/* share button div */}
                                        <div className="relative ">
                                          <button>
                                            <h3 className="text-white text-[12px]">
                                              Re-Issue
                                            </h3>
                                          </button>
                                        </div>
                                      </button>

                                      {/* <button
                                        onClick={() => IssueClaimBulk(index)}
                                        className="flex flex-row gap-x-4 hover:bg-bethel-green/50 w-full p-1"
                                      >
                                       
                                        <div className="relative ">
                                          <button>
                                            <h3 className="text-white text-[12px]">
                                              Delete
                                            </h3>
                                          </button>
                                        </div>
                                      </button> */}
                                    </div>

                                  ) : (
                                    <div className=" text-white "></div>
                                  )}
                                </div>
                                <div
                                  className="w-0 h-0 absolute right-3 top-[-40px] rotate-[180deg]
                            border-l-[10px] border-l-transparent
                            border-t-[15px] border-t-gray-500
                            border-r-[10px] border-r-transparent"
                                ></div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )}

                      {isShareSubBatch === index && (
                        <div className={`p-2 fixed bottom-0 right-6 lg:w-[500px] md:w-[500px] sm:w-[400px] min-[320px]:w-[300px] h-auto ${web_theme ? 'bg-dashboard-small-card/100' : 'bg-black/70 shadow-md border border-sidebar-text-lite2/20'} rounded-t-[25px]`}>
                          {/* share file title */}
                          <div className="flex w-full justify-start  items-center p-1 overflow-hidden px-4 py-4 gap-2">
                            <img src={icon_share} className="w-[20px]" alt="" />
                            <h3 className="text-white text-[12px]">Share files : <span className="text-white/50">{batchFiles && batchFiles[shareFileIndex].fileName.length < 40 ? batchFiles[shareFileIndex].fileName : batchFiles[shareFileIndex].fileName.slice(0, 40) + " ..."}</span></h3>
                          </div>


                          {/* input divs */}
                          <div className={`flex w-full py-4 px-4 gap-2 items-center ${web_theme ? 'bg-dashboard-home-bg1' : 'shadow-md border border-sidebar-text-lite2'} rounded-t-[25px]`}>
                            <input
                              onChange={(e) => setShareDidValue(e.target.value)}
                              type="text"
                              className={`${isSharingClaim || isShareSub ? "w-[320px]" : "w-[380px]"} p-2 border-bethel-green bg-transparent border-b rounded-[25px] placeholder:text-[12px] placeholder:px-4"`}
                              placeholder="enter did here"
                            />
                            <button disabled={isSharingClaim}
                              onClick={() => submitShareDidBulk(index)}
                              className="px-3 py-1 bg-bethel-green rounded-[25px] text-sidebar-text-lite2 font-bold hover:scale-105 border-white text-[.9rem]"
                            >
                              {isSharingClaim ? "sharing..." : "Submit "}
                            </button>


                          </div>

                          {/* close div */}
                          <button onClick={() => closeShareDid(index)} className="flex absolute top-4 right-3">
                            <img src={iconClose} className="w-[20px]" alt="" />
                          </button>

                          {isSharingClaim && <div className="flex absolute top-[85px] right-5 animate__animated animate__fadeIn">
                            <div className="loader_upload"></div>
                          </div>
                          }

                          {shareVerify && <div className="flex absolute top-[4.3rem] right-7 animate__animated animate__fadeIn">
                            <img src={iconDone} className="w-[30px]" alt="" />
                          </div>
                          }

                        </div>
                      )}
                    </div>
                  ))}

                {!bulkDonwloadDetailsData[0] &&
                  <div className="w-full justify-center flex opacity-60 py-3 tracking-widest">
                    <h3>there are no files uploaded yet<i></i></h3>
                  </div>
                }
              </div>

              <div className={`h-auto w-[2px] ${web_theme ? 'bg-white/10' : 'bg-sidebar-text-lite2/20'} `}>

              </div>

              {/* single file list div */}
              <div className="flex flex-col w-full gap-2 pr-2">
                {/* topic section */}
                <div className={`${web_theme ? "text-white/30" : 'text-sidebar-text-lite2/40'} px-3 py-2`}>
                  single files
                </div>
                {singleFileDetails &&
                  singleFileDetails.map((item, index) => (
                    <div className="flex flex-col h-auto w-full rounded-[25px] ">
                      <div className={`animate__animated animate__fadeInDown animate__faster relative rounded-[25px] z-[0] flex w-full items-center animate__animated animate__fadeIn ${web_theme ? 'bg-dashboard-small-card text-white' : 'bg-sidebar-bg-lite border border-sidebar-text-lite2/30 text-sidebar-text-lite2 '}`}>
                        <div className="p-1 flex-col flex w-full">
                          <div className="flex rounded-[25px] items-center justify-center">
                            <div className="flex items-center justify-center object-cover w-12 h-12 rounded-full">
                              <img src={web_theme ? batchIcon : icon_files_lite} alt="" className="w-[20px]" />
                            </div>

                            <div className="w-full">
                              <div className="ml-3 w-[200px] flex flex-col items-start justify-start w-full">
                                <div className=" text-[10px] flex gap-1">
                                  <h3>
                                    <h3>{fileNames[index] && fileNames[index].length > 50 ? fileNames[index].slice(0, 50) + ' ...' : fileNames[index] || 'Loading...'} </h3>
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="flex flex-row p-3 gap-2 ">
                          {/*end file download button */}
                          <div className="w-[50px] flex items-end justify-end mr-2 gap-1">
                            {/* <h3>bytes</h3> */}
                          </div>
                          <div className="relative">
                            {true ? (
                              <div className="w-full flex gap-2">

                                {/* donwload button for single files  */}
                                {true ? (
                                  <div className="relative">
                                    {/* check the download link is here or not */}
                                    {selectIndex === index && download_status.is_loading_single ? 
                                      <div className="loader_upload"></div>
                                      :
                                      <button onClick={() => downloadFileBulkSingle(index)} className="cursor-pointer flex relative w-full bg-red-500 xs:w-full hover:text-white px-3 py-1 rounded-[25px]"
                                      >
                                        <div className=" button-container w-full">
                                          <span className=" flex items-center justify-center font-bold text-black hover:text-white font-raleway">
                                            Download
                                          </span>
                                        </div>
                                      </button>
                                    }
                                    {/*end of check the download link is here or not */}
                                  </div>
                                ) : (
                                  <div></div>
                                )}
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </div>

                          <button onClick={() => handleMoreButtonClickforSingle(index)} >
                            <img src={web_theme ? threeDots : icon_threedots_lite} alt="" className="w-[20px]" />
                          </button>
                        </div>
                      </div>

                      {selectedRowSingle === index && (
                        <tr className="menu-row relative z-[20000]" ref={divRef}>
                          <div>
                            <div className={`menu ${toggleShare ? 'hidden' : ''}`}>
                              {/* file content */}
                              <div className="relative ">
                                <div className="absolute p-1 right-0 top-[0px] rounded-[15px] bg-gray-600 flex flex-col bg-card-sub-text w-[100px] h-auto items-start justify-center z-50 text-[.8rem]">
                                  <div className="flex flex-row hover:bg-black/20 hover:scale-105 text-white w-full px-3 py-2 rounded-[15px]">
                                    {/* share button div */}
                                    <div className="relative animate__animated animate__fadeInDown animate__faster">
                                      <button onClick={() => shareDid(index)} className="flex gap-1 items-center">
                                        <div>
                                          <img src={icon_share} className="w-[20px]" alt="" />
                                        </div>
                                        <div>
                                          <h3 className="">
                                            Share
                                          </h3>
                                        </div>
                                      </button>
                                    </div>

                                  </div>

                                  <div className="flex flex-row hover:bg-black/20 hover:scale-105 text-white w-full px-3 py-2 rounded-[15px]">
                                    {/* share button div */}
                                    <div className="relative animate__animated animate__fadeInDown animate__fast">
                                      <button onClick={() => Delete_single_file(index)} className="flex gap-1 items-center">
                                        <div>
                                          <img src={icon_delete} className="w-[20px]" alt="" />
                                        </div>
                                        <h3 className="">
                                          Delete
                                        </h3>
                                      </button>
                                    </div>

                                  </div>


                                  {/* reissue button funcs */}
                                  {item.Verified ? (
                                    <button
                                      onClick={() => IssueClaimBulkSingle(index)}
                                      className="flex flex-row gap-x-4 hover:bg-bethel-green/50 w-full p-1"
                                    >
                                      {/* share button div */}
                                      <div className="relative ">
                                        <button>
                                          <h3 className="text-white text-[14px]">
                                            Re-Issue
                                          </h3>
                                        </button>
                                      </div>
                                    </button>
                                  ) : (
                                    <div className="absolute"></div>
                                  )}


                                </div>
                                <div className="w-0 h-0 absolute right-2 top-[-10px] rotate-[180deg] border-l-[10px] border-l-transparent z-0 border-t-[15px] border-t-gray-500 border-r-[10px] border-r-transparent"></div>
                              </div>
                            </div>

                            {isShareSub === index && (
                              <div className="w-full fixed gap-2 bg-card-sub-text p-1 h-auto rounded-lg z-[999]">

                                <div className={`p-2 fixed bottom-0 right-8 lg:w-[500px] md:w-[500px] sm:w-[400px] min-[320px]:w-[300px] h-auto ${web_theme ? 'bg-dashboard-small-card/100' : 'bg-black/70 shadow-md border border-sidebar-text-lite2/20'} rounded-t-[25px] animate-fadeInUpCustom`}>
                                  {/* share file title */}
                                  <div className="flex w-full justify-start  items-center p-1 overflow-hidden px-4 py-4 gap-2">
                                    <img src={icon_share} className="w-[20px]" alt="" />
                                    <h3 className="text-white text-[12px]">Share file : <span className="text-white/50">{fileNames && fileNames[index].length < 40 ? fileNames[index] : fileNames[index].slice(0, 40) + " ..."}</span></h3>
                                  </div>


                                  {/* input divs */}
                                  <div className={`flex w-full py-4 px-4 gap-2 items-center ${web_theme ? 'bg-dashboard-home-bg1' : 'shadow-md border border-sidebar-text-lite2'} rounded-t-[25px]`}>
                                    <input
                                      onChange={(e) => setShareDidValue(e.target.value)}
                                      type="text"
                                      className={`${isSharingClaim || isShareSub ? "w-[320px]" : "w-[380px]"} p-2 border-bethel-green bg-transparent border-b rounded-[25px] placeholder:text-[12px] placeholder:px-4"
                                      `}
                                      placeholder="enter did here"
                                    />
                                    <button disabled={isSharingClaim}
                                      onClick={() => submitShareDidSingle(index)}
                                      className="px-3 py-1 bg-bethel-green rounded-[25px] text-sidebar-text-lite2 font-bold hover:scale-105 border-white text-[.9rem]"
                                    >
                                      {isSharingClaim ? "sharing..." : "Submit "}
                                    </button>


                                  </div>

                                  {/* close div */}
                                  <button onClick={() => closeShareDid(index)} className="flex absolute top-4 right-3">
                                    <img src={iconClose} className="w-[20px]" alt="" />
                                  </button>

                                  {isSharingClaim && <div className="flex absolute top-[85px] right-5 animate__animated animate__fadeIn">
                                    <div className="loader_upload"></div>
                                  </div>
                                  }

                                  {shareVerify && <div className="flex absolute top-[4.3rem] right-7 animate__animated animate__fadeIn">
                                    <img src={iconDone} className="w-[30px]" alt="" />
                                  </div>
                                  }

                                </div>


                              </div>
                            )}
                          </div>

                        </tr>


                      )}
                    </div>
                  ))}

                {!singleFileDetails[0] &&
                  <div className="w-full justify-center flex opacity-60 py-3 tracking-widest">
                    <h3>there are no files uploaded yet<i></i></h3>
                  </div>
                }
              </div>

            </div>

            {/* end of the bulk upload menu */}

          </table>
        </div>
      </div>

      {/* the Middle issue claim QR div */}
      {selectQR === selectIndex ? (
        <div className="z-[10] flex bg-red-400 fixed lg:left-[675px] lg:top-[250px] md:left-[325px] md:top-[250px] sm:left-[175px] sm:top-[250px] min-[320px]:left-[25px] min-[320px]:top-[250px]">
          <button
            onClick={closeQR}
            className="absolute text-white -top-[25px] left-0 z-[100px]"
          >
            <h3 className="z-[100] w-[28px] h-[25px] bg-red-600">x</h3>
          </button>
          <div className="p-4 bg-white absolute z-[1000]">
            <h3 className="flex w-full text-bold justify-center text-[14px]">
              {" "}
              -Issue Claim -
            </h3>

            {/* claim is creating */}
            {QRStatus.is_loading && <div className="flex items-center justify-center w-full relative p-1">
              <h3 className="flex w-full text-bold justify-center text-center text-[14px] text-red-500 animate-pulse font-bold">{QRStatus.msg}</h3>
            </div>}

            <QRCode
              value={JSON.stringify(qrClaim)}
              className="flex w-128 h-128 p-1 bg-white top-0 relative "
            />
          </div>
        </div>
      ) : (
        <div></div>
      )}

      {/* middle DOnwload QR  */}
      {selectedDownload === selectIndex ? (
        <div className="flex fixed lg:left-[675px] lg:top-[220px] md:left-[325px] md:top-[250px] sm:left-[175px] sm:top-[250px] min-[320px]:left-[25px] min-[320px]:top-[250px]">
          <button
            onClick={closeQR}
            className="z-[100] w-[28px] h-[25px] bg-red-600"
          >
            <h3 className="text-white z-[100] w-[28px] h-[25px] bg-red-600">
              x
            </h3>
          </button>
          <div className="p-4 bg-white flex flex-col w-full justify-center items-center">
            <h3 className="flex w-full text-bold justify-center text-[14px]">
              {" "}
              -Download Claim-
            </h3>
            <h3 className="flex w-full text-bold justify-center text-[14px] text-red-600"></h3>

            <QRCode
              value={dowloadQr}
              className="flex w-128 h-128 p-1 bg-white top-0 relative z-[200]"
            />

            {authenticationWait && (
              <div className="flex ">
                <h3>Downloading...</h3>
                <img src={loadingGif} alt="" className="w-[30px]" />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
});

export default TableWithMoreButton; 
