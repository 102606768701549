import React from 'react'
import { useSelector } from 'react-redux'


function DashboardPlanCard({plan, plan_img, plan_details}) {
    const web_theme = useSelector((state) => state.webThemeReducer.web_theme) // change theme
    return (
        <div className={`w-full ${web_theme ? 'bg-dashboard-small-card' : 'shadow-md border border-sidebar-text-lite2/10'} rounded-[25px] p-4 items-center justify-center h-full`}>
            {/* topic section */}
            <div className='w-full opacity-100 text-[1rem]'>
                <h3>{plan} Activated</h3>
            </div>

            {/* content section */}
            <div className='flex justify-center items-center w-full h-full gap-2'>
                {/* image section */}
                <div className='flex w-1/2'>
                    <img src={plan_img} className='w-[1000px] opacity-40' alt="" />
                </div>

                <div className='w-[1px] h-[80%] bg-white opacity-20'>

                </div>

                {/* plan content */}
                <div className={`p-3 flex flex-col gap-1 ${web_theme ? 'text-white/60' : 'text-sidebar-text-lite2'} h-full items-center justify-center w-1/2 text-[.9rem]`}>
                    <p>{plan_details}</p>
                </div>
            </div>
        </div>
    )
}

export default DashboardPlanCard