import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { ethers } from 'ethers'
import iconMedal1 from "../Images/icons/icon-medal1.png"
import iconMedal2 from "../Images/icons/icon-medal2.png"
import iconMedal3 from "../Images/icons/icon-medal3.png"
import iconMedal from "../Images/icons/icon-trophie.png"
import iconMedalNone from "../Images/icons/icon-medalnone.png"
import bethelLogo from "../Images/icons/bethel-logo.png"
import { useQuery } from '@apollo/client'
import iconMedalNoneLite from "../Images/icons/iconMedalNone-lite.png"
import { GET_REF_DETAILS, GET_TOP10_REFS } from '../queris'

function ReferralsComponent() {
    const userDid = useSelector((state) => state.DidReducer)
    const avalibilityData = useSelector((state) => state.requireAvalibilityReducer)
    const inputRef = useRef()
    const [copied, setCopied] = useState(false)
    const web_theme = useSelector((state) => state.webThemeReducer.web_theme)

    const [refCount, setRefCount] = useState(null)

    // referral link
    const [refLink, setRefLink] = useState(null)

    const handleCopy = () => {
        if (inputRef.current) {
            navigator.clipboard.writeText(inputRef.current.defaultValue)
                .then(() => {
                    setCopied(true)

                    setTimeout(() => {
                        setCopied(false)
                    }, 1000);
                })
                .catch(err => {
                    console.error('Failed to copy text: ', err);
                });
        }
    };

    const refLinkfn = async () => {
        try {
            const res = await fetch(process.env.REACT_APP_BACKEND_URL + "get-ref-id", {
                method: "POST",
                body: JSON.stringify({
                    "DID": userDid
                })
            })
            const ref = await res.json()
            setRefLink(`https://mainnet.bethel.network/ref?${ref.RefID}`)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        refLinkfn()
        getRefCount()
        checkRef()
        getPackageDetils()
        get_my_rank()
    }, [])

    // get referral count 
    const getRefCount = async () => {
        try {
            const res = await fetch(process.env.REACT_APP_BACKEND_URL + "get-counts", {
                method: "POST",
                body: JSON.stringify({
                    "DID": userDid
                })
            })
            const ref = await res.json()
            setRefCount(ref)
        } catch (error) {
            console.log(error)
        }
    }

    //check if ref is available
    const [isRef, setIfRef] = useState(false)

    const checkRef = async () => {
        try {
            const res = await fetch(process.env.REACT_APP_BACKEND_URL + `check-ref?did=${userDid}`)
            const ref = await res.json()
            setIfRef(ref.Status)
        } catch (error) {
            console.log(error)
        }
    }

    // get package details 
    const fileStorageJSON2 = require("../json/FileStorage2.json");
    const fileStorageAddress2 = process.env.REACT_APP_BLOCK_CHAIN_CONTRACT_ADDRESS_2
    const fileStorageABI2 = fileStorageJSON2.abi

    const [packageType, setPackageType] = useState(null)
    const [packageDuration, setPackageDuration] = useState(null)

    const getPackageDetils = async () => {
        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum)
            const signer = provider.getSigner()
            const address = await signer.getAddress()
            const contract = new ethers.Contract(fileStorageAddress2, fileStorageABI2, signer)

            const packStatus = await contract.getCurrentPackage(userDid, address)
            setPackageDuration(parseInt(packStatus[2]._hex))

            // set package type
            switch (packStatus[1]) {
                case "Basic":
                    setPackageType("BASIC")
                    break;
                case "Starter":
                    setPackageType("STARTER")
                    break;
                case "Advance":
                    setPackageType("ADVANCE")
                    break;
                default:
                    console.log("")
            }


        } catch (e) {
            console.log(e)
        }
    }

    //get learderboard details
    const [leaderboarder_details, setLeaderboard_details] = useState(null)
    const [my_rank, setMy_rank] = useState(null)
    const rankRef = useRef()

    const [toggle_leader_board, setToggle_leader_board] = useState(false)

    const get_my_rank = async () => {
        try {
            const res = await fetch(process.env.REACT_APP_BACKEND_URL + "get-user-ref-rank", {
                method: "POST",
                body: JSON.stringify({
                    "DID": userDid
                })
            })
            if (res.ok) {
                const data = await res.json()
                console.log(data)
                setMy_rank(data.Rank)
            }
        } catch (error) {
            console.log("error in leaderboard details :", error)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (rankRef.current && !rankRef.current.contains(event.target)) {
            setToggle_leader_board(false);
        }
    };

    //  grapgh (  ql ) ------------------
    const {data: ref_data, loading : ref_loading , error : ref_error} = useQuery(GET_TOP10_REFS)
    const { data : ref_details, loading : details_loading, error: details_error} = useQuery(GET_REF_DETAILS, { variables : { did : userDid } } )

    useEffect(() => {
        if(ref_data){
            setLeaderboard_details(ref_data.getTopReferrals.topReferrals)
            console.log(ref_data.getTopReferrals.topReferrals)
        }
    },[ref_data])

    useEffect(() => {
        if(ref_details){
            console.log(ref_details.getGetReferredDIDS.referrerData)
        }
    },[ref_details])

    
    

    return (
        <div className={`${web_theme ? 'bg-dashboard-home-bg1 text-white' : 'bg-sidebar-bg-lite'} mr-4 px-4 py-4 flex flex-col gap-3 rounded-[25px] ${!avalibilityData.allreadyRegisteredWallet && !avalibilityData.userAdded && !avalibilityData.noUser ? "" : "pointer-events-none grayscale blur-sm"}`}>
            {/* topic container */}
            <div className='flex flex-col mt-2 mb-1 w-full'>
                {/* topic section */}
                <div className='ml-2  text-[.9rem] flex gap-2 justify-between mr-2 mb-2 items-center'>
                    <div className='opacity-50'>
                        <h3>Referrals</h3>
                    </div>

                    {/* ref count div */}
                    <div className={`${web_theme ? "bg-dashboard-small-card" : 'bg-sidebar-text-lite2/30'} flex items-center gap-2 py-1 px-4 rounded-[25px] font-bold `}>
                        <div>My ref count</div>

                        {/* referral count */}
                        <div className={``}>
                            <div className='rounded-full flex items-center justify-center'>
                                <h3 className='text-sidebar-text-lite2 font-bold text-[1.2rem]'>{refCount && (refCount.RefCount ? refCount.RefCount : 0)}</h3>
                            </div>
                        </div>
                    </div>

                </div>

                <hr className={`${web_theme ? "text-white bg-white opacity-30 h-[1px]" : " text-sidebar-text-lite2 opacity-100 h-[2px]"} w-[99%] `} />

            </div>
            {/* end of topic section */}


            <div ref={rankRef} className="flex flex-col gap-2 w-full rounded-[25px] right-2 p-3 z-[200] animate__animated animate__fadeInUp animate__faster h-full mt-2">

                {/* leaderboard */}
                <div className='w-full p-1 flex flex-col gap-2 z-[200] relative'>
                    {/* top section */}
                    <div className="flex gap-2 items-center justify-between tracking-wide p-2">
                        <div className="flex items-center gap-2 ">
                            <div>
                                <img src={bethelLogo} className="w-[20px]" alt="" />
                            </div>
                            <div className="font-bold text-bethel-green text-[1rem]">
                                <h3>BETHEL LEADERBOARD</h3>
                            </div>
                        </div>


                        <div className={`${web_theme ? 'text-white/50' : 'text-sidebar-text-lite2'} font-bold text-[10px] flex gap-2 items-center`}>
                            <h3 className='text-[14px]'>My Rank :</h3>
                            <h3 className={`${web_theme ? 'border-bethel-green text-bethel-green' : 'border-black/50 text-sidebar-text-lite2'} relative   z-10 font-bold rounded-full w-[35px] h-[35px] justify-center items-center flex text-[12px] border `}>{my_rank && my_rank}</h3>

                        </div>
                    </div>
                    {/* end of top section */}

                    <hr className="h-[1px] opacity-20 w-full" />

                    <div className="flex w-full flex-col gap-2">
                        {leaderboarder_details && leaderboarder_details.slice(0, 3).map((ref, index) => {
                            return <div className="flex flex-col">
                                <div className="flex items-center px-2 py-1 gap-2">

                                    <div className="flex items-center w-full gap-2 ">
                                        {/* tropie image */}
                                        <div className={`flex`}>
                                            {index === 0 && <img src={iconMedal1} className="w-[45px] animate-pulse" alt="" />}
                                            {index === 1 && <img src={iconMedal2} className="w-[45px] opacity-80" alt="" />}
                                            {index === 2 && <img src={iconMedal3} className="w-[45px] opacity-60" alt="" />}
                                        </div>

                                        {/* refs */}
                                        {index === 0 && (
                                            <div className={`${web_theme ? 'text-white/70' : 'text-black'} relative w-full p-1 overflow-hidden rounded-l-lg flex justify-between animate-pulse items-center`}>
                                                <div className="bg-gradient-to-r from-green-600/100 via-green-400/100 to-transparent absolute inset-0 animate-fade-green"></div>
                                                <h3 className="relative text-[14px] z-10">{ref.referralID}</h3>
                                                <h3 className={`${web_theme ? 'border-white/50' : 'border-black/50'} relative z-10 font-bold rounded-full w-[35px] h-[35px] justify-center items-center flex text-[14px] border `}>{ref.totalPoint}</h3>
                                            </div>
                                        )}

                                        {index === 1 && (
                                            <div className={` ${web_theme ? 'text-white/70' : 'text-black'} relative w-full p-1 overflow-hidden rounded-l-lg flex justify-between items-center`}>
                                                <div className="bg-gradient-to-r from-yellow-600/90 via-yellow-400/90 to-transparent absolute inset-0 animate-fade-green"></div>
                                                <h3 className="relative text-[14px] z-10">{ref.referralID}</h3>
                                                <h3 className={`${web_theme ? 'border-white/50' : 'border-black/50'} relative z-10 font-bold rounded-full w-[35px] h-[35px] justify-center items-center flex text-[14px] border border-white/50`}>{ref.totalPoint}</h3>
                                            </div>
                                        )}

                                        {index === 2 && (
                                            <div className={`${web_theme ? 'text-white/70' : 'text-black'} relative w-full p-1 overflow-hidden rounded-l-lg flex justify-between items-center`}>
                                                <div className="bg-gradient-to-r from-orange-600/90 via-orange-400/90 to-transparent absolute inset-0 animate-fade-green"></div>
                                                <h3 className="relative text-[14px] z-10">{ref.referralID}</h3>
                                                <h3 className={`${web_theme ? 'border-white/50' : 'border-black/50'} relative z-10 font-bold rounded-full w-[35px] h-[35px] justify-center items-center flex text-[14px] border border-white/50`}>{ref.totalPoint}</h3>

                                            </div>
                                        )}
                                    </div>

                                </div>

                            </div>
                        })}

                        {leaderboarder_details && leaderboarder_details.slice(3, 10).map((ref, index) => {
                            return <div className="flex flex-col">
                                <div className="flex items-center px-2 py-1 gap-2">

                                    <div className="flex items-center w-full gap-2 ">
                                        {/* tropie image */}
                                        <div className={`flex`}>
                                            <img src={web_theme ? iconMedalNone : iconMedalNoneLite} className="w-[45px]" alt="" />
                                        </div>

                                        <div className={`${web_theme ? 'text-white/70' : 'text-black'} relative w-full p-1 overflow-hidden rounded-l-lg flex justify-between items-center`}>
                                            <div className="bg-gradient-to-r from-white/10 via-white/10 to-transparent absolute inset-0 animate-fade-green"></div>
                                            <h3 className="relative text-[14px] z-10">{ref.referralID}</h3>
                                            <h3 className={`relative ${web_theme ? 'border-white/50' : 'border-black/50'} z-10 font-bold rounded-full w-[35px] h-[35px] justify-center items-center flex text-[10px] border`}>{ref.totalPoint}</h3>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        })}
                    </div>
                </div>

            </div>
        </div>
    )
}

export default ReferralsComponent