import { createAction, createSlice } from "@reduxjs/toolkit";

export const revertUpload = createAction('REVERT_ALL')

const initialState = {
  is_uploading : false,
  is_complete : false,
  is_fail : false,
  msg : ""
}

 const uploadStatusSlice = createSlice({
    name : "upload_status",
    initialState,
    extraReducers: (builder) => builder.addCase(revertUpload, () => initialState),
    reducers: {
    updateUpload: (state, action) => action.payload,
  }
})

export default uploadStatusSlice;