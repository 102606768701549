import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toggleSidebarSlice from "../reducers/toggleSidebar";
import { NavLink, useNavigate } from "react-router-dom";
import walletIcon from "../Images/icons/icon-wallet.png"
import bethelLogo from "../Images/icons/bethel-logo.png"
import arrowUp from "../Images/icons/icons-arrow-up.png"
import arrowDown from "../Images/icons/icons8-down-100.png"
import copyIcon from "../Images/icons/icon-copy.png"
import robot from "../Images/BG-Images/robot.png"
import metaMaskLogo from "../Images/icons/metamask.png"
import becxSlice from "../reducers/becxReduser.js";
import MetamaskInstall from "./Cards/MetamaskInstall.jsx";
import requireAvalibilitySlice from "../reducers/RequireAvalibilySlice.js";
import iconDone from "../Images/icons/icon-newdone.png"
import iconfail from "../Images/icons/icon-fail.png"
import planStatusSlice from "../reducers/PlanStatusSlice.js";
import uploadGif from "../Images/Animation-gifs/upload.gif"
import uploadStatusSlice from "../reducers/UploadStatusSlice.js";
import uploadTriggerSlice from "../reducers/uploadTriggerSlice.js";
import iconClose from "../Images/icons/icons-close.png"
import MetamaskInstalationGuide from "./Cards/MetamaskInstalationGuide.jsx";
import filePointsSlice from "../reducers/filePointsReducer.js";
import web_theme_slice from "../reducers/webTheme.js";
import icon_bell from "../Images/icons/icon-bell.png"
import { useMutation, useQuery } from "@apollo/client";
import { check_active_plan, check_plan_expire, GET_BECX_PRICE, GET_TOTAL_POINTS, is_user_added } from "../queris.js";
import uploading_files_slice from "../reducers/UploadingFileSlice.js";
import { ADD_FILE_POINTS, upload_files } from "../mutations.js";
import upload_button_click from "../reducers/uploadButtonClick.js";
import icon_moon from "../Images/icons/icon-moon.png"
import icon_sun from "../Images/icons/icon-sun.png"



function Navbar() {
  const dispatch = useDispatch();
  const Navigate = useNavigate();


  // get user did 
  const userDid = useSelector((state) => state.DidReducer)
  const avalibilityData = useSelector((state) => state.requireAvalibilityReducer)
  const planExpired = useSelector((state) => state.planStatusReducer)

  // for the add user fucntions
  const [account, updateAccount] = useState(null);
  const [polygonMainnnet, setPolygonMainnet] = useState(false)
  const [validate_user, setValidate_user] = useState(false) // check user added or not 
  const [total_points, setTotal_points] = useState(0)

  useEffect(() => {
    checkUserDidAvailable()
  }, []);


  useEffect(() => {
    dispatch((dispatch, getState) => {
      dispatch(filePointsSlice.actions.savePoints({
        points: 0,
        is_added: false,
      }))
    })

    dispatch(uploading_files_slice.actions.clear_uploading()) // clear uploading data when refresh
  }, [])

  // get data from store 
  const pointData = useSelector((state) => state.filePointReducer)
  const uploading_files_data = useSelector((state) => state.uploadingFileReducer.uploading_files)
  const upload_status = useSelector((state) => state.uploadStatusReducer)

  // check did validation if not route to login page
  const checkUserDidAvailable = () => {
    userDid && userDid ? console.log("") : Navigate("/")
  }


  // for bethel Beckz wallet 
  const [sendSec, setSendSec] = useState(true)
  const [reciveSec, setReciveSec] = useState(false)
  const [copiedStyle, setCopiedStyle] = useState("")
  const [showWallet, setShowWallet] = useState(false)

  // active send sec
  const activeSendSec = () => {
    setReciveSec(false)
    setSendSec(true)
  }

  // active revice Sec 
  const activeRecive = () => {
    setReciveSec(true)
    setSendSec(false)
  }

  // copy wallet address
  const copyWalletAddress = () => {
    navigator.clipboard.writeText(account.address)
    setCopiedStyle('bg-green-500')

    setTimeout(() => {
      setCopiedStyle('')
    }, 500);
  }

  // show and hide wallet
  const openWallet = () => {
    setShowWallet(!showWallet)
  }

  // becx price states 
  const [becx, setBecx] = useState(null)

  const becxState = useSelector((state) => state.becxSliceReducer) //get becx price from state

  // get did connected wallet address 
  const get_connected_wallet_addresses = async () => {
    try {
      if (!validate_user) {
        dispatch(requireAvalibilitySlice.actions.saveAvailibility({ metamask: false, userAdded: false, noUser: true, allreadyRegisteredWallet: false }))
        return
      }

      // if (isUserHere === true) {
      //   dispatch(requireAvalibilitySlice.actions.saveAvailibility({ metamask: false, userAdded: true, noUser: false, allreadyRegisteredWallet: false }))
      //   return
      // }
      // const ress = await contract.getUserDid()

      dispatch(requireAvalibilitySlice.actions.saveAvailibility({ metamask: false, userAdded: false, noUser: false, allreadyRegisteredWallet: false }))
    } catch (error) {
      console.log(error)
    }

  }

  // upload status fns 
  const uploadStatus = useSelector((state) => state.uploadStatusReducer)

  // state upload complete trigger
  useEffect(() => {
    if (uploadStatus.isCompletet) {
      dispatch(uploadTriggerSlice.actions.saveTriger({ trigger: true }))
    }
  }, [uploadStatus, dispatch])

  // show metamask instruction guide
  const [toggleMetaMaskGuide, setToggleMetaMaskGuide] = useState(false)

  
  // toggle side bar 
  const sidebar = useSelector((state) => state.toggleSidebarReducer)
  const web_theme = useSelector((state) => state.webThemeReducer)


  // ----------------------------------------------------------------------------- graph QL fn for queries  -----------------------------------------------------------------------------

  const { data: data_add_user, loading: loading_add_user, error: error_add_user } = useQuery(is_user_added, {
    variables: { did: userDid }, fetchPolicy : 'no-cache' 
  });

  const { data: data_BECX, loading: loading_BECX, error: error_BECX } = useQuery(GET_BECX_PRICE);

  const { data: expire_data, loading: expire_loading, error: expire_error } = useQuery(check_plan_expire, {
    variables: {
      "input": {
        "did": userDid
      }
    },
    fetchPolicy : 'no-cache' 
  });

  const { data: active_plan_data, loading: active_plan_loading, error: active_plan_error } = useQuery(check_active_plan, {
    variables: { input: { did: userDid } },
  });

  const [add_file_points] = useMutation(ADD_FILE_POINTS)

  const { data: total_points_data , loading: total_points_loading, error: total_points_error} = useQuery(GET_TOTAL_POINTS, { variables : {did : userDid}})

  // add user in version 2
  const isUserRegisteredV2 = async () => {
    setValidate_user(true)
    if (data_add_user){
      dispatch(planStatusSlice.actions.saveStatus({
        planActive: data_add_user.isUserExistsOnChain.isRegistered,
        planExpire: planExpired.planExpire,
        planSpace: null
      }))
    }
    if (loading_add_user) {
      console.log("loading is user added")
    }
    if (error_add_user) {
      console.log("error apolo:", error_add_user.message)
      setValidate_user(false)
    }
  }

  // check if plan expire or not
  const check_plan_expire_gql = () => {
    if (expire_data) {
      console.log("check plan expire data :", expire_data.checkPackageExpire.Ok)
      dispatch(planStatusSlice.actions.saveStatus({
        planActive: false,
        planExpire: expire_data.checkPackageExpire.Ok,
        planSpace: null
      }))
    }
    if (expire_loading) {
      console.log("loading plan expire loading  :")
    }
    if (expire_error) {
      console.log("error is plan expire :", expire_error.message)
      dispatch(planStatusSlice.actions.saveStatus({
        planActive: planExpired.planActive,
        planExpire: planExpired.planExpire,
        planSpace: null
      }))
    }
  }

  // check what plans active
  const check_active_plan_gql = () => {
    if (active_plan_data) {
      // setValidate_user(data_add_user.isUserAddedOnChain.isRegistered)
      console.log("check active plan status :", active_plan_data.getCurrentPackage)
      // delete this after node connected
    }
    if (active_plan_loading) {
      console.log("loading is active plan  loading :")
    }
    if (active_plan_error) {
      console.log("error is active plan :", active_plan_error.message)
    }
  }

  const file_counts = useSelector((state) => state.current_file_countReducer.current_file_count)

  // add file points
  const add_file_points_gql = async () => {
    try {
      const res = await add_file_points({variables : { "did": userDid, "fileCount": file_counts }})

      if(res.data){
        console.log("file point added!")
      }
    } catch (error) {
      console.log("file points error :", error)
    }
  }

  useEffect(() => {
    isUserRegisteredV2()
    console.log(planExpired)
    get_connected_wallet_addresses()
  }, [data_add_user, loading_add_user, error_add_user])

  useEffect(() => {
    check_plan_expire_gql()
  }, [expire_data, expire_loading, expire_error])

  useEffect(() => {
    check_active_plan_gql()
  }, [active_plan_data, active_plan_loading, active_plan_error])

  useEffect(() => {
    if (data_BECX) {
      console.log(data_BECX.getBecxPrice.price)
      dispatch(becxSlice.actions.saveBECXPrice({ price: parseFloat(data_BECX.getBecxPrice.price) }))
      setBecx(parseFloat(data_BECX.getBecxPrice.price));
    }
  }, [data_BECX, loading_BECX, error_BECX, dispatch])

  useEffect(() => {
    if (total_points_data) {
      setTotal_points(total_points_data.getTotalPoints.totalPoints)
    }
  }, [total_points_data, total_points_loading, total_points_error])


  // ----------------------------------------------------------------------------- graph QL fn for mutations  -----------------------------------------------------------------------------

  const triggerFn = useSelector((state) => state.uploadButtonClickReducer.triggerFn);

  const [uploadFiles, { data: uploading_data, loading: uploading_loading, error: uploading_error }] = useMutation(upload_files);

  // graph ql file upload
  const file_upload_v2 = async () => {
    uploadFiles({
      variables: {
        files: uploading_files_data,
        did: userDid,
      },
    });

  }

  useEffect(() => {
    if (triggerFn) {
      file_upload_v2();
      dispatch(upload_button_click.actions.toggleTriggerFnReverse())
    }
  }, [triggerFn]);

  useEffect(() => {
    if (uploading_data) {

      add_file_points_gql() // add file points 

      setTimeout(() => {
        dispatch(uploading_files_slice.actions.save_uploading_files({ uploading_files: null }))
      }, 3000);

      dispatch(uploadStatusSlice.actions.updateUpload({ is_uploading: false, is_complete: true, is_fail: false, msg: "upload success" })) // dispatch ongoing upload status to store

      setTimeout(() => {
        dispatch(uploadStatusSlice.actions.updateUpload({ is_uploading: false, is_complete: false, is_fail: false, msg: "" })) // dispatch ongoing upload status to store
      }, 3000);

    }
    if (uploading_loading) {
      console.log("uploading please wait")
      dispatch(uploadStatusSlice.actions.updateUpload({ is_uploading: true, is_complete: false, is_fail: false, msg: "uploading" })) // dispatch ongoing upload status to store
    }
    if (uploading_error) {
      console.log("uploading error --- > > ", uploading_error.message)

      setTimeout(() => {
        dispatch(uploading_files_slice.actions.save_uploading_files({ uploading_files: null }))
      }, 3000);

      dispatch(uploadStatusSlice.actions.updateUpload({ is_uploading: false, is_complete: false, is_fail: true, msg: "upload fail" })) // dispatch ongoing upload status to store

      setTimeout(() => {
        dispatch(uploadStatusSlice.actions.updateUpload({ is_uploading: false, is_complete: false, is_fail: false, msg: "" })) // dispatch ongoing upload status to store
      }, 3000);
    }
  }, [uploading_data, uploading_loading, uploading_error])

  return (
    <section className={`${sidebar.toggleSidebar ? "pl-[170px]" : "pl-[100px]"} flex justify-center w-full`}>
      <div className={`fixed p-2 top-0 justify-between rounded-b-[40px] flex ${sidebar.toggleSidebar ? 'lg:pl-[20px] w-[82%]' : 'lg:pl-[20px] w-[90%]'} md:px-2 sm:px-2 min-[320px]:px-2 px-4 z-100 ${web_theme.web_theme ? 'bg-black' : 'bg-sidebar-bg-lite'}`}>

        {/* start-main nav bar */}
        <div className=" flex w-full items-center " >

          {/* left side */}

          {/* right side */}
          <div className="flex gap-2 text-[1rem] items-center justify-end w-full relative">

            <div className="navbar-right flex gap-2">
              {/* user bethel wallet */}
              <div className="relative lg:mr-[170px] md:mr-[190px] sm:mr-[190px] min-[320px]:mr-[140px] hidden min-[320px]:scale-[0.8] lg:scale-100 md:scale-100 flex items-center justify-center bg-[#0F0F0F]">
                <button onClick={openWallet}
                  className={`p-2 border-[2px] ${showWallet ? "bg-bethel-green" : ''} border-white rounded-full`}>
                  <img src={walletIcon} alt="" className="w-[25px]" />
                </button>


                {/* wallet div */}
                {showWallet &&
                  <div className={`animate__animated animate__faster ${showWallet ? "animate__fadeInRight" : "animate__fadeOutRight"}
                   absolute top-14 
                   lg:right-0 md:right-0 sm:right-0 min-[320px]:right-[-40px] w-[320px] 
                   h-[500px] bg-black bg-inherit shadow-lg shadow-bethel-green/10 border-bethel-green border-[1px] z-[9000] rounded-lg p-3`}>
                    {/* bethel logo and discription div */}
                    <div className="flex justify-start gap-2 p-2 bg-white/20 rounded-xl w-[200px]">
                      <img src={bethelLogo} alt="" className="flex items-center justify-start w-[20px]" />
                      <h1 className="font-normal text-[14px]">Bethel Wallet</h1>
                    </div>

                    {/* wallet address */}
                    <div className="px-2 py-2 flex text-white/40 items-center">
                      <h3 className="text-[10px]">Wallet Address : </h3>
                      <h3 className="text-[10px] ml-2">
                        {account &&
                          account.address.slice(0, 18) +
                          "..." + account.address.slice(35)}
                      </h3>
                      <button onClick={copyWalletAddress} className={`relative p-1 rounded-full ${copiedStyle ? "bg-green-500" : ""} flex items-center justify-center ml-2`}>
                        <img src={copyIcon} alt="" className="w-[15px] opacity-40" />
                      </button>
                    </div>

                    {/* balance and send tracsaction */}
                    <div className="flex flex-col p-4 rounded-lg mt-1 gap-3 bg-gradient-to-r from-bethel-green/50 to-bethel-newgreen/50">
                      <h3 className="font-normal text-[12px] text-white/60 tracking-wide">Total Balance</h3>
                      <h1 className="text-[30px] text-white font-bold">{account ?
                        ((account.balance * 10).toFixed(2) + " MATIC") : "0 MATIC"
                      }
                        <span className="flex text-[15px]">{account ?
                          "($" + ((account.balance * 10).toFixed(2)) * 0.55 + ")" : "$0"}
                        </span></h1>

                    </div>

                    {/* send and recive icon list */}
                    <div className="w-full flex gap-2 justify-start mt-3">
                      {/* send icon */}
                      <button onClick={activeSendSec}
                        className={`flex flex-col items-center justify-center ${sendSec ? "bg-white/20" : ''} px-2 py-1  rounded-t-lg`}>
                        <h3 className="text-[13px]">Send</h3>
                      </button>



                      {/* recive icon */}
                      <button onClick={activeRecive}
                        className={`flex flex-col items-center justify-center ${reciveSec ? "bg-white/20" : ''} px-2 py-1  rounded-t-lg`}>
                        <h3 className="text-[13px]">Recive</h3>
                      </button>
                    </div>

                    {/* input fields for send tokens */}
                    {sendSec && <div className="flex flex-col pt-2 pb-4 px-2 mt-0 bg-white/20 rounded-r-lg rounded-bl-lg">
                      <form action="">
                        {/* send address */}
                        <label htmlFor="" className="text-white/50 text-[12px] ">
                          Send Tokens:
                        </label>

                        <div className="flex relative">
                          <input type="text" className="flex border-[1px] text-[12px] border-white/20 bg-transparent w-[85%] rounded-lg mt-2 p-2 outline-none" placeholder="Enter Wallet Address" />

                          <button className="absolute right-1 p-2 rounded-full border-[1px] flex flex-col top-3">
                            <img src={arrowUp} alt="" className="w-[10px]" />
                          </button>
                        </div>

                      </form>
                    </div>}

                    {/* input fields for Recive tokens */}
                    {reciveSec && <div className="flex flex-col pt-2 pb-4 px-2 mt-0 bg-white/20 rounded-lg">
                      <form action="">
                        {/* send address */}
                        <label htmlFor="" className="text-white/50 text-[12px]">
                          Recive Tokens:
                        </label>

                        <div className="flex relative">
                          <input type="text" className="flex border-[1px] text-[12px] border-white/20 bg-transparent w-[85%] rounded-lg mt-2 p-2 outline-none" placeholder="Enter Wallet Address" />
                          <button className="absolute right-1 p-2 rounded-full border-[1px] flex flex-col top-3">
                            <img src={arrowDown} alt="" className="w-[10px]" />
                          </button>
                        </div>
                      </form>
                    </div>}


                  </div>
                }
                {/* end of wallet div */}

              </div>
              {/* end of user bethel wallet */}



            </div>

            {/* right side wallet and notification bar */}
            <div className={`flex scale-95 items-center w-[250px] rounded-[30px] ${web_theme.web_theme ? 'bg-transparent text-white' : " text-sidebar-text-lite2"}`}>

              {/* becx price div */}
              <div className="flex w-7/12 flex-col px-5 justify-center items-center">

                <div className="w-full text-[12px] font-normal opacity-40 text-right">
                  <h3>BECX price</h3>
                </div>

                <div className="w-full text-[18px] tracking-widest font-bold opacity-75 text-right">
                  <h3> $ {becx ? becx.toFixed(2) : "Calculating"}</h3>
                </div>
              </div>

              {/* notification div */}
              <div className={`w-5/12 px-2 bg-bethel-green/30 rounded-[40px] justify-end  p-1 flex gap-1`}>
                {/* shift dark mode and light mode  icon */}
                <button onClick={() => dispatch(web_theme_slice.actions.change_theme({ web_theme: !web_theme.web_theme }))} className={`w-[50px] h-10 rounded-full flex justify-center items-center ${web_theme.web_theme ? 'bg-bethel-green/30' : 'bg-sidebar-text-lite2/30'} `}>
                  { web_theme.web_theme ? <img src={icon_sun} className="w-[20px] animate__animated animate__zoomIn" alt="" /> : <img src={icon_moon} className="w-[20px] animate__animated animate__zoomIn" alt="" /> }
                </button>

                {/* notification icon */}
                <div className={`w-[50px] h-10 rounded-full flex flex-col gap-0 justify-center items-center font-bold ${web_theme.web_theme ? 'bg-bethel-green/30' : 'bg-sidebar-text-lite2/30'}`}>
                  <div>{total_points}</div>
                  <div className={`text-[0.5rem] mt-[-5px]`}>pts</div>
                </div>
              </div>

            </div>
          </div>
        </div>
        {/* end-nav bar */}
      </div>

      {/* upload status div */}
      {uploadStatus.isUploading &&
        <div className="bg-black w-[300px] p-5 fixed bottom-4 right-3 justify-center items-center flex border-[1px] border-bethel-green rounded-lg animate-pulse">
          <div className="flex items-center text-white relative">
            <h3 className="flex text-white absolute -top-11 -left-10 font-bold text-[12px] ">{uploadStatus.msg}</h3>
            <img src={uploadGif} className="w-[60px] absolute -left-[55px]" alt="" />

            <div className="flex flex-col gap-1">
              <div class="w-[200px] bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                <div className={`bg-green-500 h-2.5 rounded-full ${uploadStatus.barWidth} transition-all ease-out duration-1000 animate__animated animate__pulse animate__infinite	infinite animate__slow`}></div>
              </div>
            </div>

          </div>
        </div>}

      {/* upload complete div */}
      {uploadStatus.isCompletet &&
        <div className="bg-black w-[300px] p-4 fixed bottom-4 right-3 justify-center items-center flex border-[1px] border-bethel-green rounded-lg ">
          <div className="flex items-center text-white relative">
            <img src={iconDone} className="w-[25px] absolute -left-[35px] animate__animared animate__bounceIn" alt="" />

            <div className="flex flex-col gap-1 text-green-600 font-bold text-[15px]">
              Upload Success!
            </div>

          </div>
        </div>}

      {uploadStatus.isFail &&
        <div className="bg-black w-[300px] p-4 fixed bottom-4 right-3 justify-center items-center flex border-[1px] border-red-500 rounded-lg ">
          <div className="flex items-center text-white relative">
            <img src={iconClose} className="w-[25px] absolute -left-[35px] animate__animared animate__bounceIn" alt="" />

            <div className="flex flex-col gap-1 text-red-600 font-bold text-[15px]">
              Upload Fail!
            </div>

          </div>
        </div>}


      {/* meta mask install msg */}
      {polygonMainnnet &&
        <div className='w-full'>
          <MetamaskInstall installMetaMask={"AddMainnet"} header={"Invalid Network Detected!"} img={metaMaskLogo} li1={"System deceted that you are not in Polygon Main net currently"} li2={"Please change it to POLYGON MAIN NET or click ADD to add Mainnet into your Metamask wallet "} />

          {/* div ai robot div */}
          <div className='fixed -right-14 top-[270px] -rotate-12 rounded-full p-5 animate__animated animate__fadeInRight animate__fast'>
            <img src={robot} className='w-[150px] opacity-80 ' alt="" />
          </div>

        </div>}

      {/* meta mask installtion guide */}
      {avalibilityData.metamask && toggleMetaMaskGuide && <MetamaskInstalationGuide colseBtn={() => setToggleMetaMaskGuide(false)} />}

      {/* guide div */}
      {!toggleMetaMaskGuide && avalibilityData.metamask && <button onClick={() => setToggleMetaMaskGuide(true)} className="fixed bg-black border border-white/30 bottom-0 right-0 flex justify-center items-center gap-2 p-2 rounded-lg animate__animated animate__bounce animate__infinite	infinite animate__slower">
        <img src={metaMaskLogo} className="w-[20px]" alt="" />
        <h3 className="text-orange-500 text-[12px]">Meta Mask Installation Guide</h3>
      </button>
      }

      {/* file point added div */}
      {pointData.is_added && <div className="fixed bottom-3 right-2 bg-green-700  py-2 px-4 text-white font-bold text-[0.8rem] tracking-wider animate__animated aniamte__fadeIn">
        <h3>You've earned <span className="text-bethel-newgreen">{pointData && pointData.points} </span>points</h3>
      </div>}

      {/* file uploading section */}
      {(upload_status.is_uploading || upload_status.is_complete || upload_status.is_fail) &&
        <div className={`fixed flex w-[400px] h-auto rounded-t-[25px] bottom-0 right-2 p-2 flex-col animate__animated animate__fadeInUp
        ${web_theme.web_theme ? 'bg-black text-white' : 'bg-sidebar-bg-lite shadow-md text-sidebar-text-lite2 border border-sidebar-text-lite2/20'}
        `}>
          {/* title section div */}
          <div className="w-full py-2 px-4 text-[.8rem] flex justify-between">
            <div className="font-normal tracking-wide animate-pulse">
              {upload_status.is_uploading && <h3>{upload_status.msg}</h3>}
              {upload_status.is_complete && <h3 className="text-bethel-green">{upload_status.msg}</h3>}
              {upload_status.is_fail && <h3 className="text-red-600">{upload_status.msg}</h3>}
            </div>

            {/* loading div */}
            {upload_status.is_uploading && <div className="loader_upload">
            </div>}

            {upload_status.is_complete && <div className="opacity-100 animate-pulse">
              <img src={iconDone} className="w-[25px]" alt="" />
            </div>}

            {upload_status.is_fail && <div>
              <img src={iconfail} className="w-[20px]" alt="" />
            </div>}

          </div>

          {/* files loop */}
          <div className={`flex text-[.8rem] gap-2 flex-col p-2 ${web_theme.web_theme ? 'bg-white/10' : 'bg-black/10'} rounded-t-[25px] h-auto overflow-scroll`} >
            {uploading_files_data && uploading_files_data.map((file) => {
              return (
                <div className="w-full flex py-1 px-2 opacity-60 justify-between">
                  <h2>{file.name && file.name.length > 20 ? file.name.slice(0, 20) + ' ...' : file.name}</h2>
                  <h2>{file.size && ((file.size).toString().length > 5) ? (file.size / 1000000).toFixed(2) + " MiB" : (file.size / 1000).toFixed(2) + " KiB"}</h2>
                </div>
              )
            })}
          </div>

        </div>
      }


    </section>
  );
}

export default Navbar;
