import { createAction, createSlice } from "@reduxjs/toolkit";

export const revertAll4 = createAction('REVERT_ALL')

const initialState = {
    current_file_count : null
}

 const current_file_count = createSlice({
    name : "Verifier_did",
    initialState,
    reducers: {
    save_current_file_count: (state, action) => action.payload,
  }
})

export default current_file_count;