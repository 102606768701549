import React, { useState } from 'react'
import { NavLink, Link, useNavigate } from 'react-router-dom'
import iconHome from '../Images/icons/icon-home.png'
import iconHome1 from '../Images/icons/icon-home1.png'
import iconHomeLite from '../Images/icons/icon-home-lite.png'
import iconDolar from "../Images/icons/icons8-dolar-64.png"
import iconHelp from "../Images/icons/icon-help.png"
import iconUser from "../Images/icons/icon-male-user.png"
import iconWallet from "../Images/icons/icon-wallet.png"
import iconStorage from "../Images/icons/icon-storage.png"
import iconBethel from "../Images/icons/bethelIcon.png"
import iconLogoout from "../Images/icons/icon-logout.png"
import { useDispatch, useSelector } from 'react-redux'
import { revertAll } from '../reducers/Loginreducer'
import { revertAll2 } from '../reducers/storageDetailsSlice'
import { revertAll3 } from '../reducers/uploadDetailsSlice'
import { revertAll4 } from '../reducers/userDataReducer'
import toggleSidebarSlice from '../reducers/toggleSidebar'
import iconClose from '../Images/icons/icons8-close-96.png'
import fileIcon from "../Images/icons/icon-file.png"
import { revertAll8 } from '../reducers/didRedcuer'
import { revertTransactionHash } from '../reducers/TransactionHashReducer'
import FileCompBlurSlice from '../reducers/filesCompBlurReducer'
import { revertBecx } from '../reducers/becxReduser'
import iconReferral from "../Images/icons/icon-referrals.png"

// import icons
import icon_folder_dark from "../Images/icons/icon-folder-dark.png"
import icon_folder_lite from "../Images/icons/icon-folder-lite.png"
import icon_user_lite from "../Images/icons/icon-user-lite.png"
import icon_user_dark from "../Images/icons/icon-user-dark.png"
import icon_plan_dark from "../Images/icons/icon-plan-dark.png"
import icon_plan_lite from "../Images/icons/icon-plan-lite.png"
import icon_info_lite from "../Images/icons/icon-info-lite.png"
import icon_info_dark from "../Images/icons/icon-info-dark.png"
import icon_power from "../Images/icons/icon-power.png"
import icon_double_arr from "../Images/icons/double-arr.png"
import icon_ref_dark from "../Images/icons/icon-ref-dark.png"
import icon_ref_lite from "../Images/icons/icon-ref-light.png"


function SideBar() {
  const [toggleFile, setToggleFile] = useState(false)
  const dispatch = useDispatch();
  const Navigate = useNavigate();

  const avalibilityData = useSelector((state) => state.requireAvalibilityReducer)
  const web_theme = useSelector((state) => state.webThemeReducer)

  const toggle = () => {
    // dispatch(toggleSidebarSlice.actions.toggleSidebar())
    // dispatch(FileCompBlurSlice.actions.changeBlur(null))
  }

  const [toggle_sidebar, setToggle_sideBar] = useState(true)

  // toggle side bar
  const toggle_sidebar_fn = () => {
    setToggle_sideBar(!toggle_sidebar) // local toggle
    dispatch(toggleSidebarSlice.actions.toggleSidebar({ toggleSidebar: toggle_sidebar })) // toggle sidebar globally
  }

  const logOut = () => {
    Navigate('/')
    dispatch(revertAll(), revertAll4(), revertAll2(), revertAll3(), revertAll8(), revertTransactionHash(), revertBecx())
  }

  const toggleSidebar = useSelector((state) => state.toggleSidebarReducer)

  return (
    <div>
      {/* side bar new version 2 */}
      <div className={`fixed ${toggle_sidebar ? 'w-[100px]' : 'w-[200px]'} left-0 top-4 bottom-4 rounded-r-[40px] ${web_theme.web_theme ? 'bg-sidebar-bg-dark' : 'bg-sidebar-bg-lite'} flex-col shadow-sm`}>

        {/* bethel logo section */}
        <div className='w-full flex py-4 justify-center items-center flex-col gap-2 relative'>
          <img src={iconBethel} className='w-[40px]' alt="" />

          <div className='flex justify-center items-center relaitve relative'>
            <h3 className={`${web_theme.web_theme ? 'text-bethel-green' : 'text-bethel-green'} font-semibold tracking-wide`}>
              {toggle_sidebar ? <h3 className={`${web_theme.web_theme ? 'text-bethel-green' : 'text-bethel-green'}`}>BETHEL</h3> : <h3 className={`${web_theme.web_theme ? 'text-sidebar-text-dark' : 'text-sidebar-text-lite2'}`}><span className={`${toggle_sidebar ? '' : 'text-bethel-green'}`}>BETHEL</span>  <span className={`${web_theme.web_theme ? 'text-sidebar-text-dark' : 'text-sidebar-text-lite2'}`}>zkpStorage</span></h3>}
            </h3>
          </div>

          {/* TOGGLE ICONS */}
          <button onClick={toggle_sidebar_fn} className='p-2 flex rounded-full w-full justify-center absolute bottom-[-10px]'>
            <img src={icon_double_arr} className={`w-[15px] opacity-70 ${toggle_sidebar ? 'rotate-180' : ''}`} alt="" />
          </button>
        </div>

        {/* side bar icons content */}
        <div className='w-full h-full flex flex-col gap-[10px] py-[10px] items-center justify-between relative'>

          {/* top container */}
          <div className='w-full flex flex-col gap-[10px] px-4'>
            {/* line */}
            <div className='w-full flex justify-center'>
              <hr className={`${web_theme.web_theme ? 'border-sidebar-bg-lite' : 'border-sidebar-bg-dark'} w-full h-[5px] opacity-20`} />
            </div>

            {/* home */}
            <NavLink onClick={() => dispatch(FileCompBlurSlice.actions.changeBlur(""))} to="/dashboard" end className={({ isActive }) =>
              `flex ${toggle_sidebar ? 'justify-center' : 'justify-start'} items-center p-3 rounded-2xl gap-2 hover:bg-bethel-green/60 
              ${isActive ? "bg-bethel-green/60" : ""}`
            }>
              <div>
                <img src={web_theme.web_theme ? iconHome1 : iconHomeLite} className='w-[30px] opacity-70' alt="" />
              </div>
              {!toggle_sidebar && <div className={`${web_theme.web_theme ? 'text-sidebar-text-dark' : 'text-sidebar-text-lite2 opacity-70'} font-semibold text-[1rem] mt-2px] opacity-70`}>
                Home
              </div>}
            </NavLink>

            {/* home */}
            <NavLink onClick={() => dispatch(FileCompBlurSlice.actions.changeBlur(""))} to="/dashboard/files" end className={({ isActive }) => `${isActive ? "bg-bethel-green/60" : ""} flex ${toggle_sidebar ? 'justify-center' : 'justify-start'} items-center p-3 rounded-2xl hover:bg-bethel-green/50 gap-2`}>
              <div>
                <img src={web_theme.web_theme ? icon_folder_dark : icon_folder_lite} className='w-[32px] opacity-70' alt="" />
              </div>

              {!toggle_sidebar && <div className={`${web_theme.web_theme ? 'text-sidebar-text-dark' : 'text-sidebar-text-lite2 opacity-100'} font-semibold text-[1rem] mt-2px] opacity-70`}>
                Files
              </div>}
            </NavLink>

            {/* home */}
            <NavLink onClick={() => dispatch(FileCompBlurSlice.actions.changeBlur(""))} to="/dashboard/profile" className={({ isActive }) => `${isActive ? "bg-bethel-green/60" : ""} flex ${toggle_sidebar ? 'justify-center' : 'justify-start'} items-center p-3 rounded-2xl hover:bg-bethel-green/50 gap-2`}>
              <img src={web_theme.web_theme ? icon_user_dark : icon_user_lite} className='w-[30px] opacity-70' alt="" />
              {!toggle_sidebar && <div className={`${web_theme.web_theme ? 'text-sidebar-text-dark' : 'text-sidebar-text-lite2 opacity-100'} font-semibold text-[1rem] mt-2px] opacity-70`}>
                Profile
              </div>}
            </NavLink>

            {/* home */}
            <NavLink onClick={() => dispatch(FileCompBlurSlice.actions.changeBlur(""))} to="/dashboard/billing" className={({ isActive }) => `${isActive ? "bg-bethel-green/60" : ""} flex ${toggle_sidebar ? 'justify-center' : 'justify-start'} items-center p-3 rounded-2xl hover:bg-bethel-green/50 gap-2`}>
              <img src={web_theme.web_theme ? icon_plan_dark : icon_plan_lite} className='w-[30px] opacity-70' alt="" />
              {!toggle_sidebar && <div className={`${web_theme.web_theme ? 'text-sidebar-text-dark' : 'text-sidebar-text-lite2 opacity-100'} font-semibold text-[1rem] mt-2px] opacity-70`}>
                Plans
              </div>}
            </NavLink>

            {/* home */}
            <NavLink onClick={() => dispatch(FileCompBlurSlice.actions.changeBlur(""))} to="/dashboard/referrals" className={({ isActive }) => `${isActive ? "bg-bethel-green/60" : ""} flex ${toggle_sidebar ? 'justify-center' : 'justify-start'} items-center p-3 rounded-2xl hover:bg-bethel-green/50 gap-2`}>
              <img src={web_theme.web_theme ? icon_ref_dark : icon_ref_lite} className='w-[30px] opacity-70' alt="" />
              {!toggle_sidebar && <div className={`${web_theme.web_theme ? 'text-sidebar-text-dark' : 'text-sidebar-text-lite2 opacity-100'} font-semibold text-[1rem] mt-2px] opacity-70`}>
                Referrals
              </div>}
            </NavLink>

            {/* line */}
            <div className='w-full flex justify-center mt-2'>
              <hr className={`${web_theme.web_theme ? 'border-sidebar-bg-lite' : 'border-sidebar-bg-dark'} w-full h-[5px] opacity-20`} />
            </div>
          </div>

          {/* bottom container */}
          <div className='flex flex-col items-center justify-center px-4 absolute bottom-32 gap-[10px] w-full'>

            {/* line */}
            <div className='w-full flex justify-start'>
              <hr className={`${web_theme.web_theme ? 'border-sidebar-bg-lite' : 'border-sidebar-bg-dark'} w-full h-[5px] opacity-20`} />
            </div>

            {/* home */}
            <a href='https://docs.bethelnet.io/' target='_blank' rel='noreferrer' className={`flex ${toggle_sidebar ? 'justify-center' : 'justify-start'} items-center p-3 rounded-2xl hover:bg-bethel-green/50 w-full gap-2 px-4`}>
              <img src={web_theme.web_theme ? icon_info_dark : icon_info_lite} className='w-[25px] opacity-100' alt="" />
              {!toggle_sidebar && <div className={`${web_theme.web_theme ? 'text-sidebar-text-dark' : 'text-sidebar-text-lite2 opacity-100'} font-semibold text-[1rem] mt-2px] opacity-70`}>
                Info
              </div>}
            </a>

            {/* home */}
            <button onClick={logOut} className={`flex ${toggle_sidebar ? 'justify-center' : 'justify-start'} items-center p-3 rounded-2xl hover:bg-red-700/50 w-full gap-2 px-4`}>
              <img src={web_theme.web_theme ? icon_power : icon_power} className='w-[25px] opacity-100' alt="" />
              {!toggle_sidebar && <div className='text-red-600 text-[1rem] mt-[2px] font-semibold'>
                Logout
              </div>}
            </button>

          </div>




        </div>
      </div>
    </div>

  )
}

export default SideBar
