import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PricePlanCardV2 from './Cards/PricePlanCardV2'
import paper_rocket from "../Images/BG-Images/paper-rocket.png"
import paper_rocket_lite from "../Images/BG-Images/paper-rocket-lite.png"
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { check_active_plan, GET_BECX_PRICE, GET_PLAN_PRICE, is_user_added } from '../queris'
import iconDone from "../Images/icons/icon-newdone.png"
import iconfail from "../Images/icons/icon-fail.png"
import { ACTIVATE_PLAN, ADD_CHAIN_USER } from '../mutations'
import { ethers } from 'ethers'
import MetamaskInstall from './Cards/MetamaskInstall'
import metaMaskLogo from "../Images/icons/metamask.png"
import robot from "../Images/BG-Images/robot.png"
import planStatusSlice from '../reducers/PlanStatusSlice'

const tokenABI = [
    'function name() view returns (string)',
    'function approve(address spender, uint256 amount) returns (bool)',
    'function transfer(address recipient, uint256 amount) returns (bool)',
];

const tokenAddress = process.env.REACT_APP_TOKEN_ADDRESS_FOR_TRANSFER

function DashbaordPlanv2() {
    const web_theme = useSelector((state) => state.webThemeReducer.web_theme)

    const did = useSelector((state) => state.DidReducer)

    const plan_options = { op1: "Unlimited Uploads", op2: "Upto 1GB storage space", op3: 'Hack-Proof ZKP Protected', op4: "Blockchain-Based Security", op5: "Decentralized Data Protection" }

    // month selection
    const [planPeriod, setPlanPeriod] = useState({ month: true, month6: false, year: false })
    const [plan, setPlan] = useState("")
    const [currentPackage, setCurrentPackage] = useState()
    const [planActivateDuration, setPlanActivationDuration] = useState(null) // to get plan activate months
    const [wallet_address, setWallet_address] = useState(null)
    const [plan_activation_status, setPlan_activation_status] = useState({ is_loading: false, is_complete: false, msg: '', is_error: null })
    const [toggleMetaMaskInstalationGuide, setToggleMetaMaskInstalationGuide] = useState(false)

    const dispatch = useDispatch()
    const planExpired = useSelector((state) => state.planStatusReducer)

    // plan status
    const [plan_status, setPlanStatus] = useState({ is_loading: false, is_complete: false, is_error: false })

    // select plan fn 
    const selectPeriod = (duration) => {
        if (duration === "1") {
            setPlanPeriod((prev) => ({ ...prev, month: true, month6: false, year: false }))
        }
        if (duration === "6") {
            setPlanPeriod((prev) => ({ ...prev, month: false, month6: true, year: false }))
        }
        if (duration === "12") {
            setPlanPeriod((prev) => ({ ...prev, month: false, month6: false, year: true }))
        }
    }

    // add price plan
    const set_plan = (name) => {
        setPlan(name)

        setPlanStatus((prev) => ({ ...prev, is_loading: true })) // plan status for add plan button

        if (planPeriod.month) {
            add_plan(1, name, name) // add plan 
        }
        if (planPeriod.month6) {
            add_plan(6, name, name) // add plan 

        }
        if (planPeriod.year) {
            add_plan(12, name, name) // add plan 
        }
    }

    const [becx_price, setBecx_price] = useState(0)

    // get becx price 
    const { data, loading, error } = useQuery(GET_BECX_PRICE)

    const { data: active_plan_data, loading: active_plan_loading, error: active_plan_error, refetch: refetch_active_plan } = useQuery(check_active_plan, { variables: { input: { did: did }, caches : 'network-only'}});

    const [addChainUser] = useMutation(ADD_CHAIN_USER);

    const [is_user_add] = useLazyQuery(is_user_added)

    const [get_plan_price] = useLazyQuery(GET_PLAN_PRICE)

    const [activate_plan, { data: data_activate_plan, loading: activate_plan_loading, error: activate_plan_error }] = useMutation(ACTIVATE_PLAN)

    useEffect(() => {
        if (data) {
            setBecx_price(parseFloat(data.getBecxPrice.price))
        }
    }, [data])

    // checking user added or not
    const check_user = async () => {
        try {
            const res = await is_user_add({ variables: { did: did } })

            if (res.data) {
                console.log(res.data.isUserExistsOnChain)
                return res.data.isUserExistsOnChain.isRegistered
            }
        } catch (error) {
            console.log("user added error :", error)
            return false
        }
    }

    // check meta mask installation and get wallet address ( graph QL ) 
    const get_wallet_address = async () => {
        if (window.ethereum) {
            const accounts = await window.ethereum.request({
                method: "eth_requestAccounts",
            });
            // Get the first account
            const address = accounts[0];

            setWallet_address(address)
            return address
        } else {
            return false
        }
    }

    // add user fn 
    const add_user_onchain = async () => {

        setPlan_activation_status((prev) => ({ ...prev, msg: "user adding in progress" }))

        try {
            const res = await addChainUser({ variables: { did } })
            if (res.data) {
                setPlan_activation_status((prev) => ({ ...prev, msg: "user adding success!" }))
                dispatch(planStatusSlice.actions.saveStatus({
                    planActive: true,
                    planExpire: planExpired.planExpire,
                    planSpace: null
                  }))
                return true
            }
        } catch (error) {
            console.log("user adding fail", error)
            setPlan_activation_status((prev) => ({ ...prev, is_error: "user adding fail", msg: '' }))

            setTimeout(() => {
                setPlan_activation_status((prev) => ({ ...prev, is_loading: false, is_error: null }))
            }, 3000);
            return false
        }
    }

    // calculate becx price 
    const priceCalc = async (duration, monthDur) => {
        setPlan_activation_status((prev) => ({ ...prev, msg: 'calculating spending cap !' }))
        try {
            const plan_price = await get_plan_price({
                variables: {
                    "did": did,
                    "plan": monthDur,
                    "month": duration
                }
            })
            if (plan_price.data) {
                return plan_price.data.getPlanPrice.price
            }


        } catch (error) {
            setPlanStatus((prev) => ({ ...prev, is_loading: false, is_error: true })) // plan status for add plan button

            setTimeout(() => {
                setPlanStatus((prev) => ({ ...prev, is_loading: false, is_error: false })) // plan status for add plan button
            }, 3000);
        }
    }

    // make space according to the plan
    const make_space_fn = async (p_name) => {
        console.log(p_name)
        if (p_name === "Basic") {
            return 0
        }
        if (p_name === "Starter" || p_name === "StarterPro") {
            return 1
        }
        if (p_name === "Advance" || p_name === "AdvancePro") {
            return 2
        }
    }

    // add basic plan 
    const add_plan = async (plan_duration, package_name, name) => {
        const wallet = await get_wallet_address()
        setPlan_activation_status((prev) => ({ ...prev, is_loading: true }))

        if (!wallet) {
            setPlanStatus((prev) => ({ ...prev, is_loading: false }))
            setToggleMetaMaskInstalationGuide(true)
            return
        }

        const check = await check_user()

        console.log("this is check :", check)

        // check user is added or not 
        if (check === false) {
            console.log("user is false")
            await add_user_onchain() // user adding 
        }

        // get space from specific plan
        const space = await make_space_fn(package_name)

        //calculate becx for the plan 
        let duration = 0
        let monthDur = null

        if (package_name === "Basic") {
            package_name = "PRIMARY"
        }
        if (package_name === "StarterPro") {
            package_name = "STARTER_PRO"
        }
        if (package_name === "AdvancePro") {
            package_name = "ADVANCE_PRO"
        }

        // check if the plan duration month
        switch (true) {
            case planPeriod.month:
                duration = 1
                monthDur = `MONTH_1_${package_name.toUpperCase()}`
                break;

            case planPeriod.month6:
                duration = 6
                monthDur = `MONTH_6_${package_name.toUpperCase()}`
                break;

            case planPeriod.year:
                duration = 12
                monthDur = `MONTH_12_${package_name.toUpperCase()}`
                break;

            default:
                break;
        }

        //becx transfer ammount 
        const becx = await priceCalc(duration, monthDur)
        console.log(becx)

        // transfer amount and approve contract
        try {
            // send token for becx
            // const provider = new ethers.providers.Web3Provider(window.ethereum)
            // const signer = provider.getSigner()
            // const contract = new ethers.Contract(tokenAddress, tokenABI, signer)

            // const owner = await get_wallet_address()
            // console.log("this is owner :",owner)

            // // transfer into a wei value 
            // const amountInWei = ethers.utils.parseUnits(becx.toString(), 18); // calcBECX.toString()

            // // Approve the recipient to spend the tokens
            // const approveTx = await contract.approve(owner, amountInWei);
            // const approvetx = await approveTx.wait();


            // // Transfer the tokens from the owner (signer) to the recipient
            // const transferTx = await contract.transfer(wallet_address, amountInWei);
            // const trasnfertx = await transferTx.wait();

        } catch (error) {

            console.log("error:-", error.message)

            setPlanStatus((prev) => ({ ...prev, is_loading: false }))

            setPlan_activation_status((prev) => ({ ...prev, msg: 'plan activate failed !', is_error: true }))

            setTimeout(() => {
                setPlan_activation_status((prev) => ({ ...prev, msg: '', is_loading: false, is_complete: false, is_error: false }))
            }, 3000);
            return
        }

        setPlan_activation_status((prev) => ({ ...prev, msg: 'plan adding in progress please wait !' }))

        if (name === "Basic") {
            name = "Primary"
        }

        try {
            const response = await activate_plan({
                variables: {
                    "input": {
                        "did": did,
                        "duration": plan_duration,
                        "inapp_transactionid": "",
                        "package_name": name,
                        "paid_address": wallet,
                        "payment_method": "WalletPurchase",
                        "space": space
                    }
                }
            })

            if (response.data) {
                console.log(response.data)
                setPlan_activation_status((prev) => ({ ...prev, msg: 'plan activate successfully !', is_complete: true }))

                const results = await refetch_active_plan({ fetchPolicy: "network-only" })
                console.log("this is results:", results.data.getCurrentPackage)
                setCurrentPackage(results.data.getCurrentPackage)

                setTimeout(() => {
                    setPlanStatus((prev) => ({ ...prev, is_loading: false }))
                    setPlan_activation_status((prev) => ({ ...prev, msg: '', is_loading: false, is_complete: false }))
                }, 3000);

                return true

            }
            if (activate_plan_error) {
                setPlanStatus((prev) => ({ ...prev, is_loading: false }))

                setPlan_activation_status((prev) => ({ ...prev, msg: 'plan activate failed !', is_error: true }))

                setTimeout(() => {
                    setPlan_activation_status((prev) => ({ ...prev, msg: '', is_loading: false, is_complete: false, is_error: false }))
                }, 3000);
                return false
            }
        } catch (error) {
            console.log("plan activation fail :: ", error)

            setPlanStatus((prev) => ({ ...prev, is_loading: false }))

            setPlan_activation_status((prev) => ({ ...prev, msg: 'plan activate failed !', is_error: true }))

            setTimeout(() => {
                setPlan_activation_status((prev) => ({ ...prev, msg: '', is_loading: false, is_complete: false, is_error: false }))
            }, 3000);

            return false
        }

    }

    // check active plan status ( graph QL ) 
    useEffect(() => {
        if (active_plan_data) {
            setPlanActivationDuration(active_plan_data.getCurrentPackage.durationMonths)
            
            setCurrentPackage(active_plan_data.getCurrentPackage)

            selectPeriod(active_plan_data.getCurrentPackage.durationMonths)
        }
        if (active_plan_loading) {
            console.log("loading is active plan  loading :")
        }
        if (active_plan_error) {
            console.log("error is active plan :", active_plan_error.message)
        }
    }, [active_plan_data, active_plan_loading, active_plan_error])

    return (
        <div className={`w-full flex flex-col ${web_theme ? 'text-white bg-dashboard-home-bg1' : 'text-sidebar-text-lite2 bg-sidebar-bg-lite'} p-5 rounded-[25px]`}>
            {/* title section */}
            <div className='flex flex-col mt-2 mb-1 w-full'>
                <div className='ml-2 opacity-50 text-[.9rem] flex flex-col gap-2'>
                    <h3>Price Plans</h3>
                    <hr className={`${web_theme ? "text-white bg-white opacity-30 h-[1px]" : " text-sidebar-text-lite2 opacity-100 h-[2px]"} w-[99%] `} />
                </div>
            </div>

            {/* select plan buttons */}
            <div className='w-full  rounded-lg py-3 flex flex-col gap-4 '>
                {/* plan choose container */}
                <div className={`flex w-fit ${web_theme ? 'bg-dashboard-home-bg1' : 'bg-sidebar-text-lite2/20 shadow-md'} rounded-[25px] p-1`}>
                    {/* default plans */}
                    <button disabled={plan_status.is_loading} onClick={() => selectPeriod("1")} className={`flex text-[.9rem] w-[100px] py-1 px-2 text-center justify-center ${web_theme ? 'text-black' : 'text-white'} rounded-[25px] border-r border-bethel-green/20 
                        ${planPeriod.month ? "bg-bethel-green text-black font-bold" : "text-white/60"}`}>
                        <h3 className=''>1 Month</h3>
                    </button>

                    {/* default plans */}
                    <button disabled={plan_status.is_loading} onClick={() => selectPeriod("6")} className={`flex text-[.9rem] w-[100px] py-1 px-2 text-center justify-center ${web_theme ? '' : ''} rounded-[25px] border-r border-bethel-green/20    
                        ${planPeriod.month6 ? "bg-bethel-green text-black font-bold" : "text-white/60"}`}>
                        <h3 className=''>6 Months</h3>
                    </button>

                    {/* default plans */}
                    <button disabled={plan_status.is_loading} onClick={() => selectPeriod("12")} className={`flex text-[.9rem] w-[100px] py-1 px-2 text-center justify-center ${web_theme ? '' : ''} rounded-[25px] border-r border-bethel-green/20  
                        ${planPeriod.year ? "bg-bethel-green text-black font-bold" : "text-white/60"}`}>
                        <h3 className=''>12 Months</h3>
                    </button>


                </div>



            </div>

            {/* plan card section */}

            <div className='flex w-full p-1 gap-6 flex-wrap justify-center items-stretch'>
                <PricePlanCardV2
                    currentPackage={active_plan_data && active_plan_data.getCurrentPackage}
                    plan={plan}
                    plan_duration={(planPeriod.month && "1") || (planPeriod.month6 && "6") || (planPeriod.year && "12")}
                    plan_name={"Basic"}
                    plan_status={plan_status}
                    plan_image={web_theme ? paper_rocket : paper_rocket_lite}
                    plan_price={(planPeriod.month && 0.99) || (planPeriod.month6 && 5.69) || (planPeriod.year && 11.88)}
                    plan_des={"Ideal for individual users looking to safely store and manage their data"}
                    plan_options={plan_options}
                    becx={becx_price}
                    set_plan={(plan_name) => set_plan(plan_name)}

                />

                <PricePlanCardV2
                    currentPackage={active_plan_data && active_plan_data.getCurrentPackage}
                    plan={plan}
                    plan_status={plan_status}
                    plan_duration={(planPeriod.month && "1") || (planPeriod.month6 && "6") || (planPeriod.year && "12")}
                    plan_name={"Starter"}
                    plan_image={web_theme ? paper_rocket : paper_rocket_lite}
                    plan_price={(planPeriod.month && 2.99) || (planPeriod.month6 && 17.99) || (planPeriod.year && 35.99)}
                    plan_des={"Ideal for individual users looking to safely store and manage their data"}
                    plan_options={plan_options}
                    becx={becx_price}
                    set_plan={(plan_name) => set_plan(plan_name)}

                />

                <PricePlanCardV2
                    currentPackage={active_plan_data && active_plan_data.getCurrentPackage}
                    plan={plan}
                    plan_name={"StarterPro"}
                    plan_status={plan_status}
                    plan_duration={(planPeriod.month && "1") || (planPeriod.month6 && "6") || (planPeriod.year && "12")}
                    plan_image={web_theme ? paper_rocket : paper_rocket_lite}
                    plan_price={(planPeriod.month && 9.99) || (planPeriod.month6 && 59.99) || (planPeriod.year && 119)}
                    plan_des={"Ideal for individual users looking to safely store and manage their data"}
                    plan_options={plan_options}
                    becx={becx_price}
                    set_plan={(plan_name) => set_plan(plan_name)}

                />

                <PricePlanCardV2
                    currentPackage={active_plan_data && active_plan_data.getCurrentPackage}
                    plan={plan}
                    plan_name={"Advance"}
                    plan_status={plan_status}
                    plan_duration={(planPeriod.month && "1") || (planPeriod.month6 && "6") || (planPeriod.year && "12")}
                    plan_image={web_theme ? paper_rocket : paper_rocket_lite}
                    plan_price={(planPeriod.month && 29.99) || (planPeriod.month6 && 179.69) || (planPeriod.year && 359.99)}
                    plan_des={"Ideal for individual users looking to safely store and manage their data"}
                    plan_options={plan_options}
                    becx={becx_price}
                    set_plan={(plan_name) => set_plan(plan_name)}

                />

                <PricePlanCardV2
                    currentPackage={active_plan_data && active_plan_data.getCurrentPackage}
                    plan={plan}
                    plan_name={"AdvancePro"}
                    plan_status={plan_status}
                    plan_duration={(planPeriod.month && "1") || (planPeriod.month6 && "6") || (planPeriod.year && "12")}
                    plan_image={web_theme ? paper_rocket : paper_rocket_lite}
                    plan_price={(planPeriod.month && 59.99) || (planPeriod.month6 && 359.99) || (planPeriod.year && 719.99)}
                    plan_des={"Ideal for individual users looking to safely store and manage their data"}
                    plan_options={plan_options}
                    becx={becx_price}
                    set_plan={(plan_name) => set_plan(plan_name)}
                />
            </div>

            {/* file uploading section */}
            {plan_activation_status.is_loading &&
                <div className={`fixed flex w-[400px] h-auto rounded-t-[25px] bottom-0 right-2 p-2 flex-col animate__animated animate__fadeInUp
        ${web_theme ? 'bg-black text-white' : 'bg-sidebar-bg-lite shadow-md text-sidebar-text-lite2 border border-sidebar-text-lite2/20'}
        `}>
                    {/* title section div */}
                    <div className="w-full py-2 px-4 text-[.8rem] flex justify-between">
                        <div className="font-normal tracking-wide ">
                            <h3>Plan Activation</h3>
                        </div>

                        {/* loading div */}
                        {plan_activation_status.is_loading && <div className="loader_upload">
                        </div>
                        }

                        {false && <div className="opacity-100 animate-pulse">
                            <img src={iconDone} className="w-[25px]" alt="" />
                        </div>}

                        {false && <div>
                            <img src={iconfail} className="w-[20px]" alt="" />
                        </div>
                        }
                    </div>

                    {/* files loop */}
                    <div className={`flex text-[.8rem] gap-2 flex-col p-2 ${web_theme ? 'bg-white/20' : 'bg-black/10'} rounded-t-[25px] h-auto overflow-scroll animate-pulse`} >
                        <div className="w-full flex py-1 px-2 opacity-60 justify-between ">
                            <h3>{plan_activation_status.msg}</h3>
                        </div>
                    </div>

                </div>
            }

            {toggleMetaMaskInstalationGuide &&
                <a href='https://metamask.io/download' target='_blank' rel='noreferrer' className='w-full'>
                    <MetamaskInstall installMetaMask={"AddMainnet"} header={"Metamask is not available !"} img={metaMaskLogo} li1={"System detected that you are not installed metamask extension"} li2={"Please make sure you are installed metamask.. click this box to download"} li3_1={"After installation please refresh the page !"} />

                    {/* div ai robot div */}
                    <div className='fixed -right-14 top-[270px] -rotate-12 rounded-full p-5 animate__animated animate__fadeInRight animate__fast'>
                        <img src={robot} className='w-[150px] opacity-80 ' alt="" />
                    </div>

                </a>}


        </div>
    )
}

export default DashbaordPlanv2