import { useSelector } from 'react-redux'
import iconTick from "../../Images/icons/icon-tick.png"
import { useEffect, useState } from 'react'

function PricePlanCardV2({ plan_name, plan_image, plan_price, plan_des, plan_options, becx, set_plan, plan_status, plan, currentPackage, plan_duration }) {
    const web_theme = useSelector((state) => state.webThemeReducer.web_theme)
    const [real_plan_name, setReal_plan_name] = useState() // set plan name for display current active status 

    useEffect(() => {
        if (currentPackage && currentPackage.packageName === "Primary") {
            setReal_plan_name("Basic")
        }
        else {
            setReal_plan_name(currentPackage && currentPackage.packageName)
        }
    }, [currentPackage])

    return (
        <div className={`lg:w-[400px] md:w-[300px] sm:w-full min-[320px]:w-full gap-4 flex flex-col p-5 rounded-[25px] ${web_theme ? 'bg-dashboard-small-card text-white' : 'border border-sidebar-text-lite2/20 shadow-sm'}`}>
            {/* plan name */}
            <div className='w-full opacity-80 flex text-[1.8rem] font-bold tracking-wide text-center items-center justify-center'>
                <h3>{plan_name}</h3>
            </div>

            {/* plan image */}
            <div className='flex w-full items-center justify-center h-auto'>
                <img src={plan_image} className='w-[200px]' alt="" />
            </div>

            {/* plan price */}
            <div className='flex w-full items-center justify-center text-[2rem] font-bold tracking-wide h-auto flex-col text-center'>
                <div className={`${web_theme ? 'text-bethel-green' : ''} text-[2.3rem]`}>$ {plan_price} <div className='text-[.8rem]'>( {(plan_price / becx).toFixed(2)} BECX )</div></div>
            </div>

            {/* plan description */}
            <div className='flex w-full items-center justify-center text-[.9rem] font-bold tracking-wide opacity-70 h-auto'>
                <h3>{plan_des}</h3>
            </div>

            {/* plan status */}
            <div className='flex flex-col w-full items-start justify-center text-[.9rem] font-bold tracking-wide opacity-70 h-auto'>
                <div className='flex items-center gap-2'>
                    <img src={iconTick} alt="" className='w-[15px]' />
                    <h3>{plan_options.op1}</h3>
                </div>
                <div className='flex items-center gap-2'>
                    <img src={iconTick} alt="" className='w-[15px]' />
                    <h3>{plan_options.op2}</h3>
                </div><div className='flex items-center gap-2'>
                    <img src={iconTick} alt="" className='w-[15px]' />
                    <h3>{plan_options.op3}</h3>
                </div><div className='flex items-center gap-2'>
                    <img src={iconTick} alt="" className='w-[15px]' />
                    <h3>{plan_options.op4}</h3>
                </div>
            </div>

            {/* add plan button */}
            <button disabled={plan_status.is_loading || (plan_name === real_plan_name && plan_duration === currentPackage.durationMonths)} onClick={() => set_plan(plan_name)} className={`
                ${plan_status.is_loading && plan_name === plan ? 'animate-pulse bg-bethel-green' : ''} 
                ${plan_name === real_plan_name && plan_duration === currentPackage.durationMonths ? "text-bethel-green" : ''}
                ${web_theme ? ' hover:bg-bethel-green hover:text-dashboard-small-card ' : 'bg-sidebar-text-lite2 text-white'}
                w-full flex items-center justify-center rounded-[25px] border border-white/20  hover:scale-105 p-1 font-bold py-2`}>
                {plan_name === real_plan_name && plan_duration === currentPackage.durationMonths ?
                    <div>Activated</div>
                    :
                    <div>Add Plan</div>
                }
            </button>
        </div>
    )
}

export default PricePlanCardV2