import { Route, Routes } from "react-router-dom";
import Navbar from "./Nav-bar";
import SideBar from "./Side-Bar";
import DashboardHome from "./Dashboard-Home";
import DashboardProfile from "./Dashboard-Profile";
import FileSharedComp from "./FIles-ShareFiles";
import DashboardStorageFolder2 from "./Dashboard-Storage_Folder2";
import { useEffect, useState } from "react";
import "../components/style.css"
import { useSelector } from "react-redux";
import DashboardBilling from "./Dashbaord-Plan-v2";
import ActivitiesComponent from "./Activities-Component";
import KYCComponent from "./KYC-Component";
import ErrorPage404 from "./Validations/ErrorPage404";
import ReferralsComponent from "./Referrals-Component";

function MainContent() {
  const [isLoading, setIsLoading] = useState(true)


  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 2000)
  }, [])


  const web_theme = useSelector((state) => state.webThemeReducer)
  const sidebar = useSelector((state) => state.toggleSidebarReducer)

  return (

    <div className={`relative min-h-screen ${web_theme.web_theme ? 'bg-[#0F0F0F]' : 'bg-[#BFE0C8]'}`}>
      <div className="relative z-[100]">
        <div className="relative z-[100]">
          <SideBar />
        </div>
        <div className="relative z-[0]">
          <Navbar />
        </div>
      </div>

      {/* middle routing  */}
      {isLoading &&
        <div className="flex lg:pl-[200px] md:pl-0 sm:pl-0 min-[320px]:pl-0 min-h-screen w-full justify-center items-center">
          <svg className="pl" viewBox="0 0 200 200" width="200" height="200" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <linearGradient id="pl-grad1" x1="1" y1="0.5" x2="0" y2="0.5">
                <stop offset="0%" stopColor="hsl(113,90%,55%)" />
                <stop offset="100%" stopColor="hsl(255,100%,100%)" />
              </linearGradient>
              <linearGradient id="pl-grad2" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="hsl(113,90%,55%)" />
                <stop offset="100%" stopColor="hsl(223,90%,55%)" />
              </linearGradient>
            </defs>
            <circle className="pl__ring" cx="100" cy="100" r="82" fill="none" stroke="url(#pl-grad1)" strokeWidth="36" strokeDasharray="0 257 1 257" strokeDashoffset="0.01" strokeLinecap="round" transform="rotate(-90,100,100)" />
            <line className="pl__ball" stroke="url(#pl-grad2)" x1="100" y1="18" x2="100.01" y2="182" strokeWidth="36" strokeDasharray="1 165" strokeLinecap="round" />
          </svg>
        </div>
      }
      {!isLoading && <div className={`relative pt-24 ${sidebar.toggleSidebar ? 'lg:pl-[220px]' : 'lg:pl-[120px]'} lg:md-[270px] sm:pl-[10px] lg:px-0 md:px-0 sm:px-4 min-[320px]:px-4 w-full z-[10]`}>
        <div className={`fixed top-0 w-full h-[30px] ${web_theme.web_theme ? 'bg-[#0F0F0F]' : ''} z-[100]`}></div>
        <Routes>
          <Route path="/dashboard" element={<DashboardHome />} />
          <Route path="/dashboard/profile" element={<DashboardProfile />} />
          <Route path="/dashboard/files" element={<DashboardStorageFolder2 />} />
          <Route path="/dashboard/billing" element={<DashboardBilling></DashboardBilling>} />
          <Route path="/dashboard/files/shared-files" element={<FileSharedComp />} />
          <Route path="/dashboard/activities" element={<ActivitiesComponent />} />
          <Route path="/dashboard/KYC" element={<KYCComponent />}></Route>
          <Route path="/dashboard/referrals" element={<ReferralsComponent />}></Route>

          <Route path="*" element={<ErrorPage404 />} />
        </Routes>
      </div>}
      {/* end- middle routing */}

    </div>
  )
}

export default MainContent;
