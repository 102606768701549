import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import iconLogs from "../Images/icons/icon-logs.png"
import iconMiniLogs from "../Images/icons/icon-miniLogs.png"

function ActivitiesComponent() {
  const userDid = useSelector((state) => state.DidReducer)

  // activity array 
  const [activityArr, setActivityArr] = useState([])

  const getActivities = async () => {

    try {
      const res = await fetch(process.env.REACT_APP_BACKEND_URL + `logs?did=${userDid}`)
      const activities = await res.json()

      setActivityArr(activities.grouped_logs.grouped_logs)
    } catch (error) {
      console.log(error)
    }

  }

  const avalibilityData = useSelector((state) => state.requireAvalibilityReducer)

  useEffect(() => {
    getActivities()
  }, [])
  return (
    <div className={`px-2 ${!avalibilityData.allreadyRegisteredWallet && !avalibilityData.userAdded && !avalibilityData.noUser ? "" : "pointer-events-none grayscale blur-sm"}`}>

      {/* topic section  */}
      <div className='w-full text-white text-[1rem] flex gap-2 mt-4'>
        <img src={iconLogs} className='w-[25px]' alt="" />
        <h3 className='font-normal'>SYSTEM LOGS</h3>
      </div>

      {/* table section */}


      <div class="relative overflow-x-auto shadow-md sm:rounded-lg mt-4">
        <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead class="text-xs text-white/80 uppercase bg-bethel-green/50">
            <tr>
              <th scope="col" class="px-6 py-3">

              </th>
              <th scope="col" class="px-6 py-3">
                Activity
              </th>
              <th scope="col" class="px-6 py-3">
                Date
              </th>
              <th scope="col" class="px-6 py-3">
                Time
              </th>
              <th scope="col" class="px-6 py-3">
                Status
              </th>
              <th scope="col" class="px-6 py-3">
                Level
              </th>
            </tr>
          </thead>

          {/* table body section */}
          <tbody className="">
            {activityArr && activityArr.map((log , index) => {
              return <tr class="border-b-bethel-green/40 border-b-[1px] bg-black " key={index}>
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  <img src={iconMiniLogs} className="w-[25px]" alt="" />
                </th>
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  {log && log.logs[0].data}
                </th>
                <td class="px-6 py-4">
                  {log && log.logs[0].human_readable_timestamp.slice(0,10)}
                </td>
                <td class="px-6 py-4">
                  {log && log.logs[0].human_readable_timestamp.slice(11,16)}
                </td>
                <td class="px-6 py-4">
                  {/* button that changes when fail or success */}
                  {log && log.logs[0].level === "INFO" ? <div className='flex justify-center px-1 py-1 text-green-600 font-bold border-[1px] border-green-600 text-center rounded-xl text-[0.8rem] w-[90px]'>
                    <h3 className='text-center'>succeeded</h3>
                  </div>
                    :
                    // FAIL
                    <div className='flex justify-center px-1 py-1 text-red-600 font-bold border-[1px] border-red-600 text-center rounded-xl text-[0.8rem] w-[90px]'>
                      <h3 className='text-center'>Failed</h3>
                    </div>}

                </td>
                <td class="px-6 py-4">
                  {log && log.logs[0].level}
                </td>

              </tr>
            })}


          </tbody>
        </table>
      </div>

    </div>
  )
}

export default ActivitiesComponent