import React, { useEffect, useState } from 'react'
import { PieChart, Pie, Legend, Tooltip, ResponsiveContainer, isAnimationActive } from 'recharts';
import FilesCards from './MiniCards/FilesCards';
import fileIcon from "../../Images/icons/icon-file.png"
import iconImages from "../../Images/icons/icons-images-96.png"
import iconAudio from "../../Images/icons/icons8-music-100.png"
import iconVideo from "../../Images/icons/icon-video.png"
import { useSelector } from 'react-redux';


function PieChartCard({ card_icon, card_heading, card_subHeading, UsedValue, FilesCount, currentPackage }) {

    const [value, setValue] = useState(5)
    const web_theme = useSelector((state) => state.webThemeReducer.web_theme) // change theme

    const getCurrentGB = () => {

        switch (currentPackage) {
            case "Basic":
                setValue(1);
                break;
            case "Advance":
                setValue(5000);
                break;
            case "Starter":
                setValue(1000)
                break;
            default:
                setValue(1)
        }
    }

    useEffect(() => {
        getCurrentGB()
    }, [currentPackage])



    const data01 = [
        { name: 'Used (GiB)', value: UsedValue / 1000 / 1000 / 1000, fill: web_theme ? '#ffffff50' : '#00000030' },
        { name: 'Remain (GiB)', value: value - (UsedValue / 1000 / 1000 / 1000 ), fill: web_theme ? '#2cffae80' : '#267144' },
    ];

    return (
        <div className={`w-full ${web_theme ? 'bg-dashboard-small-card' : 'shadow-md border border-sidebar-text-lite2/10'} rounded-[25px] p-4`}>
            {/* topic section */}
            <div className='w-full opacity-100 text-[1rem]'>
                <h3>Storage Details</h3>
            </div>
            {/* chart section */}
            <div className={`w-full lg:h-[200px] min-[3200px]:h-[300px] md:h-[200px] sm:h-[150px] min-[320px]:h-[200px] flex flex-col justify-center items-center mt-9 p-2 border-[1px] ${web_theme ? 'border-white/10' : 'border-sidebar-text-lite2/20'} rounded-lg`}>
                <ResponsiveContainer width="100%" height="100%">
                    <PieChart width={400} height={400}>
                        <Pie
                            dataKey="value"
                            isAnimationActive={true}
                            data={data01}
                            outerRadius={70}
                            label
                        />
                        <Tooltip />
                        <Legend />
                    </PieChart>
                </ResponsiveContainer>

            </div>
        </div>
    )
}

export default PieChartCard