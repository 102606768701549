import React from 'react'
import { useSelector } from 'react-redux'

function SmallCard({ card_icon, heading, subheading, card_data, card_color, stats }) {
  const web = useSelector((state) => state.webThemeReducer.web_theme)

  return (
    <div className={`relative flex ${stats && web ? `text-black font-bold ` : ` border border-sidebar-text-lite2/10 shadow-md`} ${card_color} p-2 rounded-[25px] h-auto lg:w-full md:w-full sm:w-full min-[320px]:w-full min-[2500px]:w-full`}>
      {/* file icon */}
      <div className='flex absolute p-2 bg-bethel-green/100 rounded-full top-[-15px] left-[-1%]'>
        <img src={card_icon} className='w-[20px]' alt="" />
      </div>

      {/* heading and subheading */}
      <div className='flex w-full items-center justify-center'>
        <div className='w-full p-2 mt-3 flex justify-between items-center'>
          <div className='flex w-ful flex-col'>
            <h3 className='text-[.8rem]'>{heading}</h3>
            <h4 className='text-[1rem]'><span className='opacity-50 text-[0.8rem]'>{subheading}</span></h4>
          </div>
          {/* card data */}
          <div className={`flex font-bold w-[50px] h-[50px] rounded-full ${stats ? "bg-white/50" : ''}  ${web ? 'bg-dashboard-home-bg1' : ''} text-[.8rem] items-center justify-center`}>
            <h3>{card_data}</h3>
          </div>

        </div>

      </div>

    </div>
  )
}

export default SmallCard