import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import cameraIcon from "../../Images/icons/icon-camera.png"
import { useLazyQuery, useMutation } from '@apollo/client'
import { DELETE_DOC_FILES, DOWNLOAD_SINGLE_FILE, UPLOAD_DOCS } from '../../mutations'
import { GET_CIDS, GET_DOC_FILE } from '../../queris'
import icon_down_arr from "../../Images/icons/icon-down-arr.png"
import icon_delete from "../../Images/icons/icon-delete.png"

function DocumentUpload() {
    const web_theme = useSelector((state) => state.webThemeReducer.web_theme)
    const did = useSelector((state) => state.DidReducer)
    const [images_links, setImages_links] = useState({ passport_f: null, passport_b: null, nic_f: null, nic_b: null })
    const [show_and_hide, setShow_and_hide] = useState({ passport: false, NIC: false })
    const [uploading_stats, setUploading_stats] = useState({ pf_loading: false, pf_complete: false, pb_loading: false, pb_complete: false, nicf_loading: false, nicf_complete: false, nicb_loading: false, nicb_complete: false })

    useEffect(() => {
        get_doc_passport_front()
        get_doc_passport_back()
        get_doc_nic_front()
        get_doc_nic_back()
    }, [])

    // upload gql functions - - - -  - - - - - -  - - - - - 
    const [upload_doc] = useMutation(UPLOAD_DOCS)
    const [get_doc_file] = useLazyQuery(GET_DOC_FILE)
    const [download_single_file] = useMutation(DOWNLOAD_SINGLE_FILE)
    const [get_file_cids] = useLazyQuery(GET_CIDS)
    const [delete_doc_files] = useMutation(DELETE_DOC_FILES)

    // toggle passport 
    const toggle_passport = () => {
        setShow_and_hide((prev) => ({ ...prev, passport: !prev.passport }))
        setShow_and_hide((prev) => ({ ...prev, NIC: false }))

    }

    // toggle NIC 
    const toggle_nic = () => {
        setShow_and_hide((prev) => ({ ...prev, passport: false }))
        setShow_and_hide((prev) => ({ ...prev, NIC: !prev.NIC }))
    }


    // handle bulk upload fn
    const handleDocUploadPassportFront = async (event) => {
        const uploadss = event.target.files[0];
        setUploading_stats((prev) => ({ ...prev, pf_loading: true }))

        try {
            const res = await upload_doc({
                variables: {
                    "files": uploadss,
                    "did": did,
                    "doctype": "PassportFront",
                    "dockname": "Passport-Front",
                    "docid": "Pass-1-Front"
                }
            })

            if (res.data) {
                console.log("file passport SUccess!!")
                setUploading_stats((prev) => ({ ...prev, pf_loading: false, pf_complete: true }))

                setTimeout(() => {
                    setUploading_stats((prev) => ({ ...prev, pf_loading: false, pf_complete: false }))

                }, 3000);
                get_doc_passport_front()
            }
        } catch (error) {
            console.log("file passport fail !!", error)
        }

    }

    // handle bulk upload fn
    const handleDocUploadPassportBack = async (event) => {
        const uploadss = event.target.files[0];
        console.log(uploadss)
        console.log(did)
        setUploading_stats((prev) => ({ ...prev, pb_loading: true }))
        try {
            const res = await upload_doc({
                variables: {
                    "files": uploadss,
                    "did": did,
                    "doctype": "PassportBack",
                    "dockname": "PassportBack",
                    "docid": "Pass-1-Back"
                }
            })

            if (res.data) {
                console.log("file passport SUccess!!")
                setUploading_stats((prev) => ({ ...prev, pb_loading: false, pb_complete: true }))

                setTimeout(() => {
                    setUploading_stats((prev) => ({ ...prev, pb_loading: false, pb_complete: false }))

                }, 2000);
                get_doc_passport_back()
            }
        } catch (error) {
            console.log("file passport fail !!", error)
        }
    }

    // handle bulk upload fn
    const handleDocUploadNICF = async (event) => {
        const uploadss = event.target.files[0];

        setUploading_stats((prev) => ({ ...prev, nicf_loading: true }))

        try {
            const res = await upload_doc({
                variables: {
                    "files": uploadss,
                    "did": did,
                    "doctype": "NicFront",
                    "dockname": "NicFront",
                    "docid": "Nic-1-Front"
                }
            })

            if (res.data) {
                console.log("file passport SUccess!!")
                setUploading_stats((prev) => ({ ...prev, nicf_loading: false, nicf_complete: true }))

                setTimeout(() => {
                    setUploading_stats((prev) => ({ ...prev, nicf_loading: false, nicf_complete: false }))

                }, 2000);
                get_doc_nic_front()
            }
        } catch (error) {
            console.log("file passport fail !!", error)
        }

    }

    // handle bulk upload fn
    const handleDocUploadNICB = async (event) => {
        const uploadss = event.target.files[0];
        setUploading_stats((prev) => ({ ...prev, nicb_loading: true }))
        try {
            const res = await upload_doc({
                variables: {
                    "files": uploadss,
                    "did": did,
                    "doctype": "NicBack",
                    "dockname": "NicBack",
                    "docid": "Nic-1-Back"
                }
            })

            if (res.data) {
                console.log("file passport SUccess!!")
                setUploading_stats((prev) => ({ ...prev, nicb_loading: false, nicb_complete: true }))

                setTimeout(() => {
                    setUploading_stats((prev) => ({ ...prev, nicb_loading: false, nicb_complete: false }))

                }, 2000);
                get_doc_nic_back()
            }
        } catch (error) {
            console.log("file passport fail !!", error)
        }

    }

    // download file fn 
    const donwload_file_commonn = async (hash, name, cid_ndex) => {
        const cids = await get_cids_fn(cid_ndex)

        try {
            const res = await download_single_file({
                variables: {
                    "batchHash": hash,
                    "fileHash": hash,
                    "fileName": name,
                    "did": did,
                    "cids": cids
                }
            })

            if (res.data) {
                console.log(res.data.downloadFile.URL)
                return res.data.downloadFile.URL
            }
        } catch (error) {
            console.log("downlaod file error,", error)
        }
    }

    // get cids 
    const get_cids_fn = async (file_index) => {
        try {
            const res = await get_file_cids({ variables: { "index": file_index, "did": did } })

            if (res.data) {
                return res.data.getCIDS.cidList
            }
        } catch (error) {
            console.log("cid get error : ", error)
        }
    }

    // get uploads images 
    const get_doc_passport_front = async () => {
        try {
            const res = await get_doc_file({
                variables: {
                    "input": {
                        "doc_id": "Pass-1-Front",
                        "doc_type": "PassportFront",
                        "owner_did": did
                    }
                }
            })
            if (res.data) {
                const donwnload_link = await donwload_file_commonn(res.data.getDocFile.filehash, "passportFront", res.data.getDocFile.merkletree_index)
                setImages_links((prev) => ({ ...prev, passport_f: donwnload_link })) // set download link 
            }
        } catch (error) {

        }
    }

    // get uploads images 
    const get_doc_passport_back = async () => {
        try {
            const res = await get_doc_file({
                variables: {
                    "input": {
                        "doc_id": "Pass-1-Back",
                        "doc_type": "PassportBack",
                        "owner_did": did
                    }
                }
            })
            if (res.data) {
                const donwnload_link = await donwload_file_commonn(res.data.getDocFile.filehash, "PassportBack", res.data.getDocFile.merkletree_index)
                setImages_links((prev) => ({ ...prev, passport_b: donwnload_link })) // set download link 
            }
        } catch (error) {

        }
    }

    // get uploads images 
    const get_doc_nic_front = async () => {
        try {
            const res = await get_doc_file({
                variables: {
                    "input": {
                        "doc_id": "Nic-1-Front",
                        "doc_type": "NicFront",
                        "owner_did": did
                    }
                }
            })
            if (res.data) {
                const donwnload_link = await donwload_file_commonn(res.data.getDocFile.filehash, "NicFront", res.data.getDocFile.merkletree_index)
                setImages_links((prev) => ({ ...prev, nic_f: donwnload_link })) // set download link 
            }
        } catch (error) {

        }
    }

    // get uploads images 
    const get_doc_nic_back = async () => {
        try {
            const res = await get_doc_file({
                variables: {
                    "input": {
                        "doc_id": "Nic-1-Back",
                        "doc_type": "NicBack",
                        "owner_did": did
                    }
                }
            })
            if (res.data) {
                const donwnload_link = await donwload_file_commonn(res.data.getDocFile.filehash, "NicBack", res.data.getDocFile.merkletree_index)
                setImages_links((prev) => ({ ...prev, nic_b: donwnload_link })) // set download link 
            }
        } catch (error) {

        }
    }

    // delete doc files 
    const delete_doc_file = async (type) => {

        let doc_id = null;
        let doc_type = null;

        switch (type) {
            case "pf":
                doc_id = "Pass-1-Front"
                doc_type = "PassportFront"
                break;

            case 'pb':
                doc_id = "Pass-1-Back"
                doc_type = "PassportBack"
                break;

            case 'nicf':
                doc_id = "Nic-1-Front"
                doc_type = "NicFront"
                break;

            case 'nicb':
                doc_id = "Nic-1-Back"
                doc_type = "NicBack"
                break;

            default:
                break;
        }
        try {
            console.log("doc id :", doc_id)
            console.log("doc type :", doc_type)
            const res = await delete_doc_files({
                variables: {
                    "input": {
                        "doc_id": doc_id,
                        "doc_type": doc_type,
                        "owner_did": did
                    }
                }
            })
            if (res.data) {
                console.log("file deleted successful!")

                if (doc_type === "PassportFront") {
                    setImages_links((prev) => ({ ...prev, passport_f: null }))
                }
                if (doc_type === "PassportBack") {
                    setImages_links((prev) => ({ ...prev, passport_b: null }))
                }
                if (doc_type === "NicFront") {
                    setImages_links((prev) => ({ ...prev, nic_f: null }))
                }
                if (doc_type === "NicBack") {
                    setImages_links((prev) => ({ ...prev, nic_b: null }))
                }


            }
        } catch (error) {
            console.log("file deleting error :", error)
        }
    }



    return (
        <div className='flex'>
            <div className={`w-full flex flex-col ${web_theme ? "bg-dashboard-small-card text-white/60" : 'shadow-md border border-l-sidebar-text-lite2/20 '} rounded-[25px] mt-3 p-5`}>
                {/* topic container */}
                <div className={`w-full flex flex-col mt-2 mb-1 ${web_theme ? "text-white" : ""} `}>
                    <div className='ml-2 opacity-50 text-[.9rem] flex flex-col gap-2'>
                        <h3>Document Uploads</h3>
                        <hr className={`${web_theme ? "text-white bg-white opacity-30 h-[1px]" : " text-sidebar-text-lite2 opacity-100 h-[2px]"} w-[99%] `} />
                    </div>
                </div>

                {/* total uploads */}
                <div className='w-full my-2 p-2 flex lg:flex-col md:flex-col sm:flex-col min-[320px]:flex-col gap-4'>

                    {/* passport div */}
                    <button onClick={toggle_passport} className={`flex justify-between items-center w-full rounded-[25px] text-left ${web_theme ? 'bg-dashboard-home-bg1' : 'shadow-sm border border-sidebar-text-lite2/20'} py-2 px-4`}>
                        {/* information div */}
                        <div className=' flex flex-col gap-1'>
                            <h3 className='text-[.8rem]'>&#x2022; Passport Document Uploads </h3>
                            <p className='text-[0.7rem] px-3'>upload your passport documents front and back </p>
                        </div>

                        {/* more information div */}
                        <div>
                            <img src={icon_down_arr} className='w-[20px] mx-2' alt="" />
                        </div>
                    </button>

                    {/* show and hide div passport */}
                    {show_and_hide.passport && <div className={` animate__animated animate__fadeIn animate__faster lg:w-full flex md:w-full sm:w-full min-[320px]:w-full border-r ${web_theme ? 'border-white/10 text-white/20' : 'border-sidebar-text-lite2/50 text-sidebar-text-lite2'} justify-between gap-4 px-4`}>
                        {/* front  */}
                        {images_links.passport_f ?
                            <div className={`relative w-full flex border items-center justify-center ${web_theme ? 'border-white/20' : 'border-sidebar-text-lite2/50'} h-[200px] rounded-[25px] overflow-hidden`}>
                                <div>
                                    <img src={images_links.passport_f && images_links.passport_f} alt="" />
                                </div>

                                {/* reupload button and delete buttons  */}
                                <div className='flex absolute bottom-1 right-1 p-1 gap-1'>

                                    {/* reupload button */}
                                    <button className="bg-black border border-white/20 p-2 rounded-full hover:scale-105 transition-all ease-in-out">
                                        <img src={cameraIcon} className='w-[20px]' alt="" />
                                    </button>

                                    {/* delete button */}
                                    <button onClick={() => delete_doc_file("pf")} className="bg-black border border-white/20 p-2 rounded-full hover:scale-105 transition-all ease-in-out">
                                        <img src={icon_delete} className='w-[20px] opacity-40' alt="" />
                                    </button>
                                </div>
                            </div>
                            :
                            <label htmlFor='passportF' className={`border ${uploading_stats.pf_loading ? 'border-green-500 animate-pulse' : ''} ${uploading_stats.pf_complete ? 'scale-105 border-green-500' : ''} w-full flex items-center justify-center ${web_theme ? 'border-white/20' : 'border-sidebar-text-lite2/50'} h-[200px] rounded-[25px] overflow-hidden`}>
                                <input
                                    onChange={handleDocUploadPassportFront}
                                    id="passportF"
                                    type="file"
                                    multiple
                                    hidden
                                />

                                {uploading_stats.pf_loading ? <div className='flex flex-col justify-center items-center gap-1'>
                                    <div>
                                        <div className='loader_upload'></div>
                                    </div>
                                    <div>
                                        <h3>uploading ... </h3>
                                    </div>
                                </div>
                                    :
                                    <div className='flex flex-col justify-center items-center gap-1'>
                                        <div>
                                            <img src={cameraIcon} className='w-[40px]' alt="" />
                                        </div>
                                        <div>
                                            <h3>Passport Front</h3>
                                        </div>
                                    </div>
                                }

                            </label>
                        }

                        {/* back  */}
                        {images_links.passport_b ?
                            <div className={`relative w-full flex border items-center justify-center ${web_theme ? 'border-white/20' : 'border-sidebar-text-lite2/50'} h-[200px] rounded-[25px] overflow-hidden`}>
                                <div>
                                    <img src={images_links.passport_b && images_links.passport_b} alt="" />
                                </div>

                                {/* reupload button and delete buttons  */}
                                <div className='flex absolute bottom-1 right-1 p-1 gap-1'>

                                    {/* reupload button */}
                                    <button className="bg-black border border-white/20 p-2 rounded-full hover:scale-105 transition-all ease-in-out">
                                        <img src={cameraIcon} className='w-[20px]' alt="" />
                                    </button>

                                    {/* delete button */}
                                    <button onClick={() => delete_doc_file("pb")} className="bg-black border border-white/20 p-2 rounded-full hover:scale-105 transition-all ease-in-out">
                                        <img src={icon_delete} className='w-[20px] opacity-40' alt="" />
                                    </button>
                                </div>

                            </div>
                            :
                            <label htmlFor='passportB' className={`w-full ${uploading_stats.pb_loading ? 'border-green-500 animate-pulse' : ''} flex border items-center justify-center ${web_theme ? 'border-white/20' : 'border-sidebar-text-lite2/50'} h-[200px] rounded-[25px] overflow-hidden`}>
                                <input
                                    onChange={handleDocUploadPassportBack}
                                    id="passportB"
                                    type="file"
                                    multiple
                                    hidden
                                />

                                {uploading_stats.pb_loading ? <div className='flex flex-col justify-center items-center gap-1'>
                                    <div>
                                        <div className='loader_upload'></div>
                                    </div>
                                    <div>
                                        <h3>uploading ... </h3>
                                    </div>
                                </div>
                                    :
                                    <div className='flex flex-col justify-center items-center gap-1'>
                                        <div>
                                            <img src={cameraIcon} className='w-[40px]' alt="" />
                                        </div>
                                        <div>
                                            <h3>Passport Back</h3>
                                        </div>
                                    </div>
                                }

                            </label>
                        }
                    </div>
                    }

                    {/* show NIC div */}
                    <button onClick={toggle_nic} className={`flex justify-between text-left items-center w-full rounded-[25px] ${web_theme ? 'bg-dashboard-home-bg1' : 'shadow-sm border border-sidebar-text-lite2/20'} py-2 px-4`}>
                        {/* information div */}
                        <div className=' flex flex-col gap-1'>
                            <h3 className='text-[.8rem]'>&#x2022; NIC Document Uploads </h3>
                            <p className='text-[0.7rem] px-3'>upload your National Identity Card front and back </p>
                        </div>

                        {/* more information div */}
                        <div>
                            <img src={icon_down_arr} className='w-[20px] mx-2' alt="" />
                        </div>
                    </button>


                    {/*  NIC uploads */}
                    {show_and_hide.NIC &&
                        <div className={`animate__animated animate__fadeIn animate__faster lg:w-full md:w-full sm:w-full min-[320px]:w-full border-r ${web_theme ? 'border-white/10 text-white/20' : 'border-sidebar-text-lite2/50 text-sidebar-text-lite2'} flex justify-between gap-4 px-4`}>

                            {/* NIC front  */}
                            {images_links.nic_f ?
                                <div className={`relative w-full flex border items-center justify-center ${web_theme ? 'border-white/20' : 'border-sidebar-text-lite2/50'} h-[200px] rounded-[25px] overflow-hidden`}>
                                    <div>
                                        <img src={images_links.nic_f && images_links.nic_f} alt="" />
                                    </div>

                                    {/* reupload button and delete buttons  */}
                                    <div className='flex absolute bottom-1 right-1 p-1 gap-1'>

                                        {/* reupload button */}
                                        <button className="bg-black border border-white/20 p-2 rounded-full hover:scale-105 transition-all ease-in-out">
                                            <img src={cameraIcon} className='w-[20px]' alt="" />
                                        </button>

                                        {/* delete button */}
                                        <button onClick={() => delete_doc_file("nicf")} className="bg-black border border-white/20 p-2 rounded-full hover:scale-105 transition-all ease-in-out">
                                            <img src={icon_delete} className='w-[20px] opacity-40' alt="" />
                                        </button>
                                    </div>

                                </div>
                                :
                                <label htmlFor='NICF' className={`w-full ${uploading_stats.nicf_loading ? 'border-green-500 animate-pulse' : ''} flex border items-center justify-center ${web_theme ? 'border-white/20' : 'border-sidebar-text-lite2/50'} h-[200px] rounded-[25px] overflow-hidden`}>
                                    <input
                                        onChange={handleDocUploadNICF}
                                        id="NICF"
                                        type="file"
                                        multiple
                                        hidden
                                    />


                                    {uploading_stats.nicf_loading ? <div className='flex flex-col justify-center items-center gap-1'>
                                        <div>
                                            <div className='loader_upload'></div>
                                        </div>
                                        <div>
                                            <h3>uploading ... </h3>
                                        </div>
                                    </div>
                                        :
                                        <div className='flex flex-col justify-center items-center gap-1'>
                                            <div>
                                                <img src={cameraIcon} className='w-[40px]' alt="" />
                                            </div>
                                            <div>
                                                <h3>NIC Front</h3>
                                            </div>
                                        </div>
                                    }

                                </label>
                            }


                            {/* NIC back  */}
                            {images_links.nic_b ?
                                <div className={`relative w-full flex border items-center justify-center ${web_theme ? 'border-white/20' : 'border-sidebar-text-lite2/50'} h-[200px] rounded-[25px] overflow-hidden`}>
                                    <div>
                                        <img src={images_links.nic_b && images_links.nic_b} alt="" />
                                    </div>

                                    {/* reupload button and delete buttons  */}
                                    <div className='flex absolute bottom-1 right-1 p-1 gap-1'>

                                        {/* reupload button */}
                                        <button className="bg-black border border-white/20 p-2 rounded-full hover:scale-105 transition-all ease-in-out">
                                            <img src={cameraIcon} className='w-[20px]' alt="" />
                                        </button>

                                        {/* delete button */}
                                        <button onClick={() => delete_doc_file("nicb")} className="bg-black border border-white/20 p-2 rounded-full hover:scale-105 transition-all ease-in-out">
                                            <img src={icon_delete} className='w-[20px] opacity-40' alt="" />
                                        </button>
                                    </div>

                                </div>
                                :
                                <label htmlFor='NICB' className={`w-full ${uploading_stats.nicb_loading ? 'border-green-500 animate-pulse' : ''} flex border items-center justify-center ${web_theme ? 'border-white/20' : 'border-sidebar-text-lite2/50'} h-[200px] rounded-[25px] overflow-hidden`}>
                                    <input
                                        onChange={handleDocUploadNICB}
                                        id="NICB"
                                        type="file"
                                        multiple
                                        hidden
                                    />

                                    {uploading_stats.nicb_loading ? <div className='flex flex-col justify-center items-center gap-1'>
                                        <div>
                                            <div className='loader_upload'></div>
                                        </div>
                                        <div>
                                            <h3>uploading ... </h3>
                                        </div>
                                    </div>
                                        :
                                        <div className='flex flex-col justify-center items-center gap-1'>
                                            <div>
                                                <img src={cameraIcon} className='w-[40px]' alt="" />
                                            </div>
                                            <div>
                                                <h3>NIC Back</h3>
                                            </div>
                                        </div>
                                    }

                                </label>}
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default DocumentUpload