import React, { useEffect, useState, forwardRef, useRef } from 'react';
import QRCode from 'react-qr-code'
import 'animate.css';
import loadingGif from "../Images/Animation-gifs/DualRing-1s-200px.gif"
import icon_files_lite from "../Images/icons/icon-files-lite.png"
import icon_threedots_lite from "../Images/icons/icon-threedots-lite.png"
import threeDots from "../Images/icons/threeDots.png";
import batchIcon from "../Images/icons/icon-batch.png";
import { useDispatch, useSelector } from 'react-redux';
import FileCompBlurSlice from '../reducers/filesCompBlurReducer';
import { ethers } from "ethers"
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { GET_CIDS, GET_DOWNLOAD_QR, GET_DOWNLOAD_STATUS, GET_FILE_INDEX, GET_SHARE_FILE_INDEX, GET_SHARE_FILES } from '../queris';
import { DOWNLOAD_SINGLE_FILE, SHARE_FILE, VERIFY_FILE } from '../mutations';

const TableWithMoreButton = forwardRef((props, ref) => {
  React.useImperativeHandle(ref, () => ({
    // getDownloadDetails,
  }));
  const dispatch = useDispatch();
  const web_theme = useSelector((state) => state.webThemeReducer.web_theme)

  const [selectedRow, setSelectedRow] = useState(null);
  const [selectQR, setSelectQR] = useState(null)
  const [dowloadQr, setDownloadQr] = useState(null)
  const [selectedDownload, setSelectedDownload] = useState(null)
  const [qrClaim, setQrClaim] = useState(null);
  const [downloadLink, setDownloadLink] = useState("")
  const [clickedDownloadIndex, setClickedDownloadIndex] = useState(null); //to catch which button was clicked
  const [downloadDetailData, setDonwloadDetailsData] = useState(null)
  const [btnGreen, setBtnGreen] = useState(false)
  const [authenticationWait, setAuthenticationWait] = useState(false)


  // data for the MIDDLE QR
  const [selectIndex, setSelectIndex] = useState("24")
  const [selectedItem, setSelectedItem] = useState(null)
  const [shareDidIndex, setShareDidIndex] = useState(null)

  //vefifiation msg 
  const [shareVerifyClaim, setShareVerifyClaim] = useState({ verifying: false, verifyMsg: '' })
  const [downloadVerify, setDownloadVerify] = useState(false)

  const [isFileDownloading, setIsFileDownloading] = useState(false);
  const [clickedDownloadIndexForHallBatch, setClickedDownloadIndexForHallBatch] = useState(null)
  const [downloadFail, setDownloadFail] = useState(false);



  const did = useSelector((state) => state.DidReducer)
  const blur = useSelector((state) => state.fileBlurReducer)

  const stopIntervalRef = useRef(false)

  //toggle three dots in the download details row
  const handleMoreButtonClick = (index) => {
    setSelectedRow(index === selectedRow ? null : index);
  };

  // issue claim fn
  const IssueClaim = async (index) => {
    setBtnGreen(false)
    dispatch(FileCompBlurSlice.actions.changeBlur("blur-md"))
    setSelectIndex(index)
    const selectedItem = downloadDetailData[index];
    setSelectedItem(selectedItem)
    const fileHash = selectedItem.fileHash
    const batchHash = selectedItem.batchhash

    setQrClaim(JSON.stringify("hey this is dummy data for QR"))
    setSelectQR(index);

    setShareVerifyClaim((prev) => ({ ...prev, verifying: true, verifyMsg: "QR is loading ..." }))

    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner()
    const address = signer.getAddress()

    const Acc = await address

    const hd5HashRes = await fetch(process.env.REACT_APP_BACKEND_URL + "getshareclaim", {
      method: "POST",
      body: JSON.stringify({
        "BatchHash": batchHash,
        "FileHash": fileHash,
        "Did": selectedItem[1],
        "OwnerAddress": Acc
      }
      )
    })
    console.log(hd5HashRes)
    if (hd5HashRes.status === 200) {
      const claimShare = await hd5HashRes.json()
      setQrClaim(claimShare)
      setShareVerifyClaim((prev) => ({ ...prev, verifying: false, verifyMsg: "Please Scan QR Now!" }))
    }
    else {
      try {
        console.log("1")
        const res = await fetch(process.env.REACT_APP_BACKEND_URL + "update-file-claim", {
          method: "POST",
          body: JSON.stringify({
            DID: did,
            BatchHash: selectedItem.BatchHash,
            Owner: Acc
          })
        })

        if (res.status === 201) {
          console.log("2")
          const claim = await res.json()
          console.log(claim)
          setQrClaim(JSON.parse(claim.ClaimQr));
        }
      } catch (e) {
        console.log(e)
      }
    }
  }

  const [get_download_claim] = useLazyQuery(GET_DOWNLOAD_QR) // 

  const [get_download_status] = useLazyQuery(GET_DOWNLOAD_STATUS) // get donwload file status 
  const [get_share_file_index] = useLazyQuery(GET_SHARE_FILE_INDEX)
  const [get_file_cids] = useLazyQuery(GET_CIDS)
  const [verify_file] = useMutation(VERIFY_FILE)
  const [donwload_single_file] = useMutation(DOWNLOAD_SINGLE_FILE)

  // make file verify in SC
  const file_verify_fn = async (batch_hash) => {
    try {
      const file_verify = await verify_file({
        variables: {
          "ownerDid": did,
          "batchHash": batch_hash
        }
      })

      if (file_verify.data) {
        console.log(file_verify.data)
        return true
      }
    } catch (error) {
      console.log("file verification error; ", error)
    }
  }

  // get file index fn
  const get_file_index_fn = async (batch_hash, file_hash) => {
    console.log(file_hash, batch_hash, did)
    try {
      const index = await get_share_file_index({
        variables: {
          "input": {
            "batchhash": batch_hash,
            "did": did,
            "filehash": file_hash
          }
        }
      })

      if (index.data) {
        console.log(index.data.getSharedFile.merkletree_index)
        return index.data.getSharedFile.merkletree_index
      }
    } catch (error) {
      console.log("get file index error", error)
    }
  }

  // get cids 
  const get_cids_fn = async (file_index) => {
    try {
      const res = await get_file_cids({ variables: { "index": file_index, "did": did } })

      if (res.data) {
        return res.data.getCIDS.cidList
      }
    } catch (error) {
      console.log("cid get error : ", error)
    }
  }

  // download single file 
  const download_single_file_fn = async (batchHash, fileHash, fileName, cids) => {

    console.log(cids)
    try {
      const donwload_file = await donwload_single_file({
        variables: {
          "batchHash": batchHash,
          "fileHash": fileHash,
          "fileName": fileName,
          "did": did,
          "cids": cids
        }
      })

      if (donwload_file.data) {
        return donwload_file.data.downloadFile.URL
      }
    } catch (error) {
      console.log("error in download file : ", error)
    }
  }

  //download fields for batch bulk fn
  const downloadFileBulkSingle = async (index) => {

    stopIntervalRef.current = false // set  state for get status 
    setBtnGreen(false);
    setClickedDownloadIndexForHallBatch(null);
    dispatch(FileCompBlurSlice.actions.changeBlur("blur-md"));
    setSelectIndex(index);
    const selectedItemxx = downloadDetailData[index];
    setSelectedItem(selectedItemxx);


    const BatchHash = selectedItemxx.batchhash
    const file_hash = selectedItemxx.filehash
    const file_name = selectedItemxx.filename

    await set_file_name(file_name)

    try {
      // verify file fn
      await file_verify_fn(BatchHash)

      // get download file claim 
      const download_cliam = await get_download_claim({ variables: { "did": did, "batchHash": BatchHash } })
      let session_id = null

      setClickedDownloadIndex(index);
      setSelectedDownload(index === selectedRow ? null : index);

      if (download_cliam.data) {
        console.log(download_cliam.data)
        // get session id
        session_id = download_cliam.data.getDownloadQR.SessionID
        // set download QR data
        setDownloadQr(download_cliam.data.getDownloadQR)
        // recursive fn for zkp verification
        const get_download_status_fn = async () => {
          if (stopIntervalRef.current) return;
          try {
            if (download_cliam.data) {

              const data = await get_download_status({ variables: { "sessionId": session_id } })
              console.log(session_id)

              if (data.data) {

                const file_index = await get_file_index_fn(BatchHash, file_hash) // to get index 

                const file_cids = await get_cids_fn(file_index)

                const download_link = await download_single_file_fn(BatchHash, file_hash, file_name, file_cids)

                console.log(download_link)

                // setCID(download_link);
                setSelectedDownload("24");
                closeQR();
                setAuthenticationWait(false);
                setDownloadLink(download_link);


                setTimeout(async () => {
                  setDownloadLink(download_link);

                  await getFile(download_link) // get file name and downloadable file

                  setFileName(file_name)
                  setBtnGreen(true);
                }, 2000);

                setDownloadVerify(true);
                setIsFileDownloading(false);

                setTimeout(() => {
                  setDownloadVerify(false);
                }, 2000);
              }
              else {
                setTimeout(() => {
                  console.log(session_id)
                  if (stopIntervalRef.current) return;
                  get_download_status_fn(session_id)
                }, 3000);
              }
            }

          } catch (error) {
            console.log(error)
            return false
          }
        }

        get_download_status_fn()
      }


    } catch (error) {
      console.log("download error", error)
      setDownloadFail(true)
      setSelectedDownload("24");
      setIsFileDownloading(false)
      closeQR();
      setAuthenticationWait(false);

      setTimeout(() => {
        setDownloadFail(false)
      }, 3000);
    }
  };


  const [fileName, setFileName] = useState(null)

  const set_file_name = async (fileName) => {
    setFileName(fileName)
  }


  // download the file 
  const getFile = async (download_link) => {
    const response = await fetch(download_link);
    const blob = await response.blob();
    const blobUrl = window.URL.createObjectURL(blob);


    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = fileName

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(blobUrl);

  }

  // close QRs 
  const closeQR = () => {
    setSelectedDownload("24");
    setSelectQR("24")
    dispatch(FileCompBlurSlice.actions.changeBlur(null))
    stopIntervalRef.current = true // set  state for get status 
  }

  // ----------------------------------------------------------------------------- graph QL fn s  -----------------------------------------------------------------------------

  const { data: share_file_data, loading: share_file_loading, error: share_file_error } = useQuery(GET_SHARE_FILES, { variables: { "input": { "did": did } } })

  useEffect(() => {
    if (share_file_data) {
      setDonwloadDetailsData(share_file_data.getAllSharedFiles.shared_files)
      console.log(share_file_data.getAllSharedFiles.shared_files)
    }
  }, [share_file_data, share_file_loading, share_file_error])


  return (
    <div className={`flex flex-col w-full px-2 py-8`}>
      {/* download verify msg */}
      {downloadVerify &&
        <div className='fixed right-2 bottom-3 w-[200px] bg-black/20 border-bethel-green border-[1px]  h-[40px] rounded-sm
             py-2 flex-col flex popup items-center justify-center text-white' id="popup">

          <div className='flex flex-row items-center justify-center gap-x-2'>
            <button className="rounded-full shadow-md">
              <svg viewBox="0 0 24 24" fill="none" className='w-5 ' xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="#aaff00" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M7.75 12L10.58 14.83L16.25 9.17004" stroke="#aaff00" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
            </button>
            <h1 className='text-sm font-sm text-bethel-green '>Download Verified</h1>
          </div>
        </div>
      }

      <div className={`w-full ${blur}`}>
        <div className='w-full overflow-auto lg:overflow-visible'>
          <table className='table w-full text-sm border-separate border-transparent rounded-md border-spacing-2'>
            <tbody>

              {/* start of the download array  */}
              {downloadDetailData && downloadDetailData.map((item, index) => (

                <React.Fragment key={index}>
                  <tr className={`flex ${web_theme ? "bg-dashboard-home-bg1 mt-2 text-white/70" : "shadow-sm border text-sidebar-text-lite2 border-sidebar-text-lite2/10"} items-center justify-between animate__animated animate__fadeIn rounded-[25px] px-4`}>
                    <td className='p-3'>
                      <div className='flex items-center justify-center'>
                        <div className='flex items-center justify-center object-cover w-12 h-12 rounded-full w-full'>
                          <img src={web_theme ? batchIcon : icon_files_lite} alt="" className="w-[20px] ml-4" />
                        </div>
                        <button className='w-full '>

                          <div className='ml-3 w-[250px] flex flex-col items-start justify-start'>
                            <div>{item.filename && item.filename.length > 20 ? item.filename.slice(0, 20) + " ..." : item.filename}</div>
                            <div className='w-full flex text-[9px]'>
                              <h3>Shared By : {item.owner_did.slice(0, 10) + " . . . " + item.owner_did.slice(40)}</h3>
                            </div>
                          </div>
                        </button>
                      </div>


                    </td>

                    <td className='flex flex-row p-3 gap-2 '>

                      {/*end file download button */}
                      <div className='w-[50px] flex items-end justify-end mr-2 gap-1'>
                        {/* <h3>bytes</h3> */}
                      </div>
                      <div className='relative'>

                        {/* {true ? (
                          <button onClick={() => IssueClaim(index)} className="cursor-pointer rounded-md flex relative w-full bg-green-600 px-[1px] py-[1px] hover:bg-green-700">
                            <span className=" flex text-[12px] items-center justify-center font-bold text-white w-full py-1 px-1 font-raleway text-center">
                              Verify
                            </span>
                          </button>) : (<div></div>)} */}
                      </div>


                      {/* file download button  */}
                      {/* issude bool value goes here */}
                      {true ? (
                        <div className='relative'>

                          {/* check the download link is here or not */}
                          {clickedDownloadIndex === index && btnGreen ?
                            (
                              <button className="cursor-pointer flex relative w-full rounded-[25px] bg-bethel-green xs:w-full hover:text-white px-3 py-1">
                                <div className=" button-container w-full">
                                  <span className=" flex items-center justify-center font-bold text-black hover:text-white font-raleway">
                                    <a href={downloadLink} download={fileName} target='_blank' rel='noreferrer'>Download</a>
                                  </span>
                                </div>
                              </button>
                            )
                            :
                            (<button onClick={() => downloadFileBulkSingle(index)} className="cursor-pointer flex relative w-full rounded-[25px] hover:scale-105 bg-red-500 xs:w-full hover:text-white px-3 py-1">
                              <div className=" button-container w-full">
                                <span className=" flex items-center justify-center font-bold text-black hover:text-white font-raleway">
                                  Download
                                </span>
                              </div>
                            </button>)}
                          {/*end of check the download link is here or not */}


                        </div>) : (<div></div>)}

                      <button className='hidden' onClick={() => handleMoreButtonClick(index)}>
                        <img src={web_theme ? threeDots : icon_threedots_lite} alt="" className="w-[20px]" />
                      </button>
                    </td>

                  </tr>

                  {/* claim share list */}
                  {
                    shareDidIndex === index &&
                    <div className='animate__animated animate__fadeInDown animate__faster w-full  bg-gray-800/40 px-10 flex flex-col justify-between p-2 mb-2'>
                      <div className='w-full flex flex-col justify-between items-center'>

                        {/* share claim show here */}
                        <div className='w-full'>
                          <h3>Shared by :</h3>
                          <h3>{downloadDetailData[index] && downloadDetailData[index].owner_id.substring(0, 10) + " ... " + downloadDetailData[index].owner_id.substring(50)}</h3>
                        </div>
                      </div>
                    </div>
                  }
                  {/* end of the claim list */}
                  {/* end of the claim list */}

                  {selectedRow === index && (
                    <tr className="menu-row relative z-[2000]">
                      <td colSpan="3">
                        <div className="menu">
                          {/* Add your unique menu content here */}
                          <div className='relative'>
                            <div className='absolute right-2 top-[-10px] flex flex-col py-5 bg-gray-800 gap-y-4 w-[130px] items-start pl-2 rounded-md justify-center'>
                              {/* reissue button funcs */}
                              {item.issue_claim ? (
                                <button disabled onClick={() => IssueClaim(index)} className='flex flex-row gap-x-4 hover:bg-bethel-green/50 w-full p-1'>
                                  <div className='flex items-center justify-center w-6 h-6'>
                                    <svg fill="#ffffff" className='w-5 h-5' viewBox="0 0 32 32" id="Outlined" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <title></title> <g id="Fill"> <path d="M25,22a4,4,0,0,0-3.26,1.69l-11-6.4A4,4,0,0,0,11,16a4.14,4.14,0,0,0-.1-.87L22,8.65A4,4,0,1,0,21,6a4.14,4.14,0,0,0,.1.87L10,13.35A4,4,0,1,0,7,20a4,4,0,0,0,2.66-1L21,25.6c0,.13,0,.26,0,.4a4,4,0,1,0,4-4ZM25,4a2,2,0,1,1-2,2A2,2,0,0,1,25,4ZM7,18a2,2,0,1,1,2-2A2,2,0,0,1,7,18ZM25,28a2,2,0,1,1,2-2A2,2,0,0,1,25,28Z"></path> </g> </g></svg>
                                  </div>
                                  {/* share button div */}
                                  <div className='relative '>
                                    <button ><h3 className='text-white'>Re-Issue</h3></button>
                                  </div>

                                </button>) : (<div className='absolute'></div>)}
                            </div>
                            <div className='w-0 h-0 absolute right-3 top-[-20px] rotate-[180deg]
                        border-l-[10px] border-l-transparent
                        border-t-[15px] border-t-gray-800
                        border-r-[10px] border-r-transparent'></div>
                          </div>

                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}


            </tbody>
          </table>
        </div>
      </div>

      {/* the Middle issue claim QR div */}
      {selectQR === selectIndex ? (
        <div className='z-[10] flex bg-red-400 h-auto fixed top-1/3 lg:left-1/2 md:left-1/3 sm:left-1/3 transform -translate-x-1/2 -translate-y-1/3'>
          <button onClick={closeQR} className='absolute text-white -top-[25px] left-0 z-[100px]'>
            <h3 className='z-[100] w-[28px] h-[25px] bg-red-600'>x</h3>
          </button>
          <div className='p-4 bg-white absolute z-[1000]'>
            <h3 className='flex w-full text-bold justify-center text-[14px]'> -Issue Claim-</h3>
            {shareVerifyClaim.verifying ? <img src={loadingGif} alt="" className='w-[25px] absolute top-[35px] left-16' /> : ''}
            <h3 className='flex w-full text-bold justify-center text-center text-[14px] text-bethel-green mb-1'>{shareVerifyClaim && shareVerifyClaim.verifyMsg}</h3>
            <QRCode
              value={qrClaim}
              className='flex w-128 h-128 p-1 bg-white top-0 relative ' />
          </div>

        </div>
      ) : (<div></div>)
      }

      {/* middle DOnwload QR  */}
      {selectedDownload === selectIndex ? (
        <div className='flex fixed lg:left-[675px] lg:top-[220px] md:left-[325px] md:top-[250px] sm:left-[175px] sm:top-[250px] min-[320px]:left-[25px] min-[320px]:top-[250px]'>
          <button onClick={closeQR} className='z-[100] w-[28px] h-[25px] bg-red-600'>
            <h3 className='text-white z-[100] w-[28px] h-[25px] bg-red-600'>x</h3>
          </button>
          <div className='p-4 bg-white flex flex-col w-full justify-center items-center'>
            <h3 className='flex w-full text-bold justify-center text-[14px]'> -Download Claim For-</h3>
            {/* <h3 className='flex w-full text-bold justify-center text-[14px] text-red-600'></h3> */}

            <QRCode
              value={JSON.stringify(dowloadQr)}
              className='flex w-128 h-128 p-1 bg-white top-0 relative z-[200]' />

            {authenticationWait && <div className='flex '>
              <h3>Downloading...</h3>
              <img src={loadingGif} alt="" className='w-[30px]' />
            </div>}
          </div>

        </div>
      ) : (<div></div>)
      }
    </div>

  );
});

export default TableWithMoreButton

