import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import verifiedIcon from "../Images/icons/icons-verification.png"
import icon_fail from "../Images/icons/icon-fail.png"
import icon_pen from "../Images/icons/icon-pen.png"
import icon_save from "../Images/icons/icon-save.png"
import icon_done from "../Images/icons/icon-newdone.png"
import icon_camera from "../Images/icons/icon-camera.png"
import ReactFlagsSelect from "react-flags-select";
import { useMutation, useQuery } from '@apollo/client'
import icon_email from "../Images/icons/icon-email-new.png"
import icon_bethel from "../Images/BG-Images/bethel-new-logo.png"
import { CONFIRM_EMAIL_VERIFICATION, UPDATE_PROFILE_DATA, UPLOAD_PROFILE_PICTURE, VERIFY_EMAIL } from '../mutations'
import { GET_ACTIVATE_PACKAGE_ALL_DETAILS, GET_EMAIL_VERIFICATION_STATUS, GET_PROFILE_DATA } from '../queris'
import platinum_icon from "../Images/icons/platinum-icon.png"
import { convert_time_stamps, parseDate } from '../store/helperFucntions'
import DocumentUpload from './Cards/DocumentUpload'


export default function DashboardProfile() {
  const Udid = useSelector((state) => state.DidReducer)
  const avalibilityData = useSelector((state) => state.requireAvalibilityReducer)
  const web_theme = useSelector((state) => state.webThemeReducer.web_theme)
  const countries = require('../json/countryList.json')

  // states 
  const [toggle_edit, setToggle_edit] = useState(false)
  const [selected, setSelected] = useState("");
  const [country_code, setCountry_code] = useState(null)
  const [profileStatus, setProfileStatus] = useState({ is_loading: false, success: false, fail: false })
  const [profile_pic_stats, setProfile_pic_stats] = useState(false)

  const [referral_stats, setReferral_stats] = useState("Platinum") // referral status 

  const [pakage_details, setPackage_details] = useState({ activate_date: null, expire_date: null, package_name: null, payment_method: null, remaining_days: null })

  // input fields 
  const [input_fields, setInput_fields] = useState({ firstName: '', lastName: '', country: '', code: '', contactNumber: '', email: '', profile_img: null })
  const [email_verification_stats, setEmail_verification_stats] = useState({ is_loading: false, is_complete: false, is_fail: false, confirm_loading: false, confirm_complete: false, confirm_fail: false, verified: false })

  useEffect(() => {
    verifyEmail()
  }, [])

  // email verified 
  const [verified, setVerified] = useState({ email: false, mobile: false })


  // get email verify or not 
  const verifyEmail = async () => {
    try {
      const res = await fetch(process.env.REACT_APP_BACKEND_URL + `get-email-verify?OwnerDid=${Udid}`)
      const verifyData = await res.json()
      setVerified((prev) => ({ ...prev, email: verifyData.is_verified }))

      const res2 = await fetch(process.env.REACT_APP_BACKEND_URL + `get-phone-number-verify?OwnerDid=${Udid}`)
      const verifyData2 = await res2.json()
      setVerified((prev) => ({ ...prev, mobile: verifyData2.verified }))
    } catch (error) {
      console.log(error)
    }
  }

  // set toggle functions 
  const toggle_edit_fn = () => {
    setToggle_edit(!toggle_edit)
  }

  // handle submit form
  const handle_change = (code) => {
    setSelected(code)
    countries.map((country) => {
      if (country.iso === code) {
        console.log(country)
        setCountry_code(country)
        setInput_fields((prev) => ({ ...prev, code: country.code, country: country.country }))
      }
    })
  }

  // submit profile form
  const submit_form = async () => {

    setProfileStatus((prev) => ({ ...prev, is_loading: true }))
    console.log("submit form is loading ")
    console.log(input_fields)

    try {
      const res = await update_profile_data({
        variables: {
          "input": {
            "did": Udid,
            "updates": {
              "account_type": null,
              "address_1": null,
              "address_2": null,
              "city": null,
              "company_name": null,
              "company_reg_no": null,
              "country": input_fields.country,
              "country_code": input_fields.code,
              "description": null,
              "email": input_fields.email,
              "first_name": input_fields.firstName,
              "last_name": input_fields.lastName,
              "phone_number": input_fields.contactNumber.toString(),
              "postal_code": null,
              "state": null
            }
          }
        }
      })
      if (res.data) {
        setProfileStatus((prev) => ({ ...prev, is_loading: false, success: true }))

        refetch_profile_data()

        setTimeout(() => {
          setProfileStatus((prev) => ({ ...prev, is_loading: false, success: false }))
        }, 1000);

        console.log(res.data)

      }
      setToggle_edit(false)
    } catch (error) {
      setProfileStatus((prev) => ({ ...prev, is_loading: false, success: false, fail: true }))

      setTimeout(() => {
        setProfileStatus((prev) => ({ ...prev, is_loading: false, fail: false }))
      }, 1000);
    }


  }

  const [imageSrc, setImageSrc] = useState("");


  // convert image to svg
  useEffect(() => {
    if (input_fields.profile_img) {
      // Flatten the byte chunks into a single array
      // const combinedByteArray = new Uint8Array(input_fields.profile_img.flat());

      // Create a Blob from the combined byte array
      // const blob = new Blob([combinedByteArray], { type: 'image/png' });

      // Create an object URL for the Blob
      // const imageUrl = URL.createObjectURL(blob);
      const convert_img = "data:image/png;base64," + input_fields.profile_img

      // Create an image element and set the object URL as the source
      setImageSrc(convert_img)
    }
  }, [input_fields.profile_img])




  // ---------------------------------------------  mutation functions ---------------------------------------------

  const [update_profile_data] = useMutation(UPDATE_PROFILE_DATA)
  const { data: profile_data, loading: profile_loading, error: profile_error, refetch: refetch_profile_data } = useQuery(GET_PROFILE_DATA, { variables: { did: Udid } })
  const { data: email_verify, loading: email_verify_loading, error: email_verify_error, refetch: refetch_email_verificaton } = useQuery(GET_EMAIL_VERIFICATION_STATUS, { variables: { did: Udid } })
  const { data: activate_pkg_details, loading: activate_pkg_loading, error: activate_pkg_error } = useQuery(GET_ACTIVATE_PACKAGE_ALL_DETAILS, { variables: { "input": { "did": Udid } } })

  const [verify_email_gql] = useMutation(VERIFY_EMAIL)
  const [confirm_email_gql] = useMutation(CONFIRM_EMAIL_VERIFICATION)
  const [upload_profile_pic] = useMutation(UPLOAD_PROFILE_PICTURE)

  // get profile data
  useEffect(() => {
    if (profile_data) {
      setInput_fields((prev) => ({ ...prev, firstName: profile_data.getUserProfile.firstName, lastName: profile_data.getUserProfile.lastName, code: profile_data.getUserProfile.countryCode, country: profile_data.getUserProfile.country, contactNumber: profile_data.getUserProfile.phoneNumber, email: profile_data.getUserProfile.email, profile_img: profile_data.getUserProfile.profileImage }))

    }
    if (profile_loading) {
      console.log("profile data is loading")
    }
    if (profile_error) {
      console.log("profile error data", profile_error.message)
    }
  }, [profile_data, profile_loading, profile_error])

  // get email verification status
  useEffect(() => {
    if (email_verify) {
      setEmail_verification_stats((prev) => ({ ...prev, verified: email_verify.getEmailVerificationStatus.isVerified }))
    }
  }, [email_verify, email_verify_loading, email_verify_error])

  // get activate pkg details
  useEffect(() => {
    if (activate_pkg_details) {
      const data = activate_pkg_details.getActivatePackageDetails.Ok
      const active_data = parseDate(convert_time_stamps(data.createTime))
      const expire_date = parseDate(convert_time_stamps(data.expireTime))

      const time_dif = expire_date - active_data
      const daysLeft = Math.ceil(time_dif / (1000 * 60 * 60 * 24));

      setPackage_details((prev) => ({ ...prev, package_name: data.packageName, activate_date: data.createTime, expire_date: data.expireTime, payment_method: data.paymentMethod, remaining_days: daysLeft }))

    }
    if (activate_pkg_error) {
      console.log("activate package get details error :", activate_pkg_error.message)
    }
  }, [activate_pkg_details, activate_pkg_error, activate_pkg_loading])

  // email verification
  const verify_email = async (e) => {
    e.preventDefault()
    console.log(input_fields.email)

    // set stats
    setEmail_verification_stats((prev) => ({ ...prev, is_loading: true }))
    try {
      const res_email = await verify_email_gql({
        variables: {
          "input": {
            "did": Udid,
            "userEmail": input_fields.email
          }
        }
      })

      if (res_email.data) {
        console.log(res_email.data)
        setEmail_verification_stats((prev) => ({ ...prev, is_loading: true, is_complete: true }))
      }
    } catch (error) {
      console.log("email verification error:", error)
      setEmail_verification_stats((prev) => ({ ...prev, is_loading: false, is_complete: false }))
    }
  }

  const [emailCode, setEmailCode] = useState(null)

  // confirm email verification
  const confirm_email_verification_fn = async () => {

    setEmail_verification_stats((prev) => ({ ...prev, confirm_loading: true }))
    try {
      const res_confirm = await confirm_email_gql({
        variables: {
          "token": emailCode
        }
      })

      if (res_confirm.data) {
        console.log(res_confirm.data)
        setEmail_verification_stats((prev) => ({ ...prev, confirm_loading: false, is_complete: false, confirm_complete: true }))
        refetch_email_verificaton() // update verification stats

        setTimeout(() => {
          setEmail_verification_stats((prev) => ({ ...prev, confirm_loading: false, is_complete: false, confirm_complete: false }))
        }, 3000);
      }
    } catch (error) {
      console.log("verification email error;", error)
      setEmail_verification_stats((prev) => ({ ...prev, is_loading: false, confirm_loading: false, is_complete: false, confirm_complete: false, confirm_fail: true }))

      setTimeout(() => {
        setEmail_verification_stats((prev) => ({ ...prev, confirm_fail: false }))

      }, 3000);

    }
  }

  // handle file upload
  const handleProfileUpload = async (e) => {
    const pro_pic = e.target.files[0]
    console.log(pro_pic)

    try {
      const upload_pic = await upload_profile_pic({
        variables: {
          "did": Udid,
          "file": pro_pic
        }
      })

      if (upload_pic.data) {
        console.log("profile pic data upload :", upload_pic.data)
        setProfile_pic_stats(true)

        refetch_profile_data()

        setTimeout(() => {
          setProfile_pic_stats(false)
        }, 2000);
      }

    } catch (error) {
      console.log("profile pic upload fail :", error)
    }
  }

  return (
    <section className={`relative h-auto w-full flex flex-col pr-4 justify-center items-center ${!avalibilityData.allreadyRegisteredWallet && !avalibilityData.userAdded && !avalibilityData.noUser ? "" : ""}`}>

      {/* new profile card section */}
      <div className={`${web_theme ? 'bg-dashboard-home-bg1' : 'bg-sidebar-bg-lite text-sidebar-text-lite2'} w-full rounded-[25px] p-4`}>

        {/* topic container */}
        <div className={`flex flex-col mt-2 mb-1 ${web_theme ? "text-white" : ""}`}>
          <div className='ml-2 opacity-50 text-[.9rem] flex flex-col gap-2'>
            <h3>Profile</h3>
            <hr className={`${web_theme ? "text-white bg-white opacity-30 h-[1px]" : " text-sidebar-text-lite2 opacity-100 h-[2px]"} w-[99%] `} />
          </div>
        </div>

        {/* content profile */}
        <div className={`w-full my-5`}>
          {/* cover page div */}
          <div className='w-full rounded-[25px] overflow relative z-[50]'>
            {/* <div className='w-full overflow-hidden rounded-[25px] '>
              <img src={cover_img} className='w-full opacity-10 bg-blend-overlay' alt="" />
            </div> */}

            {/* profile img */}
            <div className={`w-[200px] mx-5 ${profile_pic_stats ? 'border-8 bg-white border-green-800 scale-110' : 'border-4 bg-white border-bethel-green'} shadow-md h-[200px] rounded-full overflow-auto z-[100]  flex justify-center items-center`}>
              <div className='w-full '>
                {input_fields.profile_img ? <img src={imageSrc} alt='pro_pic' className='w-full' /> : <img src={icon_bethel} className='w-full' alt="" />}
              </div>
            </div>

            {/* image upload icon */}
            <label htmlFor="file-types" className='cursor-pointer hover:scale-105 absolute hover:bg-bethel-green bottom-[-10px] left-[100px] z-[200] p-2 rounded-full bg-white border-2 border-bethel-green '>
              <img src={icon_camera} className='w-[25px]' alt="" />
            </label>

            <input
              onChange={handleProfileUpload}
              id="file-types"
              type="file"
              hidden
            />

            {/* image content section */}
            <div className={`${web_theme ? 'text-white text-[2rem] font-bold tracking-wide' : 'text-sidebar-text-lite2 text-[2rem] font-bold tracking-wide'} absolute left-[250px] bottom-[50px] flex flex-col`}>
              <h1 className='opacity-75 text-[1.9rem]'>{input_fields.firstName === '' ? 'New User' : input_fields.firstName + " " + input_fields.lastName}</h1>
              <h3 className='opacity-50 text-[1.5rem]'>{input_fields.email === '' ? 'newuseremail@bethel.com' : input_fields.email}</h3>
            </div>
          </div>

        </div>

        {/* profile information main div */}
        <div className='w-full flex px-1 mt-10 gap-4  lg:flex-row md:flex-col sm:flex-col min-[320px]:flex-col'>

          {/* profile information card */}
          <div className={`w-full flex flex-col rounded-[25px] p-3 ${web_theme ? 'bg-dashboard-small-card' : 'shadow-md border'} h-auto relative ${toggle_edit ? 'border border-bethel-green' : ''}`} >
            {/* topic container */}
            <div className={`flex flex-col mt-2 mb-1 ${web_theme ? "text-white font-bold tracking-wide" : "text-sidebar-text-lite2 font-bold tracking-wide"} mt-4`}>
              <div className='ml-2 opacity-50 text-[1rem] flex flex-col gap-2 transition-all ease-in-out'>
                {toggle_edit ? <h3 className={`${web_theme ? "text-bethel-green animate-glow  rounded-[25px] p-2 opacity-100" : 'text-sidebar-text-lite2 shadow-md p-2 opacity-100'}`}>Edit Profile Information</h3> : <h3>Profile Information</h3>}
                <hr className={`${web_theme ? "text-white bg-white opacity-50 h-[1px]" : " text-black bg-sidebar-text-lite2 opacity-40 h-[2px]"} w-[99%] `} />
              </div>
            </div>

            {/* form section */}
            <form action="" className={`w-full ${web_theme ? 'text-white' : 'text-sidebar-text-lite2 '} p-2 mt-2 gap-4 flex flex-col `}>
              <div className='w-full flex flex-col p-1 gap-2'>
                <label htmlFor="" className='text-[.8rem] opacity-60'>First Name</label>
                <input disabled={!toggle_edit} onChange={(e) => setInput_fields((prev) => ({ ...prev, firstName: e.target.value }))} defaultValue={input_fields.firstName}
                  type="text" className={`${web_theme ? 'text-white ' : 'text-sidebar-text-lite2 shadow-sm border border-sidebar-text-lite2/40'} text-[.8rem] outline-none ${toggle_edit ? 'border-b border-bethel-green/100' : 'opacity-70  border-b'} px-4 py-3 rounded-[25px] bg-transparent`} />
              </div>

              <div className='w-full flex flex-col p-1 gap-2'>
                <label htmlFor="" className='text-[.8rem] opacity-60'>Last Name</label>
                <input disabled={!toggle_edit} type="text" onChange={(e) => setInput_fields((prev) => ({ ...prev, lastName: e.target.value }))} defaultValue={input_fields.lastName}
                  className={`${web_theme ? 'text-white ' : 'text-sidebar-text-lite2 shadow-sm border border-sidebar-text-lite2/40'} text-[.8rem] outline-none ${toggle_edit ? 'border-b border-bethel-green/100' : 'opacity-70  border-b'} px-4 py-3 rounded-[25px] bg-transparent`} />
              </div>

              <div className='w-full flex flex-col p-1 gap-2'>
                <label htmlFor="" className='text-[.8rem] opacity-60'>Country</label>
                <ReactFlagsSelect className={`${web_theme ? "text-sidebar-bg-dark" : ''} rounded-[25px]`}
                  selected={selected}
                  placeholder={input_fields.country}
                  disabled={!toggle_edit}
                  onSelect={handle_change}
                  searchable
                />
              </div>

              <div className='w-full flex flex-col p-1 gap-2'>
                <label htmlFor="" className='text-[.8rem] opacity-60'>Contact Number</label>
                <div className='flex gap-1 w-full'>
                  <input disabled type="text" className={`${web_theme ? 'text-white ' : 'text-sidebar-text-lite2 shadow-sm border border-sidebar-text-lite2/40'} text-[.8rem] outline-none ${toggle_edit ? 'border-b border-bethel-green/100' : 'opacity-70  border-b'} px-4 py-3 rounded-[25px] bg-transparent w-[15%] placeholder:text-center`} placeholder={country_code && "+ " + country_code.code} defaultValue={input_fields.code} />
                  <input disabled={!toggle_edit} onChange={(e) => setInput_fields((prev) => ({ ...prev, contactNumber: e.target.value }))} defaultValue={input_fields.contactNumber}
                    type="number" className={`${web_theme ? 'text-white ' : 'text-sidebar-text-lite2 shadow-sm border border-sidebar-text-lite2/40'} text-[.8rem] outline-none ${toggle_edit ? 'border-b border-bethel-green/100' : 'opacity-70  border-b'} px-4 py-3 rounded-[25px] bg-transparent w-[85%]`} />
                </div>

              </div>

              <div className='w-full flex flex-col p-1 gap-2 relative'>
                <label htmlFor="" className='text-[.8rem] opacity-60'>Email </label>
                <input disabled={!toggle_edit || email_verification_stats.verified} type="text" onChange={(e) => setInput_fields((prev) => ({ ...prev, email: e.target.value }))} defaultValue={input_fields.email}
                  className={`${web_theme ? 'text-white ' : 'text-sidebar-text-lite2 shadow-sm border border-sidebar-text-lite2/40'} text-[.8rem] outline-none ${toggle_edit ? 'border-b border-bethel-green/100' : 'opacity-70  border-b'} px-4 py-3 rounded-[25px] bg-transparent`} />

                {/* verification button  */}
                {(!email_verification_stats.verified && !toggle_edit) &&  <div className='flex w-full'>
                  {!email_verification_stats.is_loading && input_fields.email !== "" && <button onClick={verify_email} className='flex w-[80px] right-3 top-10 rounded-[25px] hover:text-sidebar-text-lite2 bg-bethel-green text-black absolute text-[.8rem] justify-center p-1 font-bold hover:scale-105 '>
                    <h3>Verify</h3>
                  </button>}

                  {email_verification_stats.is_loading && <div className='loader_upload right-3 top-10  absolute '></div>}
                </div>}

                {email_verification_stats.verified &&
                  <div className='flex w-[80px] opacity-80 -right-2 top-8 rounded-[25px]  text-black absolute text-[.8rem] justify-center p-1 font-bold hover:scale-105 '>
                    <img src={icon_done} className='w-[30px]' alt="" />
                  </div>
                }

              </div>

            </form>

            {/* edit button */}
            <button onClick={toggle_edit_fn} className={`flex absolute top-4 right-3 p-1 rounded-full ${web_theme ? 'bg-dashboard-home-bg1 text-sidebar-text-lite2 hover:bg-bethel-green' : 'bg-sidebar-bg-lite shadow-sm border border-sidebar-text-lite2/10 text-sidebar-text-lite2 hover:bg-bethel-green'} px-4 text-[0.8rem]`}>
              <div className='flex gap-1 justify-center items-center'>
                <div className='w-full'>
                  <img src={icon_pen} className='flex w-[15px]' alt="" />
                </div>

                <div>
                  <h3>edit</h3>
                </div>
              </div>
            </button>

            {/* save button */}
            {toggle_edit &&
              <button onClick={submit_form} className={`flex absolute top-4 right-[90px] p-1 rounded-full ${web_theme ? 'bg-dashboard-home-bg1 text-sidebar-text-lite2 hover:bg-bethel-green' : 'bg-sidebar-bg-lite shadow-sm border border-sidebar-text-lite2/10 text-sidebar-text-lite2 hover:bg-bethel-green'} px-4 text-[0.8rem]`}>
                <div className='flex gap-1 justify-center items-center'>
                  <div className='w-full'>
                    <img src={icon_save} className='flex w-[15px]' alt="" />
                  </div>

                  <div>
                    <h3>save</h3>
                  </div>
                </div>
              </button>
            }

          </div>


          {/* other information card */}
          <div className={`w-full flex flex-col rounded-[25px] p-3 ${web_theme ? 'bg-dashboard-small-card' : 'shadow-md border'} h-auto`} >
            {/* topic container */}
            <div className={`flex flex-col mt-2 mb-1 ${web_theme ? "text-white font-bold tracking-wide" : "text-sidebar-text-lite2 font-bold tracking-wide"} mt-4`}>
              <div className='ml-2 opacity-50 text-[1rem] flex flex-col gap-2'>
                <h3>Other Information</h3>
                <hr className={`${web_theme ? "text-white bg-white opacity-50 h-[1px]" : " text-black bg-sidebar-text-lite2 opacity-40 h-[2px]"} w-[99%] `} />
              </div>
            </div>

            {/* other information content */}
            <div className='w-full flex flex-col p-4 gap-10'>

              <div className={`${web_theme ? 'text-white ' : 'text-sidebar-text-lite2'} flex items-center justify-between px-1`}>
                <div className='flex flex-col gap-1 w-3/4'>
                  <h1 className='text-[.9rem] opacity-75 font-bold tracking-wide'>&#x2022; KYC Verification</h1>
                  <h3 className='text-[.8rem] px-4 opacity-50'>make sure you can eligible for the monthly subscription </h3>
                </div>

                <div className='flex items-center justify-end w-1/4 z-[200]'>
                  <img src={icon_fail} className='w-[30px]' alt="" />
                </div>
              </div>

              <div className={`${web_theme ? 'text-white ' : 'text-sidebar-text-lite2'} flex items-center justify-between px-1`}>
                <div className='flex flex-col gap-1 w-3/4'>
                  <h1 className='text-[.9rem] opacity-75 font-bold tracking-wide'>&#x2022; Email Verification</h1>
                  <h3 className='text-[.8rem] px-4 opacity-50'>make sure your email verified or not  </h3>
                </div>

                <div className='flex items-center justify-end w-1/4 z-[200]'>
                  {email_verification_stats.verified ? <img src={verifiedIcon} className='w-[30px]' alt="" /> : <img src={icon_fail} className='w-[30px]' alt="" />}
                </div>
              </div>

              <div className={`${web_theme ? 'text-white ' : 'text-sidebar-text-lite2'} flex items-center justify-between px-1`}>
                <div className='flex flex-col gap-1 w-3/4'>
                  <h1 className='text-[.9rem] opacity-75 font-bold tracking-wide'>&#x2022; Payment Method</h1>
                  <h3 className='text-[.8rem] px-4 opacity-50'>active package payment method </h3>
                </div>

                <div className={`flex items-center justify-end w-1/4 z-[200] text-[1rem] ${web_theme ? 'text-white/60' : 'text-sidebar-text-lite2'} font-bold`}>
                  <h3>{pakage_details.payment_method === 'WalletPurchase' ? "By Wallet" : "In App"}</h3>
                </div>
              </div>

              <div className={`${web_theme ? 'text-white ' : 'text-sidebar-text-lite2'} flex items-center justify-between px-1`}>
                <div className='flex flex-col gap-1 w-3/4'>
                  <h1 className='text-[.9rem] opacity-75 font-bold tracking-wide'>&#x2022; Plan status ( {pakage_details.package_name === 'Basic' ? 'Trial Plan' : pakage_details.package_name} ) </h1>
                  <h3 className='text-[.8rem] px-4 opacity-50'>plan status that expired date and time limit </h3>
                </div>

                <div className={`flex items-center ${web_theme ? 'white' : 'text-sidebar-text-lite2'} text-[.8rem] justify-end w-1/4 z-[200]`}>
                  <div><h3><span className={`${web_theme ? 'text-bethel-green' : 'text-sidebar-text-lite2 font-bold'} text-[1.5rem] font-bold`}>{pakage_details.remaining_days && pakage_details.remaining_days}</span> days left</h3></div>
                </div>
              </div>

              <div className={`${web_theme ? 'text-black bg-bethel-green' : 'text-sidebar-text-lite2 bg-sidebar-hover-dark'} flex items-center justify-between py-3 px-4 rounded-[25px] animate-pulse`}>
                <div className='flex flex-col gap-1 w-3/4'>
                  <h1 className='text-[.9rem] opacity-75 font-bold tracking-wide'>&#x2022; Referral Status ({referral_stats})</h1>
                  <h3 className='text-[.8rem] px-4 opacity-50'>Rank given according to the referral count in subscriptions. </h3>
                </div>

                <div className='flex items-center justify-end w-1/4 z-[200]'>
                  <img src={platinum_icon} className='w-[35px]' alt="" />
                </div>
              </div>

            </div>

          </div>
        </div>


        {/* document uploads */}
        <DocumentUpload />


        {email_verification_stats.is_complete &&
          <div className={`fixed flex w-[400px] h-auto rounded-t-[25px] bottom-0 right-2 p-2 flex-col animate__animated animate__fadeInUp z-[1000]
        ${web_theme ? 'bg-black text-white' : 'bg-sidebar-bg-lite shadow-md text-sidebar-text-lite2 border border-sidebar-text-lite2/20'}
        `}>
            {/* title section div */}
            <div className="w-full py-4 px-4 text-[.9rem] flex justify-between ">
              <div className="font-normal tracking-wide animate-pulse flex items-center gap-2">
                <img src={icon_email} className='w-[25px]' alt="" />
                <h3>verification code sent to your inbox</h3>
              </div>
            </div>

            {/* files loop */}
            <div className={`flex text-[.8rem] gap-2 flex-col p-2 ${web_theme ? 'bg-white/10' : 'bg-black/10 shadow-sm'} rounded-t-[25px] h-auto overflow-scroll`} >
              <div className="w-full flex py-1 px-2 opacity-60 justify-between gap-2 items-center">
                <div className='w-full'>
                  <input onPaste={(e) => setEmailCode(e.target.value)} onChange={(e) => setEmailCode(e.target.value)} type='text' className={`text-[.9rem] outline-none  border-b ${web_theme ? 'border-bethel-green' : 'border-sidebar-text-lite2'} w-full p-1 rounded-[25px] bg-transparent px-3`} />
                </div>
                <button onClick={confirm_email_verification_fn} className='flex w-[80px] rounded-[25px] hover:text-sidebar-text-lite2 bg-bethel-green/100 text-black  text-[.8rem] justify-center p-1 font-bold hover:scale-105'>
                  Submit
                </button>
              </div>


            </div>

          </div>
        }

        {email_verification_stats.is_fail && (
          <div
            className="fixed right-2 bottom-1 w-[250px] z-[200] bg-red-900 border border-red-600 shadow-md  h-[40px] rounded-t-[25px]
             py-2 flex-col flex popup items-center justify-center text-red-500 animate__animated animate__fadeInUp animate__faster"
            id="popup"
          >
            <div className="flex flex-row items-center justify-center gap-x-2">
              <button className="rounded-full shadow-md">
                <img src={icon_fail} className="w-[25px]" alt="" />
              </button>
              <h1 className="text-sm font-bold  ">
                email verification fail
              </h1>
            </div>
          </div>
        )}

      </div>

    </section >
  )
}