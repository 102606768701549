import { configureStore } from "@reduxjs/toolkit";
import LoginSlice from "../reducers/Loginreducer";
import userDataSlice from "../reducers/userDataReducer";

import storage from "redux-persist/lib/storage";
import { 
  persistReducer ,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,} from "redux-persist";

import { combineReducers } from "@reduxjs/toolkit";
import storageDataSlice from "../reducers/storageDetailsSlice";
import uploadSlice from "../reducers/uploadDetailsSlice";
import toggleSidebarSlice from "../reducers/toggleSidebar";
import WalletAddressSlice from "../reducers/WalletAddressSlice";
import didSlice from "../reducers/didRedcuer";
import downloadCountSlice from "../reducers/DownloadsCountReducer";
import FileCompBlurSlice from "../reducers/filesCompBlurReducer";
import  verifierReducer  from "../reducers/VerifierDidReducer";
import current_file_count from "../reducers/currentAccountReducer";
import transactionHashReducer from "../reducers/TransactionHashReducer";
import becxSliceReducer from "../reducers/becxReduser";
import requireAvalibilitySlice from "../reducers/RequireAvalibilySlice";
import planStatusSlice from "../reducers/PlanStatusSlice";
import uploadStatusSlice from "../reducers/UploadStatusSlice";
import uploadTriggerSlice from "../reducers/uploadTriggerSlice";
import refSlice from "../reducers/referralReducer";
import filePointsSlice from "../reducers/filePointsReducer";
import totalPointsSlice from "../reducers/totalPointsReducer";
import web_theme_slice from "../reducers/webTheme";
import uploading_files from "../reducers/UploadingFileSlice";
import upload_button_click from "../reducers/uploadButtonClick";


const persistConfig = {
  key: 'root',
  storage: storage,
}

const reducer = combineReducers({
  loginReducer: LoginSlice.reducer,
  userDataReducer: userDataSlice.reducer,
  storageDetailsReducer: storageDataSlice.reducer,
  uploadDetailsReducer: uploadSlice.reducer,
  toggleSidebarReducer: toggleSidebarSlice.reducer,
  WalletAddressReducer: WalletAddressSlice.reducer,
  DidReducer: didSlice.reducer,
  downloadCountReducer: downloadCountSlice.reducer,
  fileBlurReducer : FileCompBlurSlice.reducer,
  verifierReducer : verifierReducer.reducer,
  current_file_countReducer : current_file_count.reducer,
  transactionHashReducer : transactionHashReducer.reducer,
  becxSliceReducer : becxSliceReducer.reducer,
  requireAvalibilityReducer : requireAvalibilitySlice.reducer,
  planStatusReducer : planStatusSlice.reducer,
  uploadStatusReducer : uploadStatusSlice.reducer,
  uploadTriggerReducer : uploadTriggerSlice.reducer,
  refLinkReducer : refSlice.reducer,
  filePointReducer : filePointsSlice.reducer,
  totalPointReducer : totalPointsSlice.reducer,
  webThemeReducer : web_theme_slice.reducer,
  uploadingFileReducer : uploading_files.reducer,
  uploadButtonClickReducer : upload_button_click.reducer
})

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})