import { gql } from "@apollo/client";

// query --------------------------------------------------------------------------------
export const is_user_added = gql`
query IsUserExistsOnChain($did: String) {
  isUserExistsOnChain(did: $did) {
    isRegistered
  }
}`

export const GET_TOTAL_POINTS = gql`
query GetTotalPoints($did: String!) {
  getTotalPoints(did: $did) {
    totalPoints
  }
}`

// get user logs
export const get_user_activities = gql`
query GetLogs($did: String, $pageSize: Int, $pagingState: String) {
  getLogs(did: $did, pageSize: $pageSize, pagingState: $pagingState) {
    traceGroups {
      traceId
      logs {
        did
        traceId
        serviceName
        level
        message
        startTime
        endTime
        loggedAt
      }
    }
  }
}`

// check plan expire or not 
export const check_plan_expire = gql`
query CheckPackageExpire($input: CheckPackageExpireInput!) {
  checkPackageExpire(input: $input) {
    Ok
  }
}
`

// check what package's active
export const check_active_plan = gql`
query GetCurrentPackage($input: GetCurrentPackageInput!) {
    getCurrentPackage(input: $input) {
      isActive
      packageName
      durationMonths
    }
  }
`

// --------------------------------------------- dashboard home query functions ---------------------------------------------

export const get_all_file_count = gql`
query GetTotalFilesCountSize($input: GetTotalFilesCountSizeInput!) {
  getTotalFilesCountSize(input: $input) {
    ok {
      ok {
        count
        size
      }
    }
  }
}
 `

 export const GET_USER_LOGS = gql`
 query GetUserActivityLogs($did: String, $pageSize: Int, $pageNumber: Int) {
  getUserActivityLogs(did: $did, pageSize: $pageSize, pageNumber: $pageNumber) {
    logs {
      did
      traceId
      level
      message
      duration
      loggedAt
    }
    pageNumber
    totalLogs
  }
}`

export const GET_NETWORK_STATUS = gql`
query GetUserNetworkUsage($did: String!) {
  getUserNetworkUsage(did: $did) {
    daily_usage {
      day
      daily_usage
    }
  }
}`

// --------------------------------------------- dashboard Folder query functions ---------------------------------------------

export const CHECK_PACAKGE_ACTIVATION = gql`
query CheckPackageActivate($input: CheckPackageActivateInput!) {
  checkPackageActivate(input: $input) {
    Ok
  }
}`

export const GET_ALL_FILES = gql`
query GetAllBatches($input: GetAllBatchesInput!) {
  getAllBatches(input: $input) {
    batches {
      ownerDid
      batchhash
      filesCount
      batchSize
      verified
      fileHashes
      deletedFilesCount
    }
  }
}
`

export const GET_INDIVIDUAL_FILE_NAMES = gql`
query GetFileNames($did: String!, $batchHash: String!) {
  getFileNames(did: $did, batchHash: $batchHash) {
    fileNameData {
      fileHash
      fileName
      fileType
    }
  }
}
`

export const GET_DOWNLOAD_QR = gql`
query GetDownloadQR($did: String!, $batchHash: String!) {
  getDownloadQR(did: $did, batchHash: $batchHash) {
    Did
    Hash
    SessionID
  }
}`

export const GET_DOWNLOAD_STATUS = gql`
query GetDownloadStatus($sessionId: String!) {
  getDownloadStatus(sessionId: $sessionId) {
    Response
  }
}`

export const GET_CIDS = gql`
query GetCIDS($index: Int!, $did: String!) {
  getCIDS(index: $index, did: $did) {
    cidList
  }
}`

export const GET_FILE_INDEX = gql`
query GetBatchFile($input: GetBatchFileInput!) {
  getBatchFile(input: $input) {
    index
  }
}`

export const GET_SHARE_FILE_INDEX = gql`
query GetSharedFile($input: GetSharedFileInput!) {
  getSharedFile(input: $input) {
    filename
    merkletree_index
  }
}`

// --------------------------------------------- dashboard Login query functions ---------------------------------------------

export const GET_SESSION_FOR_LOGIN = gql`
query GetSignInStatus($sessionId: String!) {
  getSignInStatus(sessionId: $sessionId) {
    Response
  }
}
`

export const GET_LOGIN_QR_STATS = gql`
query GetUserSignInBethel {
  getUserSignInBethel {
    SessionID
  }
}
`


// --------------------------------------------- dashboard billing query functions ---------------------------------------------
export const GET_FREE_PLAN_STATUS = gql`
query GetFreePlanStatus($input: GetFreePlanStatusInput!) {
  getFreePlanStatus(input: $input) {
    Ok
  }
}`

export const GET_EMAIL_VERIFICATION_STATUS = gql`
query GetEmailVerificationStatus($did: String!) {
  getEmailVerificationStatus(did: $did) {
    isVerified
    userEmail
  }
}`

export const GET_SHARE_FILES = gql`
query GetAllSharedFiles($input: GetAllSharedFilesInput!) {
  getAllSharedFiles(input: $input) {
    shared_files {
      owner_did
      shared_did
      batchhash
      filehash
      filename
      merkletree_index
      verified
    }
  }
}`

export const GET_BECX_PRICE = gql`
query GetBecxPrice {
  getBecxPrice {
    price
  }
}`

export const GET_PLAN_PRICE = gql`
query GetPlanPrice($did: String!, $plan: String!, $month: Int!) {
  getPlanPrice(did: $did, plan: $plan, month: $month) {
    price
  }
}`

// --------------------------------------------- dashboard profile query functions ---------------------------------------------
export const GET_PROFILE_DATA = gql`
query GetUserProfile($did: String!) {
  getUserProfile(did: $did) {
    id
    did
    email
    firstName
    lastName
    country
    phoneNumber
    countryCode
    description
    companyName
    companyRegNo
    postalCode
    city
    state
    address_1
    address_2
    accountType
    profileImage
    createdAt
  }
}`


export const GET_ACTIVATE_PACKAGE_ALL_DETAILS = gql`
query GetActivatePackageDetails($input: GetActivatePackageDetailsInput!) {
  getActivatePackageDetails(input: $input) {
    Ok {
      did
      packageName
      space
      createTime
      expireTime
      duration
      usedSpace
      paidAddress
      graceExtended
      paymentMethod
      invoiceNo
      inAppTransactionId
    }
  }
}`


export const GET_DOC_FILE = gql`
query GetDocFile($input: GetDocFileInput!) {
  getDocFile(input: $input) {
    owner_did
    filehash
    merkletree_index
    doc_type
    doc_name
    doc_id
  }
}`

// --------------------------------------------- dashboard referrals query functions ---------------------------------------------

export const GET_TOP10_REFS = gql`
query TopReferrals {
  getTopReferrals {
    topReferrals {
      referralID
      totalPoint
    }
  }
}`

export const GET_REF_DETAILS = gql`
query GetGetReferredDIDS($did: String!) {
  getGetReferredDIDS(did: $did) {
    referrerData {
      referredDid
      addedPoints
      createdAt
    }
  }
}`