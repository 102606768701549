import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    triggerFn : false
}

const upload_button_click = createSlice({
    name : "upload_button_click",
    initialState,
    reducers : {
        toggleTriggerFn: (state) => {
            state.triggerFn = state.triggerFn = true; // Toggle the trigger
        },
        toggleTriggerFnReverse: (state) => {
            state.triggerFn = state.triggerFn = false; // Toggle the trigger
        },
        
    }
})

export const { toggleTriggerFn } = upload_button_click.actions;
export default upload_button_click