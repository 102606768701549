import { gql } from "@apollo/client";

export const ADD_CHAIN_USER = gql`
  mutation AddChainUser($did: String!) {
    addChainUser(did: $did) {
      success
      message
    }
  }
`;

 // --------------------------------------------- dashboard folder mutation functions ---------------------------------------------

 export const ADD_LOGIN_POINTS = gql`
 mutation AddLoginPoints($did: String!) {
  addLoginPoints(did: $did) {
    did
    login_count
    success
  }
}`

export const ADD_FILE_POINTS = gql`
mutation AddFilePoints($did: String!, $fileCount: Int!) {
  addFilePoints(did: $did, file_count: $fileCount) {
    added_points
    did
    total_points
  }
}`

 


 // --------------------------------------------- dashboard folder mutation functions ---------------------------------------------

 export const upload_files = gql`
  mutation UploadFiles($files: [Upload!]!, $did: String!, $doctype: String) {
    uploadFiles(files: $files, did: $did, doctype: $doctype) {
      ownerDid
      claim
      fileCount
    }
  }
`;

export const UPLOAD_DOCS = gql`
mutation UploadFiles($files: [Upload!]!, $did: String!, $doctype: String, $dockname: String, $docid: String) {
  uploadFiles(files: $files, did: $did, doctype: $doctype, dockname: $dockname, docid: $docid) {
    claim
    fileCount
    ownerDid
  }
}`

export const VERIFY_FILE = gql`
mutation UpdateFileVerifyStatus($ownerDid: String!, $batchHash: String!) {
  updateFileVerifyStatus(owner_did: $ownerDid, batch_hash: $batchHash) {
    success
    message
  }
}`

export const DOWNLOAD_SINGLE_FILE = gql`
mutation DownloadFile($batchHash: String!, $fileHash: String!, $fileName: String!, $did: String!, $cids: [String!]!) {
  downloadFile(batchHash: $batchHash, fileHash: $fileHash, fileName: $fileName, did: $did, cids: $cids) {
    DID
    URL
  }
}`

export const SHARE_FILE = gql`
mutation ShareFile($input: ShareFileInput!) {
  shareFile(input: $input) {
    success
    message
  }
}`

export const SHARE_CLAIM = gql`
mutation ShareClaim($ownerDid: String!, $sharedDid: String!, $batchhash: String!, $filehash: String!, $filename: String!) {
  shareClaim(owner_did: $ownerDid, shared_did: $sharedDid, batchhash: $batchhash, filehash: $filehash, filename: $filename) {
    success
    message
  }
}`

export const DELETE_FILE = gql`
mutation DeleteFile($input: DeleteFileInput!) {
  deleteFile(input: $input) {
    success
    message
  }
}`

export const DOWNLOAD_HALL_BATCH = gql`
mutation DownloadBatch($input: BatchInput!) {
  downloadBatch(input: $input) {
    DID
    URL
  }
}`


 // --------------------------------------------- dashboard billing plan mutation functions ---------------------------------------------

export const ACTIVATE_PLAN = gql`
mutation ActivatePackage($input: ActivatePackageInput!) {
  activatePackage(input: $input) {
    success
    message
  }
}
`

 // --------------------------------------------- dashboard profile mutation functions ---------------------------------------------

export const UPDATE_PROFILE_DATA = gql`
mutation UpdateUserProfile($input: UpdateUserProfileInput!) {
  updateUserProfile(input: $input) {
    success
    message
  }
}`

export const VERIFY_EMAIL = gql`
mutation SaveEmailVerify($input: SaveEmailVerifyInput!) {
  saveEmailVerify(input: $input) {
    success
    message
    emailVerify {
      id
      did
      userEmail
      token
      isVerified
      createdAt
      expiresAt
    }
  }
}`

export const CONFIRM_EMAIL_VERIFICATION = gql`
mutation ConfirmEmailVerification($token: String!) {
  confirmEmailVerification(token: $token) {
    success
    message
  }
}`

export const UPLOAD_PROFILE_PICTURE = gql`
mutation UpdateUserProfilePic($did: String!, $file: [Upload!]) {
  updateUserProfilePic(did: $did, file: $file) {
    success
    message
    profile_image
  }
}`

export const DELETE_DOC_FILES = gql`
mutation DeleteDoc($input: DeleteDocInput!) {
  deleteDoc(input: $input) {
    success
    message
  }
}`