import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { GET_USER_LOGS } from '../../queris'
import { useQuery } from '@apollo/client'
import icon_success from "../../Images/icons/icon-newdone.png"
import icon_wrong from "../../Images/icons/icon-close.png"


function DashboardActivitiesComponent() {
    const web_theme = useSelector((state) => state.webThemeReducer.web_theme)
    const did = useSelector((state) => state.DidReducer)

    const [logs, setLogs] = useState()
    const [isLogs, setIsLogs] = useState(false)

    const { error, loading, data: data_activity , refetch } = useQuery(GET_USER_LOGS, {
        variables: { did: did, pageSize: 10, pagingState: null }
    })

    useEffect(() => {
        if (loading) {
            setIsLogs(true)
        }
        if (error) {
            setIsLogs(false)
        }
        if (data_activity) {
            setLogs(data_activity.getUserActivityLogs.logs)
            setIsLogs(false)
        }
        refetch()
    }, [data_activity, loading, error])

    return (
        <div className={`w-full flex flex-col ${web_theme ? 'bg-dashboard-small-card' : 'shadow-md border border-sidebar-text-lite2/10'} p-4 rounded-[25px]`}>
            {/* topic container */}
            <div className={`${web_theme ? 'text-white' : 'text-sidebar-text-lite2'} text-[1rem]`}>
                <h3>Latest Activities</h3>
            </div>

            {/* activity component */}
            <div className={`${web_theme ? "bg-dashboard-home-bg1" : 'bg-sidebar-text-lite2/10'} rounded-[20px] mt-4 overflow-y-scroll`}>
                {/* print logs */}
                {logs ?
                    <div className='flex w-full flex-col text-white p-2 gap-3'>
                        {logs && logs.map((log, index) => {
                            return (
                                <div className='w-full flex flex-col px-2'>
                                    <div className={`${web_theme ? 'text-white/50' : 'text-sidebar-text-lite2'} text-[0.8rem]`}>
                                        <h3>time : ({log.loggedAt.slice(0, 10)}) : : {log.loggedAt.slice(11, 16)}</h3>
                                    </div>

                                    <div className={`flex text-[0.6rem] w-full h-full ${web_theme ? 'text-white/90' : 'text-sidebar-text-lite2/50'} overflow-hidden gap-2 justify-between px-1 mt-1`}>
                                        <div className='w-3/4 flex overflow-scroll scroll-smooth gap-1 items-center'><h1 className={`${web_theme ? 'text-white' : ''}`}>&#9654;</h1><h3>{logs && log.message}</h3></div>
                                        <div className='w-1/4 flex items-center justify-end'>{log.level === 'error' ? <img src={icon_wrong} className='w-[20px]' alt="" /> : <img src={icon_success} className='w-[20px]' alt="" />}</div>
                                    </div>

                                    <hr className='w-[99%] h-[1px] mt-2 opacity-40' />
                                </div>
                            )
                        })}
                    </div>
                    :
                    <div className={`flex w-full flex-col p-2 gap-3 items-center justify-center ${web_theme ? 'text-white/50' : 'text-sidebar-text-lite2'} tracking-wider `}>
                        <h3>No Activities</h3>
                    </div>
                }
            </div>
        </div>
    )
}

export default DashboardActivitiesComponent