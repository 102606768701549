import React, { useEffect, useRef, useState } from 'react'
import heroVid from "../../Images/Videos/green-bg.mp4";
import bethel_logo from "../../Images/BG-Images/bethel-new-logo.png"
import QRCode from "react-qr-code";
import qr_icon from "../../Images/icons/QR.png"
import { useNavigate } from 'react-router-dom';
import didSlice from '../../reducers/didRedcuer';
import { useDispatch, useSelector } from 'react-redux';
import WalletAddressSlice from '../../reducers/WalletAddressSlice';
import icon_close from "../../Images/icons/icon-close.png"
import animation_qr from "../../Images/Animation-gifs/Animation-qr.gif"
import icon_fail from "../../Images/Animation-gifs/icon-fail.png"
import animated_gif from "../../Images/Animation-gifs/Ellipsis-11.gif"
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { GET_LOGIN_QR_STATS, GET_SESSION_FOR_LOGIN } from '../../queris';
import { ADD_LOGIN_POINTS } from '../../mutations';

function Version_login() {
    const [login_qr_toggle, setLogin_qr_toggle] = useState(false)
    const [isSubmit, setIsSubmit] = useState(false)
    const [intro_bethel, setIntro_bethel] = useState(false)
    const [register_toggle, setRegister_toggle] = useState(false)
    const [register_did, setRegister_did] = useState(null)

    // qr code
    const [login_qr_code_data, setLogin_qr_code_data] = useState('')
    const [registration_qr_status, setRegistration_qr_status] = useState({ status: '', err: null, msg: null })

    // refs
    const videoRef = useRef()

    const refPath = useSelector((state) => state.refLinkReducer)   // get path

    // navigation
    const navigate = useNavigate()

    // store 
    const dispatch = useDispatch()

    useEffect(() => {
        if (videoRef.current) {
            // Adjust playback speed based on the `scale` state
            videoRef.current.playbackRate = isSubmit ? 6 : 1;
        }
    }, [isSubmit]);

    // add login points to user
    const addLoginPoints = async (did) => {
        try {
            const res = await fetch(process.env.REACT_APP_BACKEND_URL + "login-points", {
                method: "POST",
                body: JSON.stringify({
                    "DID": did.id
                })
            })
            console.log(res)
        } catch (error) {
            console.log(error)
        }
    }

    // login QR function
    const login_function = async () => {
        try {
            const authRequest = await fetch(
                process.env.REACT_APP_BACKEND_URL + "sign-in"
            );
            if (authRequest.ok) {
                const qr_data = await authRequest.json()
                setLogin_qr_code_data(JSON.stringify(qr_data)); // set login qr data
                setLogin_qr_toggle(true) // login qr show

                const sessionID = authRequest.headers.get("x-id"); // get headers

                // store wallet address
                dispatch(WalletAddressSlice.actions.saveWalletAddress(sessionID));

                const interval = setInterval(async () => {
                    try {
                        const sessionResponse = await fetch(
                            process.env.REACT_APP_BACKEND_URL + "status?sessionId=" + sessionID
                        );

                        if (sessionResponse.status === 200) {
                            setIsSubmit(true) // trigger login animation
                            setLogin_qr_toggle(false)
                            const did = await sessionResponse.json();
                            dispatch(didSlice.actions.didStore(did.id));

                            // login animation
                            setTimeout(() => {
                                setIsSubmit(false)
                                setIntro_bethel(true)

                                setTimeout(() => {
                                    navigate("/dashboard") // navigate if success
                                }, 600);
                            }, 4000);

                            // add login points fn 
                            addLoginPoints(did)

                            clearInterval(interval);
                        }
                    } catch (e) {
                        console.log("err->", e);
                    }
                }, 3000);
            }
        } catch (error) {
            console.log("login error :", error)
        }
    }

    // close login qr 
    const close_login_qr = () => {
        setLogin_qr_toggle(false)
    }

    // register toggle
    const register = () => {
        setRegister_toggle(true)
    }

    const handlePatse = (e) => {
        const clipboardData = e.clipboardData || window.clipboardData;
        const pastedText = clipboardData.getData("text");

        // Here we'll need to simulate a change event for the input manually
        const updatedValue = pastedText;
        setRegister_did(updatedValue);
    };

    // submit register
    const submit_register = async () => {
        setLogin_qr_code_data('')
        setRegistration_qr_status((prev) => ({ ...prev, status: 'loading' })) //set status
        try {
            const signup_qr_data = await fetch(process.env.REACT_APP_BACKEND_URL + `sign-up?refID=${refPath && refPath.RefLink}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                mode: 'cors',
                body: JSON.stringify({
                    "DID": register_did,
                    "First": "",
                    "Last": "",
                    "Email": "",

                }),
            });

            if (signup_qr_data.ok) {
                setLogin_qr_toggle(true) // show qr
                setRegister_toggle(false) // hide register input 
                const claim = await signup_qr_data.json()
                setLogin_qr_code_data(JSON.stringify(claim.claim));
                setRegistration_qr_status((prev) => ({ ...prev, err: null, status: 'complete', msg: "After scan close QR and click Log In" })) //set status

                setTimeout(() => {
                    setRegistration_qr_status((prev) => ({ ...prev, err: null, status: '', msg: null })) //set status
                }, 4000);


            }
            else {
                setRegistration_qr_status((prev) => ({ ...prev, err: 'Registration fail please try again', status: '' })) //set status
                setRegister_toggle(false)
                setTimeout(() => {
                    setLogin_qr_toggle(false)
                    setRegistration_qr_status((prev) => ({ ...prev, err: null, status: '' })) //set status
                }, 3000);

            }
        } catch (error) {
            setRegistration_qr_status((prev) => ({ ...prev, err: 'Registration fail please try again', status: '' })) //set status
            setRegister_toggle(false)
            console.log("sign up qr data err :- ", error);
            setTimeout(() => {
                setRegistration_qr_status((prev) => ({ ...prev, err: null, status: '' })) //set status
            }, 3000);
        }

    }

    //  ----------------------------------------------------------------------------- graph QL fn for queries  -----------------------------------------------------------------------------

    const [login_data_fn] = useLazyQuery(GET_LOGIN_QR_STATS)
    const [login_session_fn] = useLazyQuery(GET_SESSION_FOR_LOGIN)
    const [add_login_points] = useMutation(ADD_LOGIN_POINTS)

    const add_points = async (did) => {
        try {
            const res = await add_login_points({ variables : {did: did}})
            if(res.data){
                console.log("login points added")
            }
        } catch (error) {
            console.log("login points error: ",error)
        }
    }

    // get session ID status
    const session_id_fn = async (sessionID) => {
        const session_req = await login_session_fn({ variables: { sessionId: sessionID } })

        if (session_req.errors) {
            if (session_req.errors[0].message === "Failed to get sign-in status.") {
                const login_session = setTimeout(() => {
                    session_id_fn(sessionID)
                }, 3000);

            }
        }
        if (session_req.data) {
            setIsSubmit(true) // trigger login animation
            setLogin_qr_toggle(false)
            dispatch(didSlice.actions.didStore(session_req.data.getSignInStatus.Response)); // send did to store

            await add_points(session_req.data.getSignInStatus.Response)

            // login animation
            setTimeout(() => {
                setIsSubmit(false)
                setIntro_bethel(true)

                setTimeout(() => {
                    navigate("/dashboard") // navigate if success
                }, 600);
            }, 4000);

            return true
        }

    }

    const auth_login = async () => {
        try {
            const login_res = await login_data_fn()

            if (login_res) {
                console.log("this is login response :", login_res.data.getUserSignInBethel.SessionID)
                setLogin_qr_code_data(JSON.stringify(login_res.data.getUserSignInBethel.SessionID)); // set login qr data
                setLogin_qr_toggle(true) // login qr show

                let sessionID = login_res.data.getUserSignInBethel.SessionID

                const session_status = await session_id_fn(sessionID)


            }
        } catch (error) {

        }
    }

    return (
        <div className='flex w-full bg-black/20 lg:flex-row md:flex-col sm:flex-col min-[320px]:flex-col items-center justify-center'>

            <div className="flex w-[55%] h-screen overflow-hidden bg-cover relative rounded-r-[550px] shadow-custom">
                <div className=''>
                    <video
                        ref={videoRef}
                        className={`absolute inset-0 h-full object-cover ${login_qr_toggle ? "scale-[1.2] blur-[1px] animate-glow" : "scale-[1.1]"}`}
                        src={heroVid}
                        autoPlay
                        loop
                        muted
                    />
                </div>


                {/* bethel logo */}
                <div className={`absolute transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2 ${!login_qr_toggle ? 'animate__animated animate__fadeIn' : "animate__animated animate__fadeOut"}`}>
                    <img src={bethel_logo} className={`${intro_bethel ? "scale-[8] transition-all duration-1000 ease-in-out" : ""} w-full ${isSubmit ? "opacity-40" : "opacity-20"} bg-transparent`} alt="" />
                </div>

                {/* login QR */}
                {login_qr_code_data &&
                    <div className={`absolute transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2 bg-white border-2 border-bethel-green py-5 px-8 scale-[.8] shadow-custom rounded-xl ${login_qr_toggle ? 'animate__animated animate__fadeIn' : "hidden"}`}>
                        <div className='w-full relative'>
                            <div className='w-full flex justify-center items-center py-1 tracking-wide text-black text-[1.1rem] font-semibold relative mb-2'>
                                <h3>Bethel ZKP login</h3>

                                <div className='absolute top-1 w-full flex justify-center items-center py-1 tracking-wide text-bethel-green text-[1.1rem] font-semibold'>
                                    <img src={animated_gif} className='w-[60px]' alt="" />
                                </div>
                            </div>

                            <hr className='w-full py-1 h-[.5px]' />
                            <QRCode value={login_qr_code_data} className='' />
                            <hr className='w-full mt-2 h-[.5px]' />

                            <div className='w-full flex justify-center items-center mt-2  tracking-wide text-bethel-green font-semibold text-[1.1rem]'>
                                <h3>Scan to proceed</h3>
                            </div>

                            {/* close icon */}
                            <button onClick={close_login_qr} className='absolute -right-7 -top-4'>
                                <img src={icon_close} className='w-[30px]' alt="" />
                            </button>
                        </div>
                    </div>
                }



                {/* login QR */}
                {isSubmit &&
                    <div className={`absolute bottom-28 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-[2rem] text-bethel-green tracking-widest animate-shine font-bold`}>
                        <h3>logging in ...</h3>
                    </div>
                }

            </div>

            {/* bethel login and sign up section */}
            <div className='flex w-[45%] flex-col gap-1 p-5 justify-center'>
                {/* logo div */}
                <button className='flex justify-center'>
                    <img src={bethel_logo} className='w-[80px]' alt="" />
                </button>


                {/* topic */}
                <div className='w-full flex justify-center text-[3rem] font-bold tracking-wide gap-2'>
                    <h3 className='text-bethel-green'>Bethel</h3>
                    <h3 className='text-white'>zkpStorage</h3>
                </div>

                <div className='flex justify-center w-full text-[1rem] font-semibold tracking-wide text-white/50 text-center'>
                    <h3>Hack Proof Blockchain Based Secure Decentralized File Storage with (ZKP) <span className='text-white/70'><i>Zero-Knowledge Proofs</i></span></h3>
                </div>

                {/* login button and register button */}
                {(!login_qr_toggle && !register_toggle) && <div className='w-full flex justify-center items-center mt-4 gap-2 relative'>

                    {/* login button */}
                    <button onClick={auth_login} className='p-2 rounded-[25px] border border-bethel-green text-white font-bold w-[150px] flex items-center justify-center gap-2 hover:bg-bethel-green hover:text-black hover:scale-105 transition-all ease-in-out'>
                        <div>
                            <img src={qr_icon} className='w-[20px]' alt="" />
                        </div>
                        <h3 className=''>Log In</h3>
                    </button>

                    {/* login button */}
                    {/* <button onClick={register} className='p-2 rounded-[25px] border border-bethel-green text-white font-bold w-[150px] flex items-center justify-center gap-2 hover:bg-bethel-green hover:text-black hover:scale-105 transition-all ease-in-out'>
                        <div>
                            <img src={qr_icon} className='w-[20px]' alt="" />
                        </div>
                        <h3 className=''>Register</h3>
                    </button> */}

                    {/* registration error msg */}
                    {registration_qr_status.err &&
                        // login scanning
                        <div className='w-full flex justify-center items-center my-2 p-2 rounded-[25px] animate__animated animate_fadeIn absolute -bottom-16 animate-pulse'
                            style={{
                                textShadow: "2px 2px 4px rgba(255, 0, 0, 0.5)",
                            }}>
                            <div className='w-full flex justify-center items-center relative'>
                                <div className='text-red-700 text-[1.2rem] font-bold tracking-normal'>
                                    <h3>{registration_qr_status.err} </h3>
                                </div>

                                <img src={icon_fail} className='w-[40px] animate-glow opacity-70' alt="" />
                            </div>
                        </div>
                    }
                </div>
                }

                {login_qr_toggle &&
                    // login scanning
                    <div className='w-full flex justify-center items-center my-2 p-2 rounded-[25px] relative'>
                        <div className='w-full flex justify-center items-center relative'>
                            <div className='text-bethel-green text-[1rem] font-normal tracking-normal text-center animate-pulse'>
                                <h3 style={{
                                    textShadow: "2px 2px 4px rgba(0, 255, 0, 0.5)",
                                }}>Scan the QR using Bethel Mobile App <br />{registration_qr_status.msg && registration_qr_status.msg} </h3>
                            </div>

                            <img src={animation_qr} className='w-[60px] animate-glow opacity-70' alt="" />
                        </div>
                    </div>
                }


                {/* register section */}
                {/* {register_toggle && <div className='w-full flex justify-center items-center py-4 gap-1 mt-2 animate__animated animate__fadeIn'>

                    <div>
                        <input type="text" onPaste={handlePatse} className='w-[300px] px-4 py-2 outline-none bg-transparent text-white placeholder:text-white/30 rounded-[15px] border-l border-r border-bethel-green' placeholder='paste your did here' />
                    </div>

                    <button onClick={submit_register} disabled={registration_qr_status.status === "loading"}
                        className={`py-2 px-4 text-center border border-bethel-green rounded-[25px] text-white hover:text-black hover:bg-bethel-green font-bold text-[1rem] hover:scale-105 ${registration_qr_status.status === "loading" ? 'animate-pulse' : ''}`}>
                        {registration_qr_status.status === "loading" ? <h3>loading</h3> : <h3>Submit</h3>}
                    </button>
                </div>
                } */}


            </div>


        </div>
    )
}

export default Version_login