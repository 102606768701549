import { createSlice } from "@reduxjs/toolkit";

let initialState = {
    uploading_files: null
}

export const uploading_files_slice = createSlice({
    name: "Uploading files",
    initialState,
    reducers: {
        save_uploading_files:  (state, action) => action.payload,
        clear_uploading : () => initialState = { uploading_files: null }
    }
})

export default uploading_files_slice